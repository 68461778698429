import React from "react";
import PropTypes from 'prop-types'
import 'antd/dist/antd.css';
import { Select } from 'antd';
import NewCustomerQuestion from './newCustomerQuestion';
import VppQuestion from './vppQuestion';
import SearchType from './searchType';
import SignupPlan from './plan';
import ReviewQuotation from './reviewQuotation';
import ExistedCustomerQuestion from './existedCustomerQuestion'
import Storage from '../storage'
import emitter from '../../lib/events';



function generateUUID() {
  var d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }
  var uuid = 'xxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}



export class SignNewPlan extends React.Component {
  constructor(props) {
    super(props);
    this.cur_storage = new Storage()
    // this.selectedMarketOfferCacheID = generateUUID()
    this.state = {
      select_customer_type: 'question_new_customer',
      new_customer_info: {
        birthday: ''
      },
      existed_customer_info:{
        email: '',
        customer_id: '',
        birthday: ''
      },
      vpp_customer_info: {
        vpp_customer: '',
        install_solar: '',
        deinsight_user: '',
        disabled_change: false,
        email: ''
      },
      energy_type: 'electricity', // electricity、gas
      customer_type: '', // residential、business
      plan_search_type: 'nmi', // nmi、address、mirn
      search_type_info: {
        full_nmi: '',
        connected_full_address: '',
        nmi: '',
        checksum: '',
        full_mirn: '',
        mirn: ''
      },
      plans: {
      },
      user_info: {
        peak_usage: '',
        peak_usage_price: '',
        days: '',
        days_price: '',
        total: ''
      },
      reviewQuotationShow: false,
      estimated_monthly_bill: ''
    }
    let cache_state = this.cur_storage.get(this.props.cache_state_id)
    if(cache_state){
      Object.assign(this.state, JSON.parse(cache_state))
    }
    this.state.user_info = {}
    this.newCustomerResetItem = {
      select_customer_type: 'question_new_customer',
      new_customer_info: {
        birthday: ''
      },
      existed_customer_info:{
        email: '',
        customer_id: '',
        birthday: ''
      },
      vpp_customer_info: {
        vpp_customer: '',
        install_solar: '',
        deinsight_user: '',
        disabled_change: false,
        email: ''
      },
      energy_type: 'electricity', // electricity、gas
      customer_type: '', // residential、rusiness
      plan_search_type: 'nmi', // nmi、address、mirn
      search_type_info: {
        full_nmi: '',
        connected_full_address: '',
        nmi: '',
        checksum: '',
        full_mirn: '',
        mirn: ''
      },
      plans: {
      },
      user_info: {
        peak_usage: '',
        peak_usage_price: '',
        days: '',
        days_price: '',
        total: ''
      },
      reviewQuotationShow: false,
      estimated_monthly_bill: ''
    }
    this.oldCustomerResetItem = {
      select_customer_type: 'question_existing_customer',
      new_customer_info: {
        birthday: ''
      },
      existed_customer_info:{
        email: '',
        customer_id: '',
        birthday: ''
      },
      vpp_customer_info: {
        vpp_customer: '',
        install_solar: '',
        deinsight_user: '',
        disabled_change: false,
        email: ''
      },
      energy_type: 'electricity', // electricity、gas
      customer_type: '', // residential、business
      plan_search_type: 'nmi', // nmi、address、mirn
      search_type_info: {
        full_nmi: '',
        connected_full_address: '',
        nmi: '',
        checksum: '',
        full_mirn: '',
        mirn: ''
      },
      plans: {
      },
      user_info: {
        peak_usage: '',
        peak_usage_price: '',
        days: '',
        days_price: '',
        total: ''
      },
      reviewQuotationShow: false,
      estimated_monthly_bill: ''
    }
  }

  // reset component
  resetPlanList = (type) => {
    let plan_search_type;
    plan_search_type = type ? type : 'nmi'
    this.setState({
      plan_search_type: plan_search_type,
      search_type_info: {
        full_nmi: '',
        connected_full_address: '',
        nmi: '',
        checksum: '',
        full_mirn: '',
        mirn: ''
      },
      plans: {},
      reviewQuotationShow: false
    }, () => {
      emitter.emit('resetSearchTypeForm', true);
    })
  }

  changeCustomerType = (item) => {
    if(item.select_customer_type != this.state.select_customer_type){
      if(item.select_customer_type === 'question_new_customer'){
        this.setState(this.newCustomerResetItem)
      }else{
        this.setState(this.oldCustomerResetItem)
      }
    }
  }

  onHandleNewCustomerInfoChange = (item) => {
    if(item.new_customer_info.birthday !== this.state.new_customer_info.birthday){
      this.setState(item, ()=>{
        if(this.supply_address_can_show && this.state.search_type_info.nmi != '' && this.state.search_type_info.checksum != ''){
          emitter.emit('changeBirthday', item.birthday);
        }
      })
    }else{
      this.setState(item)
    }
  }

  onHandleExistedCustomerChange = (item) => {
    this.setState(item)
  }

  updateState = (item, callBack) => {
    this.setState(item,() => {
      if(callBack) {
        callBack
      }
    })
  }

  get vpp_can_show () {
    if(this.props.is_vpp_channel === true){
      if (this.state.select_customer_type == 'question_new_customer'){
        if( this.state.new_customer_info.birthday != ''
            && this.state.estimated_monthly_bill != ''){
            return true
        }else{
          return false
        }
      }else if(this.state.select_customer_type == 'question_existing_customer'){
        if(this.state.energy_type == 'electricity'){
          return (this.state.existed_customer_info.customer_id !== '' && this.state.estimated_monthly_bill != '') ? true : false
        }else{
          return this.state.existed_customer_info.customer_id !== '' ? true : false
        }
      }else{
        return false
      }
    }else{
      return false
    }
  }

  get is_vpp() {
    let is_vpp
    is_vpp = this.state.vpp_customer_info.vpp_customer === 'yes' ? true : false
    return is_vpp
  }

  onHandleVppCustomerInfoChange = (item) => {
    // let _this = this
    this.setState(item, ()=>{
      this.resetPlanList('nmi')
    })
  }


  get supply_address_can_show () {
    if(this.state.energy_type === 'gas'){
      if (this.state.select_customer_type == 'question_new_customer'){
        if( this.state.new_customer_info.birthday != ''){
            return true
        }else{
          return false
        }
      }else if(this.state.select_customer_type == 'question_existing_customer'){
        return this.state.existed_customer_info.customer_id !== '' ? true : false
      }else{
        return false
      }
    }else{
      if ( this.props.is_vpp_channel === true){
        if ((this.state.vpp_customer_info.vpp_customer === 'yes' && this.state.vpp_customer_info.install_solar === 'no')
            || this.state.vpp_customer_info.vpp_customer === 'no'
            || this.state.vpp_customer_info.disabled_change === true ){
          return true
        }else{
          return false
        }
      }else{
        let new_customer = this.state.new_customer_info
        let existed_customer = this.state.existed_customer_info
        if((existed_customer.customer_id !== '' && this.state.estimated_monthly_bill != '')
          ||(new_customer.birthday !== '' && this.state.estimated_monthly_bill !== '')){
          return true
        }else{
          return false
        }
      }
    }

  }

  onHandlePlanSearchTypeChange = (item) => {
    this.setState(item)
    // Reset Plan List
    this.resetPlanList(item.plan_search_type)
  }

  onHandleEnergyTypeChange = (item) => {
    this.setState(item)
    this.resetPlanList(item.plan_search_type)
  }

  onHandleSearchTypeInfoChange = (item) => {
    this.setState(item)
  }

  onHandlePlanChange = (item) => {
    this.setState({plans: {result: false}})
    item.plans.selectedMarketOfferId = null
    item.plans.isSelectPlan = false
    this.setState(item)
  }

  onHandleReviewQuotationClick = () => {
    let s = this.state.search_type_info
    if(this.state.plan_search_type === 'address'){
      if(s.connected_full_address === s.search_connected_full_address){
        this.setState({reviewQuotationShow: true})
      }else{
        alert("the plan isn't suitable for the address, please check it")
      }
    }else if(this.state.plan_search_type === 'nmi'){
      if(s.nmi === s.search_nmi && s.checksum === s.search_checksum){
        this.setState({reviewQuotationShow: true})
      }else{
        alert("the plan isn't suitable for the nmi, please check it")
      }
    } else if (this.state.plan_search_type === 'mirn') {
      if (s.mirn === s.search_mirn) {
        this.setState({
          reviewQuotationShow: true
        })
      } else {
        alert("the plan isn't suitable for the mirn, please check it")
      }
    }else{
      alert("please select a plan")
    }
  }

  onHandleReviewQuotationSubmit = (user_info) => {
    this.setState({reviewQuotationShow: false, user_info: user_info}, ()=>{
      let cache_state_id = generateUUID()
      this.cur_storage.set(cache_state_id, JSON.stringify(this.state), 1440)  //expired  use minutes
      let is_vpp = this.is_vpp
      let reviewParams = {
        cache_state_id: cache_state_id,
        authenticity_token: $('[name="csrf-token"]')[0].content,
        planList: this.state.plans.data.planList,
        selectedMarketOffer: JSON.stringify(this.state.plans.selectedMarketOffer),
        selectedDiscountDetail: this.state.plans.selectedDiscountDetail,
        selectedMarketOfferId: this.state.plans.selectedMarketOfferId,
        address: this.state.plans.data.nmi_info.address,
        postcode: this.state.plans.data.nmi_info.postcode,
        state: this.state.plans.data.nmi_info.state,
        nmi: this.state.search_type_info.nmi,
        checksum: this.state.search_type_info.checksum,
        user_info: this.state.user_info,
        email: this.state.vpp_customer_info.email,
        user_email: this.state.vpp_customer_info.user_email,
        check_code: this.state.vpp_customer_info.check_code,
        deinsight_id: this.state.vpp_customer_info.deinsight_id,
        sn: this.state.vpp_customer_info.sn,
        type: this.state.vpp_customer_info.type,
        is_vpp: is_vpp,
        energy_type: this.state.energy_type,
        customer_type: this.state.customer_type,
        mirn: this.state.search_type_info.mirn
      }
      if(this.state.select_customer_type === 'question_existing_customer'){
        reviewParams.customer_id = this.state.existed_customer_info.customer_id
        reviewParams.birthday = this.state.existed_customer_info.birthday
      }else if(this.state.select_customer_type === 'question_new_customer'){
        reviewParams.birthday = this.state.new_customer_info.birthday
      }
      if(this.state.energy_type == 'electricity'){
        reviewParams.estimated_monthly_bill = this.state.estimated_monthly_bill
      }
      return $.ajax({
        url: '/plans/review',
        method: 'post',
        type: 'json',
        data: reviewParams,
        success: function (data) {
          if (data.result) {
            localStorage.removeItem("vppAccount")
            return window.location.href = data.path;
          } else {
            return alert("system error");
          }
        }
      });
    })
  }

  onHandleChange = (item) => {
    this.setState(item)
  }

  handleSelectPlan = (plans_data)=>{
    let cur_plans = this.state.plans
    Object.assign(cur_plans, plans_data);
    this.setState({plans: cur_plans})
  }

  get birthday(){
    if (this.state.select_customer_type == 'question_new_customer'){
      return this.state.new_customer_info.birthday
    }else if(this.state.select_customer_type == 'question_existing_customer'){
      return this.state.existed_customer_info.birthday
    }else{
      return ''
    }
  }





  render () {
    let selectBoxStyle = 'de-select';
    let selectBoxActiveStyle = 'de-select de-select-active';
    let birthday = this.state

    return (
      <div className='ui form'>
        <div className="ui grid no-margin">
          <div className="four wide computer six wide tablet sixteen wide mobile column">
            <div className="ch-left-title">Are you a new Discover Energy customer?</div>
          </div>
          <div className="twelve wide computer ten wide tablet sixteen wide mobile column">
            <div className={['', this.state.select_customer_type == 'question_new_customer' ? selectBoxActiveStyle : selectBoxStyle].join(' ')} onClick={()=> this.changeCustomerType({select_customer_type: 'question_new_customer'})}>
              <span className="block">Yes</span>
            </div>
            <div className={['', this.state.select_customer_type == 'question_existing_customer' ? selectBoxActiveStyle : selectBoxStyle].join(' ')} onClick={()=> this.changeCustomerType({select_customer_type: 'question_existing_customer'})}>
              <span className="block">No</span>
            </div>
          </div>
        </div>
        {
          this.state.select_customer_type === 'question_new_customer' ?
            <NewCustomerQuestion
              handleNewCustomerInfoChange={(item)=>this.onHandleNewCustomerInfoChange(item)}
              new_customer_info = {this.state.new_customer_info}
              estimated_monthly_bill={this.state.estimated_monthly_bill}
              supply_address_can_show = {this.supply_address_can_show}
              energy_type={this.state.energy_type}
              handleEnergyTypeChange={(item) => this.onHandleEnergyTypeChange(item)}
              handleChange={(item) => this.onHandleChange(item)}/>
          :
            <ExistedCustomerQuestion
              handleExistedCustomerChange={(item)=>this.onHandleExistedCustomerChange(item)}
              existed_customer_info = {this.state.existed_customer_info}
              estimated_monthly_bill={this.state.estimated_monthly_bill}
              supply_address_can_show = {this.supply_address_can_show}
              valid_email = {this.props.valid_email} 
              energy_type={this.state.energy_type}
              handleEnergyTypeChange={(item) => this.onHandleEnergyTypeChange(item)}
              handleChange={(item) => this.onHandleChange(item)}/>
        }
        {
          this.vpp_can_show === true && this.state.energy_type == 'electricity' ?
            <VppQuestion
              handleVppCustomerInfoChange={(item) => this.onHandleVppCustomerInfoChange(item)}
              vpp_customer_info={this.state.vpp_customer_info}
              de_customer_email={this.state.existed_customer_info.email}
              vpp_policy={this.props.vpp_policy}
              />
          : ''
        }
        <div className='plan-list-info'>
          {
            this.supply_address_can_show === true ?
              <SearchType handlePlanSearchTypeChange={(item) => this.onHandlePlanSearchTypeChange(item)}
                          handleEnergyTypeChange={(item) => this.onHandleEnergyTypeChange(item)}
                          handleSearchTypeInfoChange={(item) => this.onHandleSearchTypeInfoChange(item)}
                          handlePlanChange = {(item) => this.onHandlePlanChange(item)}
                          updateState={(item) => this.updateState(item)}
                          energy_type={this.state.energy_type}
                          customer_type={this.state.customer_type}
                          plan_search_type = {this.state.plan_search_type}
                          vpp_customer_info = {this.state.vpp_customer_info}
                          search_type_info={this.state.search_type_info}
                          is_vpp={this.is_vpp}
                          birthday={this.birthday}/>
            : ''
          }
          {
            this.state.plans.result === true ?
              this.state.plans.data.planList.length != 0 && this.state.plans.data.nmi_info.is_reject == false ?
                <div>
                  <h3 className="ui header ch-left-bord">Choose your  energy plan</h3>
                  <div className="electricity-plans-body">
                    <div className="plan-body">
                      <div className="plan-content-area">
                        <div className="compare-plan">
                          <SignupPlan
                            handleSelectPlan = {(plans_data) => this.handleSelectPlan(plans_data)}
                            planList = {this.state.plans.data.planList}
                            nmi_info = {this.state.plans.data.nmi_info}
                            market_offer_id = {this.state.plans.selectedMarketOfferId}
                            isSelectPlan = {this.state.plans.isSelectPlan}
                            handleReviewQuotationClick = {()=>this.onHandleReviewQuotationClick()}
                            policy = {this.props.policy}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              :
                <div className='not-found-plans'>
                  <img src="//discover-energy.s3-ap-southeast-2.amazonaws.com/assets/images/not_found_plan.png" />
                  {
                    this.state.plans.data.nmi_info.is_reject === true ?
                      <p>Unfortunately, Discover Energy doesn't supply energy in your area.</p>
                    :
                      <p>Unfortunately, we did not find a plan.</p>
                  }
                </div>
            : null
          }
          {
            this.state.plans.result === false ?
              <div className='not-found-plans'>
                <img src="//discover-energy.s3-ap-southeast-2.amazonaws.com/assets/images/not_found_plan.png" />
                <p>Unfortunately, we did not find a plan.</p>
              </div>
            : null
          }
        </div>

        <div>
          {
            this.state.reviewQuotationShow ?
              <ReviewQuotation
                energyType={this.state.energy_type}
                visible={this.state.reviewQuotationShow}
                onHide={() => this.setState({reviewQuotationShow: false})}
                planOffer={this.state.plans}
                handleSubmit={(user_info)=> this.onHandleReviewQuotationSubmit(user_info)} />
            : ''
          }
        </div>
      </div>
    )
  }
}


SignNewPlan.propTypes = {
  cache_state_id: PropTypes.string,
  is_vpp_channel: PropTypes.bool,
};
export default SignNewPlan
