document.addEventListener("turbolinks:load", function() {
  $('#checkAll').change(function(){
    console.log("check all")
    $("input[name='commission_ids[]']:checkbox").prop("checked", this.checked);
  })
  $("input[name='commission_ids[]']:checkbox").change(function(){
    var $tmp = $("input[name='commission_ids[]']:checkbox")
    $("#checkAll").prop("checked", $tmp.length == $tmp.filter(':checked').length)
  })
  $(document).on('click', '.create_all_pending_commissions', function(e) {
    $('#build_commissions').modal({
      onApprove: function(){
        $(".create_all_pending_commissions").attr("disabled", true).addClass("disabled").text("Building")
        $(".audit_all_pending_commissions").attr("disabled", true).addClass("disabled")
        $.ajax({
          url: "/channel_commissions/create_all_pending_commissions",
          method: "post",
          type: "json",
          data: {
            authenticity_token: $('[name="csrf-token"]')[0].content
          }
        })
      }
    }).modal('show')
  })

  if($(".channel-commissions .tabular a:first").hasClass("active")){
    var timer
    build_worker()
    timer = setInterval(function(){
      build_worker()
    }, 6000);
  }

  function build_worker(){
    if(!$(".channel-commissions .tabular a:first").hasClass("active")){
      clearInterval(timer)
    }
    $.ajax({
      url:"/channel_commissions/build_worker_status",
      method: "GET",
      global: false,
      success: function(data){
        if(data.working){
          $(".create_all_pending_commissions").attr("disabled", true).addClass("disabled").text("Building")
          $(".audit_all_pending_commissions").attr("disabled", true).addClass("disabled")
        }else{
          $(".create_all_pending_commissions").removeAttr("disabled").removeClass("disabled").text("Build")
          $(".audit_all_pending_commissions").removeAttr("disabled").removeClass("disabled")
        }
      }
    })
  }
});