export default {
  fixedLength: function (value, max) {
    if(value.length>max) value=value.slice(0,max)
    return value
  },
  traverseObj: (targetObj, sourceObj) => {
    for(let prop in sourceObj){
        targetObj[prop] = sourceObj[prop]
    }
  },
  /**
   * The callback is executed n seconds after the event is triggered,
   * and if it is fired again within that n seconds, the timer is re-timed.
   */
  debounce: (callback, delay) =>{
    let timerId = null;
    return function (args) {
        let that = this;
        clearTimeout(timerId);
        timerId = setTimeout(function () {
            callback.call(that, args, time());
        }, delay);
    }
  },
  getValidDayRang: (month, year) => {
    month = Number.parseInt(month)
    year = Number.parseInt(year)
    let bigMonth = [1,3,5,7,8,10,12]
    let smallMonth = [4,6,9,11]
    let maxDay

    if(month === '' || year === '') {
      maxDay=31
    }
    if(month === 2 && year % 4 === 0) {
      maxDay = 29

    }
    if(month === 2 && year % 4 !== 0) {
      maxDay = 28

    }
    if(bigMonth.indexOf(month) !== -1) {
      maxDay = 31

    }
    if(smallMonth.indexOf(month) !== -1) {
      maxDay = 30

    }

    return maxDay
  },
  validityCheck: (value) => {
    let invalidityArr = [undefined, false, null, '', 0, '0']
    if(invalidityArr.indexOf(value) !== -1){
      return false
    }
    return true
  },
  // Three significant digits
  formateNumber: (numberVal, decimals) => {
    let isThanZero = false
    numberVal = Number(numberVal)
    
    let numStrArr = (numberVal+'').split('.')
    let _length = numStrArr.length
    if(_length === 1){
      numberVal = numberVal + '.00'
    } else {
      numberVal = (Math.round(numberVal*100)/100).toFixed(2)
    }
    return  numberVal
  },
  formateMoney: (val)  => {
    if(val !== null) {
          return new Number(val).toFixed().replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,');
        }
    return
  },
  formatePrecent: (val) => {
    if(val !== null) {
      return Math.round( new Number(val))
    }
    return
  },

  sort: (arr, type) => {
    let buddleSort = (arr, fn)  => {
      let len = arr.length
      while (len--) {
        for (let i = 0; i < len; i++) {
          if (fn(arr[i], arr[i + 1]) > 0) {
            [arr[i], arr[i + 1]] = [arr[i + 1], arr[i]]
          }
        }
      }
    }
    switch (type) {
      case 'asc':
        buddleSort(arr, (a,b) => a - b)
        break;

      case 'desc':
        buddleSort(arr, (a,b) => b - a)
        break

      default:
        break
    }
    return arr
  },

  getLocalStorageByKey: (key) => {
    return JSON.parse(localStorage.getItem(key))
  },
  setLocalStorageKeyValue: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
  },
  removeLocalStorageByKey: (key) => {
    localStorage.removeItem(key)
  },
  isBlank: (value) => {
    return [null, undefined, ''].includes(value)
  },
  isPresent: (value) => {
    return ![null, undefined, ''].includes(value)
  },
  group: (array, size) => {
    let index = 0;
    let newArray = []
    while(index < array.length) {
      newArray.push(array.slice(index, index += size))
    }
    return newArray
  }
}