import React from "react"
import Icon from 'antd/lib/icon'
import 'antd/lib/icon/style/css'

import Tooltip from 'antd/lib/tooltip'
import 'antd/lib/tooltip/style/css'

export class TooltipIcon extends React.Component {

  constructor(props){
    super(props)
    this.state = {}
  }

  render () {
    const title = this.props.title;
    return (
      <Tooltip placement="topRight" className="plan-tip" title={title}>
        <Icon type="info-circle" theme="filled" />
      </Tooltip>
    )
  }
}
export default TooltipIcon