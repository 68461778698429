import React from "react"
import PropTypes, { number } from "prop-types"
import { Modal, Button } from 'antd';
import Message from 'antd/lib/message'
import 'antd/lib/message/style/css'
import Validate from '../../lib/validate'
import Utility from '../../lib/utility'

import { Form, Input, Icon, DatePicker, Switch } from 'antd';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/date-picker/style/css';
import 'antd/lib/switch/style/css';
import moment from 'moment';

import { createForm, formShape } from 'rc-form'

function hasContolLoad_1(rates){
  let flag = false
  rates.map((rate, key) => {
    if (rate.name == 'control_load_1'){
      flag = true
    }
  })
  return flag
}
function hasContolLoad_2(rates){
  let flag = false
  rates.map((rate, key) => {
    if (rate.name == 'control_load_2'){
      flag = true
    }
  })
  return flag
}

function getRatePrice(rates, name){
  let value = null
  rates.map((rate, key) => {
    if (rate.name == name){
      value = rate.price
    }
  })
  return value
}

function sumDailySupplyPrice(arr) {
  let total_price = 0.0;
  for (let i=arr.length-1; i>=0; i--) {
      total_price += parseFloat(arr[i].price);
  }
  return total_price;
}

let id = 1

export class ReviewQuotation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bill_period: null,
      energy_type: '',
      days: null,
      days_price: null,
      peak_usage: 0,
      off_peak_usage: 0,
      shoulder_usage: 0,
      control_load_1: 0,
      control_load_2: 0,
      demand_usage_details: [],
      solar_usage: 0,
      solar_usage_peak: 100,
      solar_usage_shoulder: 0,
      solar_usage_sponge: 0,
      solar_usage_off_peak: 0,
      gas_usage: 0,
      total_amount: 0,
      items: [],
      days_item: {},
      total_amount_item: {},
      isNoDemandUsage: false,
      isSeasonDemand: false,
      hasDemand: false,
      isMultipleGasRate: false
    }

    Message.config({
      top: 100,
      duration: 3.5,
      maxCount: 1,
    })
    this.selectedPlan = null
  }

  formatUserInfo = (values) => {
    console.log(values)
    let _this = this
    // console.log(this.state)
    // let user_info = {
    //   "days": "28",
    //   "total": "-4.56",
    //   "solar_p": "10.2",
    //   "days_price": "89.001",
    //   "peak_usage": "98.99",
    //   "solar_usage": "540.26",
    //   "peak_usage_price": "19.7954834"
    // }
    const {
      days,
      peak_usage,
      off_peak_usage,
      shoulder_usage,
      control_load_1,
      control_load_2,
      solar_usage,
      solar,
      gas_usage,
      total_amount,
      demand_keys,
      demand_month_usages,
      demand_month_start_dates,
      demand_month_end_dates,
      multiple_gas_usage_keys,
      multiple_gas_usage_month_usages,
      multiple_gas_usage_month_start_dates,
      multiple_gas_usage_month_end_dates
    } = values;
    let user_info = {}
    user_info.days = days
    user_info.days_price = sumDailySupplyPrice(this.selectedPlan.daily_supply)

    if(this.state.energy_type == 'electricity'){
      // peak_usage, off_peak_usage, shoulder_usage, control_load_1, control_load_2, gas_usage
      let list = ["peak_usage", "off_peak_usage", "shoulder_usage", "control_load_1", "control_load_2", "gas_usage"]
      list.map((key, index) => {
        if (values[key] != undefined){
          user_info[key] = values[key]
        }
      })

      let price_list = ["off_peak_usage", "shoulder_usage", "control_load_1", "control_load_2"]
      price_list.map((key, index) => {
        if (values[key] != undefined){
          let current_key = `${key}_price`
          user_info[current_key] = getRatePrice(_this.selectedPlan.usage_rate, key)
        }
      })

      // single demand
      const {single_demand_usage} = values
      if(single_demand_usage != undefined){
        user_info.demand_usage = single_demand_usage
        user_info.demand_charge = this.selectedPlan.demand_detail[0].price
      }

      // Select No Demand Usage
      if(this.state.hasDemand && this.state.isNoDemandUsage){
        user_info.is_no_demand_usage = true
      }

      // Season Demand details
      if(this.state.isSeasonDemand && !this.state.isNoDemandUsage && demand_keys.length > 0){
        user_info.demand_details = demand_keys.map((k, index) => {
          let item = {
            start_date: demand_month_start_dates[k].format("YYYY-MM-DD"),
            end_date: demand_month_end_dates[k].format("YYYY-MM-DD"),
            usage: demand_month_usages[k]
          }
          return item
        })
      }

      // Solar
      const {solar_usage, solar_usage_peak, solar_usage_shoulder, solar_usage_sponge, solar_usage_off_peak} = values
      if(Utility.isPresent(this.selectedPlan.solar)){
        user_info['solar'] = {
          rate_type: this.selectedPlan.solar.rate_type,
          total_usage: solar_usage,
        }
        if (user_info.solar.rate_type == 'tou'){
          user_info['solar']['tou'] = {
            peak: solar_usage_peak * 0.01 * solar_usage,
            shoulder: solar_usage_shoulder * 0.01 * solar_usage,
            sponge: solar_usage_sponge * 0.01 * solar_usage,
            off_peak: solar_usage_off_peak * 0.01 * solar_usage
          }
        }
      }
    }

    
    if(this.state.energy_type == 'gas'){
      let list = ["gas_usage"]
      list.map((key, index) => {
        if (values[key] != undefined){
          user_info[key] = values[key]
        }
      })
      if(this.state.isMultipleGasRate){
        //Gas Usage Rate - Multiple prices a year
        user_info.multiple_gas_rate = JSON.stringify(this.selectedPlan.usage_rate)
        let days = 0
        user_info.multiple_gas_usages = multiple_gas_usage_keys.map((k, index) => {
          let item = {
            start_date: multiple_gas_usage_month_start_dates[k].format("YYYY-MM-DD"),
            end_date: multiple_gas_usage_month_end_dates[k].format("YYYY-MM-DD"),
            usage: multiple_gas_usage_month_usages[k]
          }
          days += multiple_gas_usage_month_end_dates[k].diff(multiple_gas_usage_month_start_dates[k], 'days') + 1
          return item
        })
        user_info.days = days
      }else{
        //Gas Usage Rate - only one price per year
        user_info.gas_rate = JSON.stringify(this.selectedPlan.usage_rate[0].details)
      }
      
    }

    // total
    user_info.total = total_amount

    console.log(user_info)
    return user_info
  }

  initValue() {
    let _this = this
    let allItems = []
    let planOffer = _this.props.planOffer
    if (planOffer.selectedMarketOfferId != null && planOffer.selectedMarketOfferId != undefined) {
      let plan = planOffer
      let selectedPlan = _.find(plan.planList, function(n){return n.market_offer_id == plan.selectedMarketOfferId})
      _this.selectedPlan = selectedPlan
      _this.setState({energy_type: selectedPlan.energy_type})

      if (selectedPlan.energy_type === 'electricity'){
        let control_load_1_flag = hasContolLoad_1(selectedPlan.usage_rate)
        let control_load_2_flag = hasContolLoad_2(selectedPlan.usage_rate)

        // Peak Usage, Off Peak Usage, Shoulder Usage, Control load 1, Control lodf 2
        let usageItems = selectedPlan.usage_rate.map((rate, key) => {
          let defaultValue = '0'
          if (selectedPlan.reference_price != undefined){
            if(control_load_1_flag && rate.name == 'control_load_1'){
              defaultValue = selectedPlan.reference_price.control_load_usage
            }else if(!control_load_1_flag && control_load_2_flag && rate.name == 'control_load_2'){
              defaultValue = selectedPlan.reference_price.control_load_usage
            }else {
              defaultValue = selectedPlan.reference_price[rate.name] || 0
            }
          }
          let item = {id: rate.name, title: rate.title + '(kWh)', defaultValue: defaultValue, rules: _this.getRules(rate.name)}
          return item
        })
        allItems = allItems.concat(usageItems)

        // Demand Usage
        let hasDemand = selectedPlan.demand_detail.length > 0 ? true : false
        let isSeasonDemand = selectedPlan.demand_detail.length > 1 ? true : false
        this.setState({
          hasDemand: hasDemand,
          isSeasonDemand: isSeasonDemand
        })

        // Total Amount Item, Days Item, isReference
        let total_amount_item = {}
        let days_item = {}
        let reference_amount = '0'
        let days = 0
        let is_reference = false
        let no_demand_usage = false
        if (selectedPlan.reference_price != undefined){
          reference_amount = selectedPlan.reference_price.reference_amount || 0
          days = 365
          is_reference = true
          no_demand_usage = true
        }
        // Days
        days_item = {id: 'days', title: 'Days', defaultValue: days, rules: _this.getRules('days')}
        

        // Total
        total_amount_item = {id: 'total_amount', title: 'Total Amount($)', defaultValue: reference_amount, rules: _this.getRules('total_amount')}

        _this.setState({
          items: allItems,
          total_amount_item: total_amount_item,
          days_item: days_item,
          isReferencePrice: is_reference,
          isNoDemandUsage: no_demand_usage
        })
      }

      // GAS
      if(selectedPlan.energy_type == "gas") {
        //Gas Usage
        let gasUsageItem = []
        let defaultValue = 0
        if (selectedPlan.reference_price) {
          defaultValue = selectedPlan.reference_price.reference_usage || 0
        }

        // Gas Usage Rate
        let is_multiple_gas_rate = false
        if(selectedPlan.usage_rate.length > 1){
          //Gas Usage Rate - Multiple prices a year
          is_multiple_gas_rate = true
        }else{
          //Gas Usage Rate - only one price per year
          is_multiple_gas_rate = false
          gasUsageItem = [
            {id: 'gas_usage', title: 'Gas Usage(MJ)', defaultValue: defaultValue, rules: _this.getRules('gas_usage')}
          ]
          allItems = allItems.concat(gasUsageItem)
        }

        // Total Amount Item, Days Item, isReference
        let total_amount_item = {}
        let days_item = {}
        let reference_amount = '0'
        let days = 0
        let is_reference = false
        let no_demand_usage = false
        if (selectedPlan.reference_price != undefined){
          reference_amount = selectedPlan.reference_price.reference_amount || 0
          days = 365
          is_reference = true
          no_demand_usage = true
        }
        // Days
        if(!is_multiple_gas_rate){
          days_item = {id: 'days', title: 'Days', defaultValue: days, rules: _this.getRules('days')}
        }

        // Total
        total_amount_item = {id: 'total_amount', title: 'Total Amount($)', defaultValue: reference_amount, rules: _this.getRules('total_amount')}

        _this.setState({
          items: allItems,
          total_amount_item: total_amount_item,
          days_item: days_item,
          isReferencePrice: is_reference,
          isMultipleGasRate: is_multiple_gas_rate
        })
      }
    }
  }

  handleChange = (e) => {
    let change_id = e.target.id
    this.setState({
      [e.target.id]: e.target.value
    }, () => {
      const tou_item_keys = ['solar_usage_peak', 'solar_usage_shoulder', 'solar_usage_sponge', 'solar_usage_off_peak']
      if(tou_item_keys.includes(change_id)){
        this.props.form.validateFields(tou_item_keys)
      }
    })
  }

  handleNoDemandUsageSwitchChange = (e) => {
    this.setState({isNoDemandUsage: e})
  }

  planList() {
    let plans = this.state.items.map((item, key) => {
      return (
        <React.Fragment key={key}>
          {this.usageRateInput(item)}
        </React.Fragment>
      )
    })
    return (
      <React.Fragment>
        {plans}
      </React.Fragment>
    )
  }

  validateGreaterThanStartDate = (rule, value, callback,source, options) => {
    let disabledValue = [undefined, null]
    let message = rule.message || 'greater than start date'
    if(disabledValue.indexOf(value) == -1 && value.isBefore(rule.start_date)){
      callback(message)
    }
    callback()
  }

  validateSameMonth = (rule, value, callback,source, options) => {
    let disabledValue = [undefined, null]
    let message = rule.message || 'must be the same month'
    if(disabledValue.indexOf(value) == -1 && disabledValue.indexOf(rule.start_date) == -1 && value.month() != rule.start_date.month()){
      callback(message)
    }
    callback()
  }

  validateTotalPercentage = (rule, value, callback, source, options) => {
    const { getFieldValue } = this.props.form;
    let percent_item_ids = rule.item_ids
    let total_percentage = percent_item_ids.map((key) => getFieldValue(key)).reduce((a, b) => Number(a) + Number(b), 0)
    total_percentage = Utility.formateNumber(total_percentage, 2)
    if(100.00 != total_percentage){
      callback(rule.message)
    }
    callback()
  }

  hasPeriodDemand = (values) => {
    const {
      demand_keys
    } = values
    return (this.state.energy_type == 'electricity' && this.state.isSeasonDemand && !this.state.isNoDemandUsage && demand_keys.length > 0)
  }

  validateDemandPeriodEqualDays = (values) => {
    const {
      demand_keys,
      demand_month_start_dates,
      demand_month_end_dates,
    } = values
    let demand_total_days = 0
    // Season Demand details
    demand_keys.map((k, index) => {
      let end_date = demand_month_end_dates[k]
      let start_date = demand_month_start_dates[k]
      demand_total_days += end_date.diff(start_date, 'day') + 1
    })
    return demand_total_days == this.state.days ? true : false
  }

  componentDidMount() {
    this.initValue()
  }

  solarInput = () => {
    let rate_solar = this.props.planOffer.selectedMarketOffer.solar
    let errors;
    const { getFieldProps, getFieldError } = this.props.form;
    if(Utility.isBlank(rate_solar)){ return ''}
    if(rate_solar.rate_type == 'single' || rate_solar.rate_type == 'step'){
      let item = {id: 'solar_usage', title: "Solar Usage(kWh)", defaultValue: 0, rules: this.getRules('solar_usage')}
      return this.usageRateInput(item)
    }

    if(rate_solar.rate_type == 'tou'){
      let total_solar_item = {id: 'solar_usage', title: "Total Solar Usage(kWh)", defaultValue: 0, rules: this.getRules('solar_usage')}
      let item_ids = rate_solar.tou.map((item) => 'solar_usage_' + item.price_class)
      let rules = this.getRules('solar_usage_percent').concat({validator: this.validateTotalPercentage, message: 'total percentage must be 100%', item_ids: item_ids})
      let tou_price_class_array = rate_solar.tou.map((item) => {
        return {id: 'solar_usage_' + item.price_class, title: item.title + "(%)", defaultValue: item.price_class == 'peak' ? 100 : 0, rules: rules}
      })

      const tou_items = Utility.group(tou_price_class_array, 2).map((items, index) => {
        return (<div className="flex-def flex-zSpace" key={index}>
                  {
                    items.map((item, i) => (
                      <div className="row no-margin field" key={i}>
                        <label htmlFor={item.id}>{item.title}</label>
                        <div className="os-input">
                          <div className="flex-def center-content">
                            <input
                              id={item.id}
                              type="number"
                              placeholder={item.title}
                              className={[ 'width-50', 'form-control' , getFieldError(item.id) ? 'validate-error-input' : ''].join(' ')}
                              {...getFieldProps(item.id, {
                              initialValue: item.defaultValue,
                              onChange: this.handleChange,
                              rules: item.rules
                            })}/>
                            <span className="solar-suffix">{ this.getPercentSolarValue(item.id) + ' kWh' }</span>
                          </div>
                          <div className="validate-error-info">
                            {(errors = getFieldError(item.id))? errors.join(',') : null}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>)
      })
      return (
        <React.Fragment>
          {this.usageRateInput(total_solar_item)}
          {tou_items}
        </React.Fragment>
      )
    }
  }

  getPercentSolarValue = (solar_rate) => {
    return Utility.formateNumber(this.state.solar_usage * this.state[solar_rate] * 0.01, 2)
  }


  getRules = (rate_name) => {
    let rules = []
    switch(rate_name) {
      case 'days':
        rules = [
          {validator: Validate.validateEmpty, message: 'this is required'},
          {pattern: /^([0-9]\d?|[12]\d\d|3[0-5]\d|36[0-5])$/ , message: "please enter the number 0~365"}
        ]
        break;
      case 'peak_usage':
        rules = [
          {validator: Validate.validateEmpty, message: 'this is required'},
          {pattern: /^\d+(\.\d+)?$/ , message: "please enter the number"}
        ]
        break;
      case 'off_peak_usage':
        rules = [
          {validator: Validate.validateEmpty, message: 'this is required'},
          {pattern: /^\d+(\.\d+)?$/ , message: "please enter the number"}
        ]
        break;
      case 'shoulder_usage':
        rules = [
          {validator: Validate.validateEmpty, message: 'this is required'},
          {pattern: /^\d+(\.\d+)?$/ , message: "please enter the number"}
        ]
        break;
      case 'control_load_1':
        rules = [
          {validator: Validate.validateEmpty, message: 'this is required'},
          {pattern: /^\d+(\.\d+)?$/ , message: "please enter the number"}
        ]
        break;
      case 'control_load_2':
        rules = [
          {validator: Validate.validateEmpty, message: 'this is required'},
          {pattern: /^\d+(\.\d+)?$/ , message: "please enter the number"}
        ]
        break;
      case 'solar_usage':
        rules = [
          {validator: Validate.validateEmpty, message: 'this is required'},
          {pattern: /^\d+(\.\d+)?$/ , message: "please enter the number"}
        ]
        break;
      case 'solar_usage_percent':
        rules = [
          {validator: Validate.validateEmpty, message: 'this is required'},
          {pattern: /^100$|^\d{0,2}(\.\d{1,2})? *%?$/, message: "please enter 0~100%"}
        ]
        break;
      case 'gas_usage':
        rules = [
          {validator: Validate.validateEmpty, message: 'this is required'},
          {pattern: /^\d+(\.\d+)?$/ , message: "please enter the number"}
        ]
        break;
      case 'total_amount':
        rules = [
          {validator: Validate.validateEmpty, message: 'this is required'}
        ]
        break;
      default:
        rules = []
    }
    return rules;
  }

  // Season Demand
  seasonDemandUsageInput = () => {
    let _this = this
    let errors;
    const { getFieldProps, getFieldError, getFieldValue } = this.props.form;
    getFieldProps("demand_keys", {initialValue: [0]})
    let demand_keys = getFieldValue("demand_keys")
    const demnad_items = demand_keys.map((k, index) => (
      <React.Fragment key={`demand_details[${k}]`} >
        <div className="flex-def flex-zSpace">
          <div className="row no-margin field">
            <label>Start Date</label>
            <div className="os-input flex-def flex-column flex-1">
              <DatePicker
                showToday={false}
                id={`demand_month_start_dates[${k}]`}
                className={['form-control', 'antd-date-picker-control', getFieldError(`demand_month_start_dates[${k}]`) ? 'validate-error-input' : ''].join(' ')}
                {...getFieldProps(`demand_month_start_dates[${k}]`, {
                  id: `demand_month_start_dates[${k}]`,
                  rules: [
                    { type: 'object', required: true, message: 'Please select time!' }
                  ]
              })}/>
              <div className="validate-error-info">
                {(errors = getFieldError(`demand_month_start_dates[${k}]`))? errors.join(',') : null}
              </div>
            </div>
          </div>
          <div className="row no-margin field padding-lr-5">
            <label>End Date</label>
            <div className="os-input flex-def flex-column flex-1">
              <DatePicker
                showToday={false}
                id={`demand_month_end_dates[${k}]`}
                className={['form-control', 'antd-date-picker-control', getFieldError(`demand_month_end_dates[${k}]`) ? 'validate-error-input' : ''].join(' ')}
                {...getFieldProps(`demand_month_end_dates[${k}]`, {
                  id: `demand_month_end_dates[${k}]`,
                  rules: [
                    { type: 'object', required: true, message: 'Please select time!' },
                    { validator: _this.validateGreaterThanStartDate, message: 'greater than start date', start_date: getFieldValue(`demand_month_start_dates[${k}]`) }
                  ]
              })}/>
              <div className="validate-error-info">
                {(errors = getFieldError(`demand_month_end_dates[${k}]`))? errors.join(',') : null}
              </div>
            </div>
          </div>
          <div className="row no-margin field">
            <label>Usage</label>
            <div className="os-input">
              <input
                id={`demand_month_usages[${k}]`}
                className={['form-control' , getFieldError(`demand_month_usages[${k}]`) ? 'validate-error-input' : ''].join(' ')}
                {...getFieldProps(`demand_month_usages[${k}]`, {
                  id: `demand_month_usages[${k}]`,
                  rules: [
                    {validator: Validate.validateEmpty, message: 'this is required'},
                    {required: true, pattern: /^\d+(\.\d+)?$/ , message: "please enter the number"}
                  ]
              })}/>
              <div className="validate-error-info">
                {(errors = getFieldError(`demand_month_usages[${k}]`))? errors.join(',') : null}
              </div>
            </div>
          </div>
          {
            demand_keys.length > 1 ? (
              <div className="row no-margin field">
                <label htmlFor="remove_demand"></label>
                <div id="remove_demand" onClick={ () => this.removeDemand(k)}>
                  <Icon style={{ fontSize: '22px'}} type="minus-circle" />
                </div>
              </div>
            ) : null
          }
        </div>
      </React.Fragment>
    ))
    return demnad_items
  }

  // Single Demand
  singleDemandInput = () => {
    let errors
    const { getFieldProps, getFieldError } = this.props.form
    return(
      <div className="row no-margin field">
        <label>Capacity Demand Usage(KW)</label>
        <div className="os-input">
          <input
            id={'single_demand_usage'}
            className={['form-control' , getFieldError('single_demand_usage') ? 'validate-error-input' : ''].join(' ')}
            {...getFieldProps('single_demand_usage', {
              id: 'single_demand_usage',
              rules: [
                {validator: Validate.validateEmpty, message: 'this is required'},
                {required: true, pattern: /^\d+(\.\d+)?$/ , message: "please enter the number"}
              ]
          })}/>
          <div className="validate-error-info">
            {(errors = getFieldError('single_demand_usage'))? errors.join(',') : null}
          </div>
        </div>
      </div>
    )
  }

  demandInput = () => {
    if(this.state.energy_type != 'electricity' || !this.state.hasDemand){
      return;
    }
    let _this = this
    let errors
    const { getFieldProps, getFieldError } = this.props.form

    if(this.state.isSeasonDemand){
      return (
        <React.Fragment>
          <div className="row no-margin flex-def flex-zSpace">
            <div className="field">
              <label htmlFor="isNoDemandUsage" className="inline-show">No Demand Usage: </label>
              <Switch
                id='isNoDemandUsage'
                className={['form-control' , getFieldError('isNoDemandUsage') ? 'validate-error-input' : ''].join(' ')}
                {...getFieldProps('isNoDemandUsage', {
                  id: 'isNoDemandUsage',
                  onChange: this.handleNoDemandUsageSwitchChange,
                  initialValue: this.state.isNoDemandUsage
              })}/>
            </div>
            {
              !this.state.isNoDemandUsage ?
                <div className="field">
                  <label htmlFor="add_demand" className="inline-show">Add Demand: </label>
                  <div id="add_demand" onClick={this.addDemand} className="inline-show" >
                    <Icon style={{ fontSize: '22px'}} type="plus-circle" />
                  </div>
                </div>
              : null
            }
          </div>
          {
            this.state.isNoDemandUsage == false ?
              this.seasonDemandUsageInput()
            : null
          }
        </React.Fragment>
      )
    }else{
      return this.singleDemandInput()
    }
  }

  gasUsageInput = () => {
    if(this.state.isMultipleGasRate){
      return (
        <React.Fragment>
          <div className="row no-margin flex-def flex-zSpace">
            <div className="field">
            </div>
            <div className="field">
              <label htmlFor="add_gas_usage" className="inline-show">Add Usage: </label>
              <div id="add_gas_usage" onClick={this.addMultipleGasUsage} className="inline-show" >
                <Icon style={{ fontSize: '22px'}} type="plus-circle" />
              </div>
            </div>
          </div>
          <React.Fragment>
            {this.multipleGasUsageInput()}
          </React.Fragment>
        </React.Fragment>
      )
    }
  }

  multipleGasUsageInput = () => {
    let _this = this
    let errors;
    const { getFieldProps, getFieldError, getFieldValue } = this.props.form;
    getFieldProps("multiple_gas_usage_keys", {initialValue: [0]})
    let multiple_gas_usage_keys = getFieldValue("multiple_gas_usage_keys")
    const multiple_gas_items = multiple_gas_usage_keys.map((k, index) => (
      <React.Fragment key={`multiple_gas_usage_details[${k}]`} >
        <div className="flex-def flex-zSpace">
          <div className="row no-margin field">
            <label>Start Date</label>
            <div className="os-input flex-def flex-column flex-1">
              <DatePicker
                showToday={false}
                id={`multiple_gas_usage_month_start_dates[${k}]`}
                className={['form-control', 'antd-date-picker-control', getFieldError(`multiple_gas_usage_month_start_dates[${k}]`) ? 'validate-error-input' : ''].join(' ')}
                {...getFieldProps(`multiple_gas_usage_month_start_dates[${k}]`, {
                  id: `multiple_gas_usage_month_start_dates[${k}]`,
                  rules: [
                    { type: 'object', required: true, message: 'Please select time!' }
                  ]
              })}/>
              <div className="validate-error-info">
                {(errors = getFieldError(`multiple_gas_usage_month_start_dates[${k}]`))? errors.join(',') : null}
              </div>
            </div>
          </div>
          <div className="row no-margin field padding-lr-5">
            <label>End Date</label>
            <div className="os-input flex-def flex-column flex-1">
              <DatePicker
                showToday={false}
                id={`multiple_gas_usage_month_end_dates[${k}]`}
                className={['form-control', 'antd-date-picker-control', getFieldError(`multiple_gas_usage_month_end_dates[${k}]`) ? 'validate-error-input' : ''].join(' ')}
                {...getFieldProps(`multiple_gas_usage_month_end_dates[${k}]`, {
                  id: `multiple_gas_usage_month_end_dates[${k}]`,
                  rules: [
                    { type: 'object', required: true, message: 'Please select time!' },
                    { validator: _this.validateGreaterThanStartDate, message: 'greater than start date', start_date: getFieldValue(`multiple_gas_usage_month_start_dates[${k}]`) }
                  ]
              })}/>
              <div className="validate-error-info">
                {(errors = getFieldError(`multiple_gas_usage_month_end_dates[${k}]`))? errors.join(',') : null}
              </div>
            </div>
          </div>
          <div className="row no-margin field">
            <label>Usage</label>
            <div className="os-input">
              <input
                id={`multiple_gas_usage_month_usages[${k}]`}
                className={['form-control' , getFieldError(`multiple_gas_usage_month_usages[${k}]`) ? 'validate-error-input' : ''].join(' ')}
                {...getFieldProps(`multiple_gas_usage_month_usages[${k}]`, {
                  id: `multiple_gas_usage_month_usages[${k}]`,
                  rules: [
                    {validator: Validate.validateEmpty, message: 'this is required'},
                    {required: true, pattern: /^\d+(\.\d+)?$/ , message: "please enter the number"}
                  ]
              })}/>
              <div className="validate-error-info">
                {(errors = getFieldError(`multiple_gas_usage_month_usages[${k}]`))? errors.join(',') : null}
              </div>
            </div>
          </div>
          {
            multiple_gas_usage_keys.length > 1 ? (
              <div className="row no-margin field">
                <label htmlFor="remove_multiple_gas_usage"></label>
                <div id="remove_multiple_gas_usage" onClick={ () => this.removeMultipleGasUsage(k)}>
                  <Icon style={{ fontSize: '22px'}} type="minus-circle" />
                </div>
              </div>
            ) : null
          }
        </div>
      </React.Fragment>
    ))
    return multiple_gas_items
  }

  addMultipleGasUsage = () => {
    const { form } = this.props;
    const multiple_gas_usage_keys = form.getFieldValue("multiple_gas_usage_keys");
    const nextKeys = multiple_gas_usage_keys.concat(id++);
    form.setFieldsValue({
      multiple_gas_usage_keys: nextKeys
    });
  }

  removeMultipleGasUsage = (k) => {
    const { form } = this.props;
    const multiple_gas_usage_keys = form.getFieldValue("multiple_gas_usage_keys");
    if (multiple_gas_usage_keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      multiple_gas_usage_keys: multiple_gas_usage_keys.filter((key) => key !== k)
    });
  }

  // example
  // item: {
  //   id: String,
  //   title: String,
  //   defaultValue: Number|String,
  //   rules: [
  //     {validator: Validate.validateEmpty, message: I18n.t('required')},
  //     {pattern:/^[0-9]{0,10}$/ , message: "this is required"},
  //   ]
  // }
  usageRateInput = (item) => {
    let disabledValue = [undefined, null, '']
    if(disabledValue.indexOf(item.id) != -1){
      return;
    }
    let errors;
    const { getFieldProps, getFieldError } = this.props.form;
    return(
      <div className="row no-margin field">
        <label htmlFor={item.id}>{item.title}</label>
        <div className="os-input">
          <input
            id={item.id}
            type="number"
            placeholder={item.title}
            className={['form-control' , getFieldError(item.id) ? 'validate-error-input' : ''].join(' ')}
            {...getFieldProps(item.id, {
            initialValue: item.defaultValue,
            onChange: this.handleChange,
            rules: item.rules,
          })}/>
          <div className="validate-error-info">
            {(errors = getFieldError(item.id))? errors.join(',') : null}
          </div>
        </div>
      </div>
    )
  }

  addDemand = () => {
    const { form } = this.props;
    const demand_keys = form.getFieldValue("demand_keys");
    const nextKeys = demand_keys.concat(id++);
    form.setFieldsValue({
      demand_keys: nextKeys
    });
  }

  removeDemand = (k) => {
    const { form } = this.props;
    const demand_keys = form.getFieldValue("demand_keys");
    if (demand_keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      demand_keys: demand_keys.filter((key) => key !== k)
    });
  }

  handleSubmit = () => {
    let _this = this
    this.props.form.validateFields((error, values) => {
      if(error !== null){
        Message.error('Please check your fill out information !')
      }else{
        if(this.state.energy_type == 'electricity' && this.hasPeriodDemand(values) && this.validateDemandPeriodEqualDays(values) == false){
          Message.error("demand period is not equal days!")
          return;
        }
        let user_info = _this.formatUserInfo(values)
        this.props.handleSubmit(user_info)
      }
    })
  }

  render () {
    return (
      <div className='ui modal'>
        <Modal
          title="Review Quotation"
          visible={this.props.visible}
          centered
          onOk={this.props.handleSubmit}
          onCancel={this.props.onHide}
          maskClosable={false}
          footer={
            <div className='modal-actions-center'>
              <button type='button' className='ui ch-orange button' onClick={this.props.onHide}>Close</button>
              <button type='button' className='ui ch-main button' onClick={this.handleSubmit}>Submit</button>
            </div>
          }
        >
          <div className="ui form">
            { this.usageRateInput(this.state.days_item) }
            { this.planList() }
            { this.demandInput() }
            { this.gasUsageInput() }
            { this.solarInput() }
            { this.usageRateInput(this.state.total_amount_item) }
          </div>
        </Modal>
      </div>
    )
  }
}
export default createForm()(ReviewQuotation)
