import React from "react"
import PropTypes, { number } from "prop-types"

import './index.scss'

import Utility from '../../lib/utility'

import Divider from 'antd/lib/divider'
import 'antd/lib/divider/style/css'

import Icon from 'antd/lib/icon'
import 'antd/lib/icon/style/css'

import Message from 'antd/lib/message'
import 'antd/lib/message/style/css'

import Spin from 'antd/lib/spin'
import 'antd/lib/spin/style/css'


import Collapse from 'antd/lib/collapse'
import 'antd/lib/collapse/style/css';

import Switch from 'antd/lib/switch'
import 'antd/lib/switch/style/css';

import { CSSTransitionGroup } from 'react-transition-group'

import Tooltip from 'antd/lib/tooltip'
import 'antd/lib/tooltip/style/css'

export class SignupPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelectPlan: false,
      isShowLoading: false,
      planList: [],
      isMainOfferShow: false,
      isRateInfoShow: true,
      gstRate: 1,
      isShowInclGst: true,
      selectPlanOfferInfo: {}
    }
    this.mainOfferHeight = {
      min: 0,
      max: 0
    }
    this.planListHeightInfo = {
      offerMaxHeight: 0,
      rateMaxHeight: 0
    }
    this.site_postcode = this.props.nmi_info.postcode
    console.log(this.props)
  }

  changePlanSubmit = () => {
    let quote_history_id = $('#quote_history_id').val()
    // let planOffer = localStorage.getItem("comparePlanOffer");
    let planOffer = this.state.selectPlanOfferInfo
    if (planOffer.selectedMarketOfferId != undefined && planOffer.selectedMarketOfferId != null) {
      let plan = planOffer
      let selectedPlan  = _.find(plan.planList, function(n){return n.market_offer_id == plan.selectedMarketOfferId})
      return $.ajax({
        url: '/quote_histories/'+quote_history_id+ '/change_plan',
        method: 'put',
        data: {
          selectedPlan: selectedPlan,
          selectedMarketOfferId: plan.selectedMarketOfferId,
          authenticity_token: $('[name="csrf-token"]')[0].content
        }
      });
    }
  }

  cancelChangePlan = ()=>{
    $('#quote_history_plan_list').hide();
  }


  selectplan = (offer, index)=> {
    this.setState({
      market_offer_id: offer.market_offer_id,
      price_plan_id: offer.price_plan_id,
      engage_offering_id: offer.engage_offering_id
    }, () => {
    })
    this.setState({
      isSelectPlan: true
    })
    let comparePlanOffer = {
      planList: this.props.planList,
      selectedMarketOfferId: offer.market_offer_id,
      postcode: this.props.postcode,
      address: null,
      nmi: this.props.nmi_info.nmi,
      mirn: this.props.nmi_info.mirn,
      checksum: this.props.nmi_info.checksum
    }
    this.setState({selectPlanOfferInfo: comparePlanOffer})
  }
  backPlanList = () => {
    this.setState({isSelectPlan: false})
  }

  formateRates (rates) {
    let ratesArr = []
    if(rates.general_usage !== undefined && rates.general_usage !== null) {
      ratesArr.push(rates.general_usage)
    }
    if(rates.daily_charge !== undefined && rates.daily_charge !== null) {
      ratesArr.push(rates.daily_charge)
    }
    if(rates.controlled_load1 !== undefined && rates.controlled_load1 !== null) {
      ratesArr.push(rates.controlled_load1)
    }
    if(rates.controlled_load2 !== undefined && rates.controlled_load2 !== null) {
      ratesArr.push(rates.controlled_load2)
    }
    if(rates.solar !== undefined && rates.solar !== null) {
      ratesArr.push(rates.solar)
    }
    return ratesArr
  }

  showLoading = () => {
    this.setState({
      isShowLoading: true,
    })
  }

  hideLoading = () => {
    this.setState({
      isShowLoading: false,
    })
  }
  cancelSelect = () => {
    this.setState({
      isSelectPlan: false,
      market_offer_id: '' ,
      price_plan_id: '',
      engage_offering_id: ''
    })
    // localStorage.removeItem("comparePlanOffer")
  }


  showMainInfo = () => {
    this.setState({
      isMainOfferShow: !this.state.isMainOfferShow
    })
  }

  showRateInfo = () => {
    this.setState({
      isRateInfoShow: !this.state.isRateInfoShow
    })
  }


  setPlan () {
    this.showLoading()
    let _timer = setTimeout(() => {
      this.setState({
        planList: this.props.planList
      })
      this.hideLoading()
      clearTimeout(_timer)
    }, 1000);
  }

  setOfferAnimate = () => {
    let mainOfferItems = $('.main-offer-info')
    let mainOfferItemHeights = []
    if(this.state.isMainOfferShow) {

      this.state.planList.forEach((offer, index) => {
        let offerItemContents = $(`[data-id=${offer.market_offer_id}] .item-content`)
        offerItemContents.each((key, index) => {
          if(typeof key === 'number') {
            if(mainOfferItemHeights[key] === undefined) {
              mainOfferItemHeights[key] =  $(offerItemContents[key]).height()
            } else {
              mainOfferItemHeights[key] = $(offerItemContents[key]).height() >  mainOfferItemHeights[key] ? $(offerItemContents[key]).height() : mainOfferItemHeights[key]
            }
          }
        })

      })

      this.state.planList.forEach((offer, index) => {
        let offerItems = $(`[data-id=${offer.market_offer_id}]`)
        offerItems.each((key, index) => {
          if(typeof key === 'number') {
            $(offerItems[key]).css('height',mainOfferItemHeights[key] + 32 + 'px'  )
          }
        })
      })

      let offerTotalHeight = 33
      mainOfferItemHeights.forEach((itemHeight, index) => {
        offerTotalHeight = offerTotalHeight + itemHeight + 32
      })

      mainOfferItems.each( (key) => {
        if(typeof key === 'number') $(mainOfferItems[key]).css('height', offerTotalHeight + 'px')
      })
    } else {
      let _offerMax = 0
      this.state.planList.forEach((offer, index) => {
        let offerItems = $(`[data-id=${offer.market_offer_id}]`)
        _offerMax = offer.product_features.length > _offerMax ? offer.product_features.length : _offerMax
        offerItems.each((key, index) => {
          if(typeof key === 'number') {
            $(offerItems[key]).css('height',+ 32 + 'px'  )
          }
        })
      })
      mainOfferItems.each( (key) => {
        if(typeof key === 'number') $(mainOfferItems[key]).css('height', _offerMax*32 + 33)
      })
      return
      if( this.planListHeightInfo.offerMaxHeight > 0) {
        // set maxHegiht

      } else {
          // get maxHegiht
          mainOfferItems.each( (key) => {
            if(typeof key === 'number') this.planListHeightInfo.offerMaxHeight =  $(mainOfferItems[key]).height() > this.planListHeightInfo.offerMaxHeight ? $(mainOfferItems[key]).height() : this.planListHeightInfo.offerMaxHeight
          })
          // set maxHegiht
          mainOfferItems.each( (key) => {
            if(typeof key === 'number') $(mainOfferItems[key]).css('height', this.planListHeightInfo.offerMaxHeight + 'px')
          })
      }
    }
  }

  setDescribleAnimate = () => {
    let descriptionItems = $('.plan-describle')
    let descMaxHeight = 0
    // get max
    descriptionItems.each( (key) => {
      if(typeof key === 'number') descMaxHeight =  $(descriptionItems[key]).height() > descMaxHeight ? $(descriptionItems[key]).height() : descMaxHeight
    })
    // set max
    descriptionItems.each( (key) => {
      if(typeof key === 'number') $(descriptionItems[key]).css('height', descMaxHeight)
    })
  }

  showInclGst = (checked) => {
    if(checked) {
      this.setState({
        gstRate: 1.1,
        isShowInclGst: true
      })
      return
    }
    this.setState({
      gstRate: 1,
      isShowInclGst: false
    })
  }

  componentWillMount() {
    this.setDescribleAnimate()
    this.setPlan()
  }

  componentDidUpdate() {
    this.setOfferAnimate()
    this.setDescribleAnimate()
    this.setSingleAnimate('plan-describle')
    this.setSingleAnimate('solar-info')
    this.setSingleAnimate('rate-peak_usage-info')
    this.setSingleAnimate('rate-off_peak_usage-info')
    this.setSingleAnimate('rate-shoulder_usage-info')
    this.setSingleAnimate('rate-control_load_1-info')
    this.setSingleAnimate('rate-control_load_2-info')
    this.setSingleAnimate('basic-info-content')
    this.setSingleAnimate('planItem .signup-plan-box .plan-head .title')
  }

  setSingleAnimate = (className) => {
    let classNameItems = $(`.${className}`)
    let maxHeight = 0
    // get max
    classNameItems.each( (key) => {
      if(typeof key === 'number') maxHeight =  $(classNameItems[key]).height() > maxHeight ? $(classNameItems[key]).height() : maxHeight
    })
    // set max
    classNameItems.each( (key) => {
      if(typeof key === 'number') $(classNameItems[key]).css('height', maxHeight)
    })
  }

  nextClickDisplay() {
    if (this.state.isSelectPlan){
      return (
        <div className="plan-next-client">
          <button type="button" className="ui ch-gray button" onClick={()=>this.cancelChangePlan()}>Cancel</button>
          <button type="button" id="quote-history-change-plan-submit" className="ui green button" onClick={()=>this.changePlanSubmit()}>Change Plan</button>
        </div>
      )
    }
  }

  reference_price_discount(offer) {
    let energyType = offer.energy_type
    let discount = offer.reference_price_discount
    let show_estimated_cost = offer.show_estimated_cost
    if (discount != undefined && discount != null && show_estimated_cost == false ) {

      let text = "less than reference price"
      let priceHtml = <div className="pricie-symbol">
        <span className="pricie-status">　</span>
        <span className="">%</span>
      </div>

      if (energyType == 'gas') {
        text = "Discover standing rate"
        priceHtml = <div className="pricie-symbol">
          <span className="">%</span>
          <span className="pricie-status">off</span>
        </div>
      }

      if (offer.state == 'VIC') {
        text = "less than Victoria default offer"
      }

      return (
        <div>
          <div className="percent-info">
            <div className="pricie-number">
              <span>{Utility.formatePrecent(discount)}</span>
            </div>
            {priceHtml}
          </div>
          <div>{text}</div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="percent-info">
            <div className="pricie-number">
              <span></span>
            </div>
            <div className="pricie-symbol">
              <span className=""></span>
              <span className="pricie-status"></span>
            </div>
          </div>
          <div></div>
        </div>
      )
    }
  }

  calcReferenceUsage = (offer) => {
    let referrenceUsage = 20000
    if (offer.reference_price) {
      referrenceUsage = offer.reference_price.reference_usage
    }

    return Utility.formateMoney(new Number(referrenceUsage))
  }

  calcReferenceDailyUsage = (offer) => {
    let referrenceDailyUsage = 20000 / 365
    if (offer.reference_price) {
      referrenceDailyUsage = offer.reference_price.reference_daily_usage
    }

    return Utility.formateMoney(new Number(referrenceDailyUsage))
  }

  referencePriceDesc = (offer) => {
    let usage_unit = ''
    let description = ''
    let network = offer.network
    let usage = 20000

    if (offer.energy_type == 'electricity') {
      usage_unit = 'kWh'
      if (offer.show_estimated_cost == true){
        if (offer.customer_type == 'residential') {
          description = `Your estimated annual cost based on an average consumption of ${this.calcReferenceUsage(offer)} for residential customer in the ${network} network`
        } else {
          description = `Your estimated annual cost based on an average consumption of ${this.calcReferenceUsage(offer)} for a business customer in the ${network} network`
        }
      } else {
        if (offer.is_reference_price) {

          usage = Number(offer.reference_price.reference_usage)
          usage = Utility.formateMoney(usage)
          if (offer.customer_type == 'residential') {
            description = `For an average household using ${usage} ${usage_unit} / yearly on a flat rate tariff in the ${network} network.`
          } else {
            description = `For an average small business using ${usage} ${usage_unit} / yearly on a flat rate tariff in the ${network} network.`
          }
        } else {
          if (offer.customer_type == 'residential') {
            description = `For residential customers in the ${network} network area.`
          } else {
            description = `For business customers in the ${network} network area.`
          }
        }
      }
    } else {
      usage_unit = 'MJ'
      if (offer.show_estimated_cost == true){
        if (offer.customer_type == 'residential') {
          description = `the estimated cost is based on an average of ${this.calcReferenceDailyUsage(offer)} MJ/day for residential customers in postcode ${this.site_postcode}.`
        } else {
          description = `the estimated cost is based on an average of ${this.calcReferenceDailyUsage(offer)} MJ/day for business customers in postcode ${this.site_postcode}.`
        }
      } else {
        if (offer.is_reference_price) {

          usage = Number(offer.reference_price.reference_usage)
          usage = Utility.formateMoney(usage)
          if (offer.customer_type == 'residential') {
            description = `For an average household using ${usage} ${usage_unit} / yearly on a flat rate tariff in the ${network} network.`
          } else {
            description = `For an average small business using ${usage} ${usage_unit} / yearly on a flat rate tariff in the ${network} network.`
          }
        } else {
          if (offer.customer_type == 'residential') {
            description = `For residential customers in the ${network} network area.`
          } else {
            description = `For business customers in the ${network} network area.`
          }
        }
      }
    }

    return (
      <p className="reference-desc">
        {description}
      </p>
    )
  }

  usageRateDesc = (offer) => {
    if (offer.energy_type === 'gas') {
      return (
        <React.Fragment>
        { this.gasStepRateDesc(offer) }
        </React.Fragment>
      )
    }
    if (offer.energy_type === 'electricity') {
      let price_class = {
        'peak_usage': 'peak_detail'
      }
      return (
        <React.Fragment>
        {
          offer.usage_rate.map((rate, index) => {
            let price_type = price_class[rate.name]
            return (
              <div className={`rate-${rate.name}-info`} key={index}>
                { this.stepRateDesc(index, offer.market_offer_id, rate, offer[price_type]) }
              </div>
            )
          })
        }
        </React.Fragment>
      )
    }
  }

  stepRateDesc = (key, data_id, single_rate, step_rates) => {
    let single_rate_flag = single_rate !== null &&  single_rate !== undefined
    let step_rates_flag = step_rates !== null &&  step_rates !== undefined
    return (
      <React.Fragment>
      {
        (single_rate_flag || step_rates_flag) ?
          <div className="item" key={key} data-id={`rate-${data_id}`}>
            <div className="item-title">
              <span> <span>&sdot;</span></span>
              <span>{single_rate.title}</span>
            </div>
            { step_rates_flag && (step_rates.length > 1) ?
              <React.Fragment>
                {
                  step_rates.map((rate, index) => {
                    return  <div className="item-content" key={index}>
                              { `${rate.title  + ' - ' +  Utility.formateNumber(this.state.isShowInclGst ? rate.price : rate.b4_tax_price) + 'c'}` }
                            </div>
                  })
                }
              </React.Fragment>
              :
              <div className="item-content">
                { `${Utility.formateNumber(this.state.isShowInclGst ? single_rate.price : single_rate.b4_tax_price )} ${single_rate.unit}` }
              </div>
            }
          </div>
        :
        ''
      }
      </React.Fragment>
    )
  }

  gasStepRateDesc = (offer) => {
    let data_id = offer.market_offer_id
    if (offer.usage_rate.length == 1){
      let item = offer.usage_rate[0]
      return (
        <React.Fragment>
          <div className={`rate-${item.type}-info`} key={item.type}>
            {
              item.details.map((rate, index) => {
                return (
                  <div className="item" key={index} data-id={`rate-${data_id}`}>
                    <div className="item-title">
                      <span> <span>&sdot;</span></span>
                      <span>{rate.title}</span>
                    </div>
                      <div className="item-content">
                        { `${Utility.formateNumber(this.state.isShowInclGst ? rate.price : rate.b4_tax_price )} ${rate.unit}` }
                      </div>
                  </div>
                )
              })
            }
          </div>
        </React.Fragment>
      )
    } else{
      return (
        <React.Fragment>
        {
          offer.usage_rate.map((item, key) => {
            return (
              <div className={`rate-${item.type}-info`} key={key}>
                <div className="item" key={key} data-id={`rate-${data_id}`}>
                  <div className="item-title">
                    <span> <span>&sdot;</span></span>
                    <span>{item.title}</span>
                  </div>
                  <React.Fragment>
                    {
                      item.details.map((rate, index) => {
                        return  <div className="item-content" key={index}>
                                  { `${rate.title  + ' - ' +  Utility.formateNumber(this.state.isShowInclGst ? rate.price : rate.b4_tax_price)} ${rate.unit}` }
                                </div>
                      })
                    }
                  </React.Fragment>
                </div>
              </div>
            )
          })
        }
        </React.Fragment>
      )
    }
  }

  dailySupplyDesc = (offer) => {
    return (
      <React.Fragment>
        {
          offer.daily_supply.map((rate, index) => {
            return  <div className="item" key={index + rate} data-id={`rate-${offer.market_offer_id}`}>
                      <div className="item-title">
                        <span> <span>&sdot;</span></span>
                        <span>{rate.title}</span>
                      </div>
                      <div className="item-content">
                        {
                          `${Utility.formateNumber(this.state.isShowInclGst ? rate.price : rate.b4_tax_price )} ${rate.unit}`
                        }
                      </div>
                    </div>
          })
        }
      </React.Fragment>
    )
  }

  demandChargeDesc = (offer) => {
    return (
      <React.Fragment>
        {
          (offer.demand_detail !== null &&  offer.demand_detail !==undefined) ? 
            offer.demand_detail.map((rate, index) => {
              return  <div className="item" key={index + rate} data-id={`rate-${offer.market_offer_id}`}>
                        <div className="item-title">
                          <span> <span>&sdot;</span></span>
                          <span>{rate.title}</span>
                        </div>
                        <div className="item-content">
                          {
                            `${Utility.formateNumber(this.state.isShowInclGst ? rate.price : rate.b4_tax_price )} ${rate.unit}`
                          }
                        </div>
                      </div>
            })
          : null
        }
      </React.Fragment>
    )
  }

  solarFitDesc = (offer) => {
    let solar_rate = offer.solar
    if(Utility.isBlank(solar_rate)){
      return ""
    }

    //single rate
    if(solar_rate.rate_type == 'single'){
      return(
        <React.Fragment>
          <Divider className="divider-line" dashed={false}/>
          <div className="solar-info">
            <div className="item" key="index">
              <div className="item-title">
                <span> <span>&sdot;</span></span>
                <span>{solar_rate.single.title}</span>
              </div>
              <div className="item-content">
                {
                  `${Utility.formateNumber(solar_rate.single.price)} ${solar_rate.single.unit}`
                }
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }

    //step rate
    if(solar_rate.rate_type == 'step'){
      return(
        <React.Fragment>
          <Divider className="divider-line" dashed={false}/>
          <div className="solar-info">
            <div className="item" key="index">
              <div className="item-title">
                <span> <span>&sdot;</span></span>
                <span>{solar_rate.header_title}</span>
              </div>
              {
                solar_rate.step.map((rate, index) => {
                  return  <div className="item-content" key={index}>
                            { `${rate.title  + ' - ' + Utility.formateNumber(rate.price) + 'c/kWh'}` }
                          </div>
                })
              }
            </div>
          </div>
        </React.Fragment>
      )
    }

    //tou rate
    if(solar_rate.rate_type == 'tou'){
      return(
        <React.Fragment>
          <Divider className="divider-line" dashed={false}/>
            <div className="solar-info" >
              {
                solar_rate.tou.map((rate, index) => {
                  return  <div className="item" key={index}>
                            <div className="item-title">
                              <span> <span>&sdot;</span></span>
                              <span>{rate.title}</span>
                            </div>
                            <div className="item-content">
                              {
                                `${Utility.formateNumber(rate.price)} ${rate.unit}`
                              }
                            </div>
                          </div>
                          
                })
              }
            </div>
        </React.Fragment>
      )
    }
  }

  basicInfoDesc = (index, offer, policy) => {
    let basic_plan = offer.eme_link || 'https://www.discoverenergy.com.au/basic_plans'
    return(
      <React.Fragment>
        {
          offer.product_offer.name.toUpperCase() === 'VIC VPP PREMIUM' && offer.state == 'VIC' ?
          <div className="basic-info">
            <a href={policy.vic_vpp_premium.file_url} target="_blank" className={`basic-color-${index+1}`}>
            Offer Terms and Eligibility
            </a>
            <span></span>
          </div> : ''
        }
        {
          offer.product_offer.name.toUpperCase() === 'VPP PREMIUM' ?
          <div className="basic-info">
            <a href={policy.vpp_premium.file_url} target="_blank" className={`basic-color-${index+1}`}>
            Plan Offer Term
            </a>
            <span></span>
          </div> : ''
        }
        {
          offer.product_offer.name.toUpperCase() === 'VPP PREMIUM X' ?
          <div className="basic-info">
            <a href={policy.vpp_premium_x.file_url} target="_blank" className={`basic-color-${index+1}`}>
            Plan Offer Term
            </a>
            <span></span>
          </div> : ''
        }
        {
          (/FREE ENERGY DAY/).test(offer.product_offer.name.toUpperCase()) ?
          <div className="basic-info">
            <a href='https://discover-energy-assets.s3-ap-southeast-2.amazonaws.com/assets/pdf/Electricity+Smart+Saver+_1+Free+Day+Energy+per+month+6months+Energy+Offer+T%26C.pdf' target="_blank" className={`basic-color-${index+1}`}>
            Energy Offer Terms
            </a>
            <span></span>
          </div> : ''
        }
        <div className="basic-info">
            <a href={offer.market_agreement_term_link} target="_blank" className={`basic-color-${index+1}`}>
            Terms and conditions
            </a>
            <span></span>
        </div>
        <div className="basic-info">
            <a href={basic_plan} target="_blank" className={`basic-color-${index+1}`}>
              { offer.state == 'VIC' ? 'Energy Price Factsheet' : 'Basic Plan Information' }
            </a>
            <span></span>
        </div>
      </React.Fragment>
    )
  }

  BottomInfoDesc = (offer) => {
    if(offer.energy_type === 'gas'){
      return(
        <React.Fragment>
          <div className='item'>
            <div className='item-content'>
             <p>The MJ Unit usage values shown above are daily consumption thresholds up to which the stated price applies. Unused amounts for each days consumption threshold are not carried forward.</p>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  estimatedCostInfoDesc = (offer) => {
    if(offer.energy_type === 'electricity'){
      return(
         <React.Fragment>
        {offer.show_estimated_cost == false ? this.reference_price_discount(offer) :
          <React.Fragment>
              <div>
                <div className="percent-info">
                  <div className="pricie-number">
                    <span style={{"font-size": '27px'}}>${Utility.formateMoney(new Number(offer.estimated_bill_amount))}</span>
                  </div>
                  <div class="pricie-symbol"><span class="pricie-status">　</span><span class="">/year</span></div>
                </div>
                <div>Estimated cost incl GST</div>
              </div>
            </React.Fragment>
        }
        <div>
          {
            offer.estimated_bill_amount !== undefined && offer.estimated_bill_amount !== '' && offer.show_estimated_cost == false ?
            <React.Fragment>
              <div >
                <span className="discount-info">
                  <span className="dis">${Utility.formateMoney(new Number(offer.estimated_bill_amount))}</span>
                  <span>/year</span>
                </span>
                <span>
                <Tooltip placement="topRight" className="plan-tip" title='The bill amount is based on the AER reference usage.'>
                  <Icon type="info-circle" theme="filled" />
                </Tooltip>
                </span>
              </div>
              <div>Estimated cost incl GST</div>
            </React.Fragment>
            : ''
          }
          {this.referencePriceDesc(offer)}
        </div>
        </React.Fragment>
      )
    }
    if(offer.energy_type === 'gas'){
      return(
      <React.Fragment>
        {offer.show_estimated_cost == false ? 
          <React.Fragment>
            { this.reference_price_discount(offer) }
            <div>
              {
                offer.estimated_bill_amount !== undefined && offer.estimated_bill_amount !== '' ?
                <React.Fragment>
                  <div >
                    <span className="discount-info">
                      <span className="dis">${Utility.formateMoney(new Number(offer.estimated_bill_amount))}</span>
                      <span>/year</span>
                    </span>
                    <span>
                    <Tooltip placement="topRight" className="plan-tip" title='The bill amount is based on the AER reference usage.'>
                      <Icon type="info-circle" theme="filled" />
                    </Tooltip>
                    </span>
                  </div>
                  <div>Estimated cost incl GST</div>
                </React.Fragment>
                : ''
              }
              {this.referencePriceDesc(offer)}
            </div>
          </React.Fragment>
        :
          <React.Fragment>
            <div>
              <div >
                <span className="discount-info">
                  <span className="dis">${Utility.formateMoney(new Number(offer.estimated_bill_amount))}</span>
                  <span>/year</span>
                </span>
                <span>
                <Tooltip placement="topRight" className="plan-tip" title={
                  offer.customer_type == 'residential' ?
                    <div>
                      <p>This estimated annual gas cost is calculated using AER's benchmark data in your distribution area. The estimated amount includes any eligible discounts and GST. It does not include concessions or one-off credits or other fees.  The estimate is based on variable rates which may change during your energy plan.</p>
                      <p>Your actual costs will vary where your usage differs from the amounts used in this calculation.</p>
                    </div>
                  : 
                    <div>
                      <p>This estimated annual gas cost is calculated using an average gas consumption amount based on Discover Energy's customer base in your distribution area. The estimated amount includes any eligible discounts and GST. It does not include rebates or one-off credits or other fees.  The estimate is based on variable rates which may change during your energy plan.</p>
                      <p>Your actual costs will vary where your usage differs from the amounts used in this calculation.</p>
                    </div>
                  }
                >
                  <Icon type="info-circle" theme="filled" />
                </Tooltip>
                </span>
              </div>
              <div>Estimated cost incl GST</div>
              {this.referencePriceDesc(offer)}
            </div>
          </React.Fragment>
        }
      </React.Fragment>
      )
    }
  }

  render () {
    const _planLsit = this.state.planList.map((offer,index ) => {
        let basic_plan = offer.eme_link || 'https://www.discoverenergy.com.au/basic_plans'
        let additionalDocs = ''
        let additionalDocsCss = ''
        const credit_point = this.props.nmi_info.credit_point
        if (offer.product_offer.need_credit){
          if ((offer.product_offer.credit_req === null) || (offer.product_offer.credit_req > credit_point)){
            // additionalDocs = 'Additional docs'
          } else if ((offer.product_offer.credit_req === null) || (offer.product_offer.credit_req < credit_point)){
            additionalDocs = 'Good to sign'
            additionalDocsCss = 'additional-docs-success'
          }
        }
        return <div className={`signup-plan-box box-shadow-${index+1}`} key={index+offer.product_offer.name}>
                <div className={`plan-head`}>
                  <div className={`additional-docs ${additionalDocsCss}`}>
                    {
                      'Additional docs' == additionalDocs ?
                        <Tooltip placement="topRight" title='Any one of these: Previous Bill, Lease Agreement, City Council Bill.'>
                          {additionalDocs}
                        </Tooltip>
                      : additionalDocs
                    }
                  </div>
                  <div className="title">{offer.product_offer.name}</div>
                  <div  className={`plan-box-line bg-${index+1}`}></div>
                  <div className="plan-describle">{offer.product_offer.description}</div>
                  <Divider dashed={false}/>
                  <div className={`price-info color-${index + 1}`}>
                    {this.estimatedCostInfoDesc(offer)}
                  </div>


                </div>
                <div className="plan-body">
                  <Divider dashed={false}/>

                  <div className="main-offer-info">
                      <div className="title">
                        <span>Main Offer</span>
                        <span
                          onClick={this.showMainInfo}
                          className={['down-animate', this.state.isMainOfferShow ? 'rotate-180' : ''].join(' ') }><Icon type="down" /></span>
                      </div>
                      <CSSTransitionGroup
                        className="mainOfferInfoItem"
                        transitionName="mainOfferInfoItem"
                        transitionEnterTimeout={1000}
                        transitionLeaveTimeout={300}
                      >

                      {
                        offer.product_features.map((feature, index) => {
                          return  <div className="item" key={index+feature.name} data-id={`${offer.market_offer_id}`}>
                                    <div className="item-title">
                                      <span> <span>&sdot;</span></span>
                                      <span>{feature.name}</span>
                                    </div>
                                    <div className="item-content">
                                      {feature.description}
                                    </div>
                                  </div>
                        })
                      }
                      </CSSTransitionGroup>

                  </div>

                  <Divider dashed={false}/>
                  <div className="view-rate-info">
                    <div className="title">
                      {
                        offer.energy_type == 'gas' ?
                        <p>Discounted Rates displayed</p>
                        :
                        ""
                      }
                      <div className="title-content">
                        <span className="title-content-info">View rates</span>
                        <span className="title-content-switch">
                          <span className="mr-10">Incl GST</span>
                          <Switch className=""
                            onChange={this.showInclGst}
                            checked={this.state.isShowInclGst}
                          />
                        </span>
                      </div>
                    </div>

                    <CSSTransitionGroup
                      className="rateInfoItem"
                      transitionName="rateInfoItem"
                      transitionEnterTimeout={1000}
                      transitionLeaveTimeout={300}
                    >
                      { this.usageRateDesc(offer) }
                      { this.dailySupplyDesc(offer) }
                      { this.demandChargeDesc(offer) }
                      { this.BottomInfoDesc(offer) }
                    </CSSTransitionGroup>
                  </div>
                  { this.solarFitDesc(offer) }
                </div>

                <div className="padding030">
                  <Divider dashed={false}/>
                </div>

                <div className="basic-info-content">
                  { this.basicInfoDesc(index, offer, this.props.policy) }
                </div>
                <div className="plan-foot">
                {
                  offer.market_offer_id === this.props.curPlanId ?
                    <button type="button" className={`de-btn de-btn-green margin-t-10 margin-b-10 de-btn-${index+1} `} >Current plan</button>
                  :
                  <button type="button" className={`de-btn de-btn-green margin-t-10 margin-b-10 de-btn-${index+1} `} onClick={()=>{this.selectplan(offer, index)}}>
                    {
                      this.state.market_offer_id === offer.market_offer_id ?
                         <img src="//s3-ap-southeast-2.amazonaws.com/discover-energy/assets/images/checked.png"/>
                      : 'Select plan'
                    }
                  </button>
                }
                </div>
              </div>
    })

    const nextBtnDisplay = this.nextClickDisplay()

    return (
        <div className="signup-plan-content">
          <CSSTransitionGroup
            className="planItem"
            transitionName="planItem"
            transitionEnterTimeout={1000}
            transitionLeaveTimeout={300}>
            {_planLsit}
          </CSSTransitionGroup>
          {
            this.state.isSelectPlan ?
            <div className="select-control">
              <div>
                <div className="elec-info" >
                </div>
              </div>
            </div>
              : ''
          }
          {nextBtnDisplay}
        </div>
    )
  }
}
export default SignupPlan
