import "./index.scss"
document.addEventListener("turbolinks:load", function() {
  if ($('body').hasClass("potential-partners-index")) {
    $(document).ready(function(){
      seFormValidate({
        'active_start_date': 'empty',
        'active_end_date': 'empty'
      })
      $("#active_state").trigger("change");
    })
    
    $('#active_state').change(function(){
      let value = $('#active_state').val()
      $(".ui.form").form('remove fields',[
        'active_start_date',
        'active_end_date'
      ])
      if(value == 'Active' || value == 'Inactive'){
        $(".active_state_date_field").show();
        $(".ui.form").form('add rule','active_start_date', 'empty').
                      form('add rule','active_end_date', 'empty')
      }else{
        $(".active_state_date_field").hide();
        $('#active_state').val("")
      }
    })
  }
});