import './main.scss'
import './cus_sidebar.scss'
import './cus_semantic.scss'
import './company_new.scss'
import './group_buy.scss'
import 'semantic-ui-css/semantic.css'
import 'jquery-fancybox/source/scss/jquery.fancybox.scss'
import './../../components/common/new_plan/plan.scss'
import 'antd/lib/divider/style/css'
import 'antd/lib/icon/style/css'
import 'antd/lib/message/style/css'
import 'antd/lib/spin/style/css'
import 'antd/lib/collapse/style/css'
import 'antd/lib/switch/style/css'
import 'antd/lib/tooltip/style/css'
import 'select2/dist/css/select2.css'
import $ from 'jquery'
import _ from 'underscore'
require('imports-loader?$=jquery,jQuery=jquery!jquery-fancybox/source/js/jquery.fancybox')
import 'jquery-ui/ui/widgets/datepicker'
import 'jquery-ui/themes/base/datepicker'
import 'jquery-ui/themes/base/theme'
require('imports-loader?$=jquery,jQuery=jquery!semantic-ui-css/semantic.js')


window.$ = $;
window._ = _;

document.addEventListener("turbolinks:load", function () {
  // pop tooltip
  $('.tooltip.icon').popup({
    inline: true
  });

  // init semantice ui base js
  $('.ui.dropdown:not([data-placeholder])').dropdown();
  $('.ui.dropdown[data-placeholder="false"]').dropdown({
    placeholder: false
  });

  $('.ui.checkbox').checkbox();
  $('.menu .item').tab();

  // $('.ui.sidebar').sidebar('toggle')
  $(".sidebar-click").on("click", function () {
    $('#tog-sidebar').sidebar('toggle');
  })

  // mesage
  $('.message .close')
    .on('click', function () {
      $(this)
        .closest('.message')
        .transition('fade');
    });

  // jquery datepicker
  $('.datepicker').datepicker({
    changeMonth: true,
    changeYear: true,
    dateFormat: "dd/mm/yy"
  });

  $("#myModal").on("click", ".offer-detail .ant-switch", 'click', function(){
    if($(this).hasClass('ant-switch-checked')){
      $(this).removeClass('ant-switch-checked');
      $(this).attr("aria-checked", "false");
      $(".rateInfoItem").find(".tax-price").show();
      $(".rateInfoItem").find(".price").hide();
    }else{
      $(this).addClass('ant-switch-checked');
      $(this).attr("aria-checked", "true");
      $(".rateInfoItem").find(".tax-price").hide();
      $(".rateInfoItem").find(".price").show();
    }
  });

  $("#myModal").on("click", ".offer-detail .down-animate", 'click', function(){
    if($(this).hasClass('rotate-180')){
      $(this).removeClass('rotate-180');
      $(".mainOfferInfoItem").find('.item-content').hide();
    }else{
      $(this).addClass('rotate-180');
      $(".mainOfferInfoItem").find('.item-content').show();
    }
  });
  
})

// form validate
window.seFormValidate =  function(validationRules) {
  $('.ui.form').form({
    fields: validationRules,
    inline: true,
    on: 'change',
    keyboardShortcuts: false
  });
}