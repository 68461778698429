import React from "react"
import { Form } from 'antd'
import 'antd/dist/antd.css'
import I18n from 'src/shared/i18n'
import Validate from '../../lib/validate'


export class ExistedCustomerQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.existed_customer_info.email,
      customer_id: this.props.existed_customer_info.customer_id,
      verify_code: '',
      verify_code_cache_id: '',
      count_down:{
        can_send_code: false,
        showtext: "Send Code",
        count: 60
      },
      can_submit: false,
      full_name: this.props.existed_customer_info.full_name
    }
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  };

  handleSubmit = () => {
    let _this = this
    this.props.form.validateFields((err, values) => {
      if (!err) {
         _this.setState({can_submit: false}, ()=>{
          $.ajax({
            url: '/plans/verification_customer',
            method: 'POST',
            data: {
              authenticity_token: $('[name="csrf-token"]')[0].content,
              id: _this.state.verify_code_cache_id,
              email: _this.state.email,
              verification_code: _this.state.verify_code
            },
            success: function(result){
              if(result.flag === true){
                _this.setState({email: result.email, customer_id: result.customer_id})
                _this.props.handleExistedCustomerChange({existed_customer_info: {
                    email: result.email,
                    customer_id:  result.customer_id,
                    birthday: result.birthday}})
              }else{
                alert(result.info)
                _this.setState({can_submit: true})
              }
            }
          });
        })
      }

    });
  };

  handleVerifyCustomer = () => {
    let _this = this
    this.props.form.validateFields((err, values) => {
      if (!err) {
         _this.setState({can_submit: false}, ()=>{
          $.ajax({
            url: '/plans/verification_customer',
            method: 'POST',
            data: {
              authenticity_token: $('[name="csrf-token"]')[0].content,
              id: _this.state.verify_code_cache_id,
              email: _this.state.email,
              verification_code: _this.state.verify_code
            },
            success: function(result){
              if(result.flag === true){
                _this.setState({email: result.email, c_id: result.customer_id, next_btn: false, submit: true, full_name: result.full_name})
              }else{
                alert(result.info)
                _this.setState({next_btn: true, submit: false})
              }
            }
          });
        })
      }
    });
  };

  handleNoEmailSubmit = () => {
    this.setState({submit: false, customer_id: this.state.c_id});
    this.props.handleExistedCustomerChange({existed_customer_info: {
      email: this.state.email,
      customer_id:  this.state.c_id,
      birthday: this.state.birthday,
      full_name: this.state.full_name
    }})
  }

  handleDeAccountEmailChange = (e) => {
    let cur_email = e.target.value.replace(/\s+/g,"")
    this.setState({next_btn: true, submit: false, full_name: ''})
    this.setState({email: cur_email}, ()=>{
      this.props.form.validateFields(['email'],(err, values) => {
        clearInterval(this.timer);
        if (!err) {
          this.setState({
            count_down:{
              can_send_code: true,
              showtext: "Send Code",
              count: 60
            }
          })
        }else{
          this.setState({
            count_down:{
              can_send_code: false,
              showtext: "Send Code",
              count: 60
            }
          })
        }
      });
    })
  };

  handleVerifyCodeChange = (e)=>{
    let verify_code = e.target.value.replace(/\s+/g,"")
    this.setState({verify_code: verify_code}, ()=>{
      this.props.form.validateFields(['email','verify_code'],(err, values) => {
        if (!err) {
          this.setState({can_submit: true})
        }else{
          this.setState({can_submit: false})
        }
      });
    })
  };

  handleSendCode = () => {
    let _this = this
    _this.setState({count_down: Object.assign(this.state.count_down, {can_send_code: false})}, ()=>{
      $.ajax({
        url: '/plans/get_de_customer_verification_code',
        method: 'POST',
        dataType:'json',
        data: {
          authenticity_token: $('[name="csrf-token"]')[0].content,
          email: this.state.email
        },
        success: function(data){
          if(data.flag === true){
            _this.setState({verify_code_cache_id: data.id})
            _this.startCountDown()
          }else{
            _this.setState({count_down: Object.assign(_this.state.count_down, {can_send_code: true})})
            alert(data.info);
          }
        }
      });
    })
  };

  startCountDown = () => {
    this.timer = setInterval(() => {
      if (this.state.count_down.count > 0) {
        this.setState({count_down:{
          showtext: "Resend",
          count: this.state.count_down.count - 1,
          can_send_code: false
        }})
      } else {
        clearInterval(this.timer);
        this.setState({count_down:{
          count: 60,
          showtext: "Resend",
          can_send_code: true
        }})      }
    }, 1000);
  };

  changeEnergyType = (type) => {
    if(this.props.energy_type !== type){
      let init_search_type = 'nmi'
      let init_customer_type = ''
      if(type == 'gas'){
        init_search_type = 'mirn'
        init_customer_type = 'residential'
      }
      this.props.handleEnergyTypeChange({
        energy_type: type,
        customer_type: init_customer_type,
        plan_search_type: init_search_type,
        search_type_info: {
          full_nmi: '',
          connected_full_address: '',
          nmi: '',
          checksum: '',
          mirn: ''
        }
      })
    }
  }

  changeEstimatedMonthlyBill = (value) => {
    this.props.handleChange({
      estimated_monthly_bill: value
    })
  }

  selectActive = (condition) => {
    if(condition) {
      return "de-select-active"
    } else {
      return ""
    }
  }

  fullName = () => {
    if ( this.state.full_name !== null && typeof(this.state.full_name) !== "undefined"  && this.state.full_name.length > 0){
      return (
        <div className="ui grid no-margin">
          <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
            <div className="ch-left-title ch-input-title">Full Name</div>
          </div>
          <div className="seven wide computer seven wide tablet sixteen wide mobile column no-padding-bt">
            <div className="ch-left-title ch-title">{this.state.full_name}</div>
          </div>
        </div>
      )
    }
    if (typeof(this.state.c_id) !== "undefined" && this.state.customer_id === "" && this.state.full_name === null){
      return (
        <div className="ui grid no-margin">
          <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
          </div>
          <div className="eight wide computer eight wide tablet sixteen wide mobile column no-padding-bt">
            <div className="vpp-association-hint no-margin">
              <p>
                There is no associated address under your account. Click Submit to continue inquiring the quotation.
              </p>
            </div>
          </div>
        </div>
      )
    }
  };

  existedCustomer = () => {
    let errors;
    let selectBoxStyle = 'de-select'
    let selectBoxActiveStyle = 'de-select de-select-active'
    const { getFieldProps, getFieldError } = this.props.form;
    const count_down = this.state.count_down;
    const disabled_changed = this.state.customer_id !== '';
    if (disabled_changed == true){
      return (
        <React.Fragment>
          <div className="ui grid no-margin">
            <div className="four wide computer six wide tablet sixteen wide mobile column">
              <div className="ch-left-title ch-input-title">Your email address</div>
            </div>
            <div className="six wide computer six wide tablet sixteen wide mobile column">
              <div className="field">
                <input type="text" disabled value={this.state.email} />
              </div>
            </div>
          </div>
          {this.fullName()}

          <div className="ui grid no-margin">
            <div className="four wide computer six wide tablet sixteen wide mobile column">
              <div className="ch-left-title">Energy type</div>
            </div>
            <div className="twelve wide computer ten wide tablet sixteen wide mobile column">
              <div className={`de-select ${this.selectActive(this.props.energy_type == 'electricity')}`} onClick={() => this.changeEnergyType('electricity')}>
                <span className="block">Electricity</span>
              </div>
              <div className={`de-select ${this.selectActive(this.props.energy_type == 'gas')}`} onClick={() => this.changeEnergyType('gas')}>
                <span className="block">Gas</span>
              </div>
            </div>
          </div>
          {
            this.props.energy_type == 'electricity' ?
              <div className="ui grid no-margin">
                <div className="four wide computer six wide tablet sixteen wide mobile column">
                  <div className="ch-left-title">Estimated monthly bill</div>
                </div>
                <div className="twelve wide computer ten wide tablet sixteen wide mobile column">
                  <div className={['', this.props.estimated_monthly_bill == 'less_1000' ? selectBoxActiveStyle : selectBoxStyle].join(' ')} onClick={() => this.changeEstimatedMonthlyBill('less_1000')}>
                    <span className="block">Less than $1,000</span>
                  </div>
                  <div className={['', this.props.estimated_monthly_bill == 'greater_1000' ? selectBoxActiveStyle : selectBoxStyle].join(' ')} onClick={() => this.changeEstimatedMonthlyBill('greater_1000')}>
                    <span className="block">Greater than $1,000</span>
                  </div>
                </div>
              </div>
            : ''
          }
        </React.Fragment>
      )
    } else if (!this.props.valid_email){
      return (
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
          <div className="ui grid no-margin">
            <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
              <div className="ch-left-title ch-input-title">Your email address</div>
            </div>
            <div className="seven wide computer seven wide tablet sixteen wide mobile column no-padding-bt">
              <div className="field os-input">
                <input
                  id="email"
                  type="text"
                  placeholder="Please enter you Discover Energy registration email address."
                  className={['form-control' , getFieldError('email') ? 'validate-error-input' : ''].join(' ')}
                  {...getFieldProps('email', {
                  initialValue: this.state.email,
                  onChange: this.handleDeAccountEmailChange,
                  rules: [
                    {validator: Validate.validateEmpty, message: I18n.t("required")},
                    {validator: Validate.validateEmail, message: 'this email is invalid'}
                  ],
                  getValueFromEvent: (event) => {
                    return  event.target.value.replace(/\s+/g,"")
                  }
                })}/>
                <div className="validate-error-info">
                  {(errors = getFieldError('email')) ? errors.join(',') : null}
                </div>
              </div>
            </div>
          </div>
          {this.fullName()}

          <div id="customer-verification-area">
            <div className="ui grid no-margin">
              <div className="four wide computer six wide tablet sixteen wide mobile column">
              </div>
              <div className="eight wide computer eight wide tablet sixteen wide mobile column">
                {this.state.next_btn !== false ? <button type="button" className="ui green button" onClick={() => this.handleVerifyCustomer()}>Next</button> : ""}
                {this.state.submit == true ? <button id="association_existed_btn" type="button" className="ui green button" onClick={()=>this.handleNoEmailSubmit()}>Submit</button> : ""}
              </div>
            </div>
          </div>
        </Form>
      )
    }else {
      return (
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
          <div className="ui grid no-margin">
            <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
              <div className="ch-left-title ch-input-title">Your email address</div>
            </div>
            <div className="seven wide computer seven wide tablet sixteen wide mobile column no-padding-bt">
              <div className="field os-input">
                <input
                  id="email"
                  type="text"
                  placeholder="Please enter you Discover Energy registration email address."
                  className={['form-control' , getFieldError('email') ? 'validate-error-input' : ''].join(' ')}
                  {...getFieldProps('email', {
                  initialValue: this.state.email,
                  onChange: this.handleDeAccountEmailChange,
                  rules: [
                    {validator: Validate.validateEmpty, message: I18n.t("required")},
                    {validator: Validate.validateEmail, message: 'this email is invalid'}
                  ],
                  getValueFromEvent: (event) => {
                    return  event.target.value.replace(/\s+/g,"")
                  }
                })}/>
                <div className="validate-error-info">
                  {(errors = getFieldError('email')) ? errors.join(',') : null}
                </div>
              </div>
            </div>
          </div>

          <div id="customer-verification-area">
            <input type="hidden" name="de-customer-verification-code-id" id="de_customer_verification_code_id" value="" />

            <div className="ui grid no-margin">
              <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                <div className="ch-left-title ch-input-title">Verification Code</div>
              </div>
              <div className="four wide computer four wide tablet sixteen wide mobile column no-padding-bt">
                <div className="field os-input">
                  <input
                    id="verify_code"
                    type="text"
                    placeholder="Please enter verification code"
                    className={['form-control' , getFieldError('verify_code') ? 'validate-error-input' : ''].join(' ')}
                    {...getFieldProps('verify_code', {
                    initialValue: this.state.verify_code,
                    onChange: this.handleVerifyCodeChange,
                    rules: [
                      {validator: Validate.validateEmpty, message: I18n.t("required")},
                      {len: 6, message: "must be exactly 6 characters"}
                    ],
                    getValueFromEvent: (event) => {
                      return  event.target.value.replace(/\s+/g,"")
                    }
                  })}/>
                  <div className="validate-error-info">
                    {(errors = getFieldError('verify_code')) ? errors.join(',') : null}
                  </div>
                </div>
              </div>
              <div className="three wide computer three wide tablet sixteen wide mobile column no-padding-top ch-align-right">
                <button type="button" className={count_down.can_send_code === true ? "ui green button" : "ui gray button"} onClick={() => this.handleSendCode()} disabled={!count_down.can_send_code}>
                  {count_down.showtext}
                  {
                    (count_down.count > 0 && count_down.count < 60) ? "("+count_down.count+")" : ""
                  }
                </button>
              </div>
            </div>

            <div className="ui grid no-margin">
              <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
              </div>
              <div className="eight wide computer eight wide tablet sixteen wide mobile column no-padding-bt">
                <div className="vpp-association-hint no-margin">
                  <p>
                    Please check the verification code details in the email address.
                  </p>
                </div>
              </div>
            </div>

            <div className="ui grid no-margin">
              <div className="four wide computer six wide tablet sixteen wide mobile column">
              </div>
              <div className="eight wide computer eight wide tablet sixteen wide mobile column">
                <button id="association_existed_btn" type="button" className={this.state.can_submit ? "ui green button" : "ui gray button"} disabled={!this.state.can_submit} onClick={()=>this.handleSubmit()}>Submit</button>
              </div>
            </div>
          </div>
        </Form>
      )
    }
  };

  render (){
    const content = this.existedCustomer();
    return (content);
  }
}

export default Form.create()(ExistedCustomerQuestion)