import React from "react";
import 'antd/dist/antd.css';
import { Select } from 'antd';



export class NewCustomerQuestion extends React.Component {
  // props: { handleNewCustomerInfoChange(new_customer_info), new_customer_info: {} } 
  constructor(props) {
    super(props);
    // this.props.new_customer_info = {
    //   birthday: ''
    // }
  }

  componentDidMount() {
    // init semantice ui base js
    $('.ui.dropdown:not([data-placeholder])').dropdown();
    $('.ui.dropdown[data-placeholder="false"]').dropdown({
      placeholder: false
    });
  }

  onCustomerInfoChange = (item) => {
    let value  = {...this.props.new_customer_info, ...item}
    this.props.handleNewCustomerInfoChange({new_customer_info: value})
  }

  changeEnergyType = (type) => {
    if(this.props.energy_type !== type){
      let init_search_type = 'nmi'
      let init_customer_type = ''
      if(type == 'gas'){
        init_search_type = 'mirn'
        init_customer_type = 'residential'
      }
      this.props.handleEnergyTypeChange({
        energy_type: type,
        customer_type: init_customer_type,
        plan_search_type: init_search_type,
        search_type_info: {
          full_nmi: '',
          connected_full_address: '',
          nmi: '',
          checksum: '',
          mirn: ''
        }
      })
    }
  }

  changeEstimatedMonthlyBill = (value) => {
    this.props.handleChange({
      estimated_monthly_bill: value
    })
  }

  selectActive = (condition) => {
    if(condition) {
      return "de-select-active"
    } else {
      return ""
    }
  }

  render () {
    let selectBoxStyle = 'de-select'
    let selectBoxActiveStyle = 'de-select de-select-active'
    return (
      <div className='ui form'>
        <div className="ui grid no-margin">
          <div className="four wide computer six wide tablet sixteen wide mobile column">
            <div className="ch-left-title">Year of birth</div>
          </div>
          <div className="four wide computer four wide tablet sixteen wide mobile column">
            <div className="field">
              <select id="year-of-date" name="birthday" onChange={(e) => this.onCustomerInfoChange({birthday: e.target.value})} value={this.props.new_customer_info.birthday} className="ui fluid search dropdown">
                <option value="">Year of birth</option>
                {_.range(1900, 2003).reverse().map((team) =>
                  <option key={team} value={team}>{team}</option>
                )}
              </select>
            </div>
          </div>
        </div>

        <div className="ui grid no-margin">
          <div className="four wide computer six wide tablet sixteen wide mobile column">
            <div className="ch-left-title">Energy type</div>
          </div>
          <div className="twelve wide computer ten wide tablet sixteen wide mobile column">
            <div className={`de-select ${this.selectActive(this.props.energy_type == 'electricity')}`} onClick={() => this.changeEnergyType('electricity')}>
              <span className="block">Electricity</span>
            </div>
            <div className={`de-select ${this.selectActive(this.props.energy_type == 'gas')}`} onClick={() => this.changeEnergyType('gas')}>
              <span className="block">Gas</span>
            </div>
          </div>
        </div>
        {
          this.props.energy_type == 'electricity' ?
            <div className="ui grid no-margin">
              <div className="four wide computer six wide tablet sixteen wide mobile column">
                <div className="ch-left-title">Estimated monthly bill</div>
              </div>
              <div className="twelve wide computer ten wide tablet sixteen wide mobile column">
                <div className={['', this.props.estimated_monthly_bill == 'less_1000' ? selectBoxActiveStyle : selectBoxStyle].join(' ')} onClick={() => this.changeEstimatedMonthlyBill('less_1000')}>
                  <span className="block">Less than $1,000</span>
                </div>
                <div className={['', this.props.estimated_monthly_bill == 'greater_1000' ? selectBoxActiveStyle : selectBoxStyle].join(' ')} onClick={() => this.changeEstimatedMonthlyBill('greater_1000')}>
                  <span className="block">Greater than $1,000</span>
                </div>
              </div>
            </div>
          : ''
        }

      </div>
    )
  }
}

export default NewCustomerQuestion