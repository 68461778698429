import React from "react"
import './index.scss'
import I18n from "src/shared/i18n"

class SelectBox extends React.Component {
  constructor(props){
    super(props)
    this.options = this.props.options || []
    this.state = {
    }
  }
  render() {
    let selectBoxStyle = 'de-select'
    let selectBoxActiveStyle = 'de-select de-select-active'
    let tree = this.options.map((item, index) => {
      if(item.href){
        return (
          <a
            href={item.href}
            key={index} 
            className={this.props.value === item.value ? selectBoxActiveStyle : selectBoxStyle} >
            <span className="block">{I18n.t(item.text)}</span>
          </a>
        )
      }
      return (
        <div 
          key={index} 
          className={[this.props.className, this.props.value === item.value ? selectBoxActiveStyle : selectBoxStyle].join(' ')} 
          onClick={() => {this.props.onClick(item)}} >
          <span className="block">{I18n.t(item.text)}</span>
        </div>
      )
    })
    return tree
  }
}
export default SelectBox