document.addEventListener("turbolinks:load", function () {
  // $('#channel_commission_cashback_type').change(function(){
  $(document).on('change', '#channel_commission_cashback_type', function(){
    seFormValidate({
      'channel_commission[cashback_type]': 'empty',
      'channel_commission[engage_invoice_id]': 'empty',
      'channel_commission[usage_amount]': 'empty',
      'channel_commission[commission_amount]': 'empty',
      'channel_commission[commission_date]': 'empty'
    })
    if($('#new_record').val() == 'true'){
      $("#first_change").val('false');
    }
    var cashback_type = $(this).val();
    var first_change_value = $("#first_change").val();

    // control display
    $("#vpp-and-usage-area").addClass("hidden");
    $(".ui.form").form('remove fields',[
        'channel_commission[usage_amount]',
        'channel_commission[engage_invoice_id]'
      ])
    if (cashback_type === '3' || cashback_type === '4') {
      $("#vpp-and-usage-area").removeClass("hidden");
      $(".ui.form")
        .form('add rule','channel_commission[usage_amount]', 'empty')
        .form('add rule','channel_commission[engage_invoice_id]', 'empty')
    }

    // control value
    if (first_change_value === "false"){
      switch (cashback_type) {
        case '1':
          //account_switched
          $('#channel_commission_commission_amount').val($('#default_account_switched_amount').val());
          break;
        case '2':
          //first_bill
          $('#channel_commission_commission_amount').val($('#default_first_bill_amount').val());
          break;
        case '3':
          //vpp
          $('#channel_commission_engage_invoice_id').val('');
          $('#channel_commission_usage_amount').val('');
          $('#channel_commission_commission_amount').val('');
          break;
        case '4':
          //electricity_bill
          $('#channel_commission_engage_invoice_id').val('');
          $('#channel_commission_usage_amount').val('');
          $('#channel_commission_commission_amount').val('');
          break;
        case '5':
          //promotion
          $('#channel_commission_commission_amount').val('');
          break;
        case '6':
          //second_bill
          $('#channel_commission_commission_amount').val($('#default_second_bill_amount').val());
          break;
        case '7':
          //third_bill
          $('#channel_commission_commission_amount').val($('#default_third_bill_amount').val());
          break;
        case '8':
          //fourth_bill
          $('#channel_commission_commission_amount').val($('#default_fourth_bill_amount').val());
          break;
        case '9':
          //half_a_year
          $('#channel_commission_commission_amount').val($('#default_half_a_year_amount').val());
          break;
        default:
          console.log('unknown cashback type!')
      }
    }
    // update firsrt_change to reset input
    $("#first_change").val("false");
  })


  $(document).on('change', '#channel_commission_usage_amount', function(){
    var usage_amount = $(this).val()
    var cal_commission =  get_usage_commission(usage_amount)
    $('#channel_commission_commission_amount').val(cal_commission)
  })

  function get_usage_commission(usage_amount){
    cur_percent = 0
    if (0 < usage_amount && usage_amount <= 500){
      cur_percent = 0.01
    }else if(500 < usage_amount && usage_amount <= 600){
      cur_percent = 0.009
    }else if(600 < usage_amount && usage_amount <= 700){
      cur_percent = 0.008
    }else if(700 < usage_amount && usage_amount <= 800){
      cur_percent = 0.007
    }else if(800 < usage_amount && usage_amount <= 900){
      cur_percent = 0.006
    }else if(900 < usage_amount && usage_amount <= 1000){
      cur_percent = 0.005
    }else if(1000 < usage_amount && usage_amount <= 2000){
      cur_percent = 0.0045
    }else if(2000 < usage_amount){
      cur_percent = 0.004
    }
    return (usage_amount * cur_percent).toFixed(4)
  }
});