import React from "react"
import PropTypes from 'prop-types'
import { Form, Select, Input, Button } from 'antd';
import Validate from '../../lib/validate'
import I18n from 'src/shared/i18n'


export class ExistedVppAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      verify_code: '',
      count_down:{
        can_send_code: false,
        showtext: "Send Code",
        count: 60
      },
      can_submit: false,
      sn_selected: false,
      sn_list: [],
      sn: ''
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleSubmit = () => {
    let _this = this
    this.props.form.validateFields((err, values) => {
      if (!err) {
        _this.setState({can_submit: false}, ()=>{
          $.ajax({
            url: '/plans/association_vpp_with_email',
            method: 'POST',
            data: {
              authenticity_token: $('[name="csrf-token"]')[0].content,
              email: _this.state.email,
              verify_code: _this.state.verify_code,
              sn: this.state.sn
            },
            success: function(result){
              if(result.code === 200){
                _this.props.handleExistedVppAccountChange({
                  email: result.data.email,
                  sn: result.data.sn,
                  type: result.data.type,
                  check_code: result.data.check_code,
                  deinsight_id: result.data.deinsight_id,
                  user_email: result.data.user_email,
                  disabled_change: true 
                })
              }else{
                alert(result.msg)
                _this.setState({can_submit: true})
              }
            }
          });
        })
      }
    });
  };

  handleAccountEmailChange = (e) => {
    let account_email = e.target.value.replace(/\s+/g,"")
    let _this = this
    this.setState({email: account_email}, ()=>{
      this.props.form.validateFields(['email'],(err, values) => {
        clearInterval(this.timer);
        if (!err) {
          if(this.state.email.toLowerCase() === this.props.de_customer_email.toLowerCase()){
            $.ajax({
              url: '/plans/check_de_customer_is_vpp_customer',
              method: 'POST',
              data: {
                authenticity_token: $('[name="csrf-token"]')[0].content,
                email: this.state.email
              },
              success: function(result){
                if(result.code === 200){
                  
                }else{
                  alert(result.msg)
                  _this.setState({can_submit: true})
                }
              }
            });
          }
          this.setState({
            count_down:{
              can_send_code: true,
              showtext: "Send Code",
              count: 60
            }
          })
        }else{
          this.setState({
            count_down:{
              can_send_code: false,
              showtext: "Send Code",
              count: 60
            }
          })
        }
      });
    })
  }

  handleVerifyCodeChange = (e)=>{
    let verify_code = e.target.value.replace(/\s+/g,"")
    this.setState({verify_code: verify_code}, ()=>{
      this.props.form.validateFields(['email','verify_code'],(err, values) => {
        if (!err) {
          this.setState({can_submit: true})
        }else{
          this.setState({can_submit: false})
        }
      });
    })
  }

  handleSendCode = () => {
    let _this = this
    _this.setState({count_down: Object.assign(this.state.count_down, {can_send_code: false})}, ()=>{
      $.ajax({
        url: '/plans/send_verify_code',
        method: 'POST',
        dataType:'json',
        data: {
          authenticity_token: $('[name="csrf-token"]')[0].content,
          email: this.state.email
        },
        success: function(data){
          if(data.code == 200){
            if(data.data.sn.length > 1){
              _this.setState({sn_selected: true, sn_list: data.data.sn})
            }else{
              _this.setState({sn_selected: false, sn_list: [], sn: ''})
            }
            _this.startCountDown()
          }else{
            _this.setState({count_down: Object.assign(_this.state.count_down, {can_send_code: true})})
            alert(data.msg);
          }
        }
      });

    })
  }

  startCountDown = () => {
    this.timer = setInterval(() => {
      if (this.state.count_down.count > 0) {
        this.setState({count_down:{
          showtext: "Resend",
          count: this.state.count_down.count - 1,
          can_send_code: false
        }})
      } else {
        clearInterval(this.timer);
        this.setState({count_down:{
          count: 60,
          showtext: "Resend",
          can_send_code: true
        }})      }
    }, 1000);
  };

  onSnChange = (args) => {
    this.setState({sn: args.sn})
  };

  render () {
    let errors;
    const { getFieldProps, getFieldError } = this.props.form;
    const count_down = this.state.count_down
    return (
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
        <div className="ui grid no-margin">
          <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
            <div className="ch-left-title">DE Insight account email</div>
          </div>
          <div className="seven wide computer seven wide tablet sixteen wide mobile column no-padding-bt">
            <div className="field os-input">
              <input
                id="email"
                // maxLength="2"
                type="text"
                // data-type="number"
                placeholder="Please enter you DE Insight registration email address."
                className={['form-control' , getFieldError('email') ? 'validate-error-input' : ''].join(' ')}
                {...getFieldProps('email', {
                initialValue: this.state.email,
                onChange: this.handleAccountEmailChange,
                rules: [
                  {validator: Validate.validateEmpty, message: I18n.t("required")},
                  {validator: Validate.validateEmail, message: 'this email is invalid'}
                ],
                getValueFromEvent: (event) => {
                  return  event.target.value.replace(/\s+/g,"")
                }
              })}/>
              <div className="validate-error-info">
                {(errors = getFieldError('email')) ? errors.join(',') : null}
              </div>
            </div>
          </div>
        </div>

        <div className="ui grid no-margin">
          <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
            <div className="ch-left-title">Verification Code</div>
          </div>
          <div className="four wide computer four wide tablet sixteen wide mobile column no-padding-bt">
            <div className="field os-input">
              <input
                id="verify_code"
                // maxLength="2"
                type="text"
                // data-type="number"
                placeholder="Please enter verification code"
                className={['form-control' , getFieldError('verify_code') ? 'validate-error-input' : ''].join(' ')}
                {...getFieldProps('verify_code', {
                initialValue: this.state.verify_code,
                onChange: this.handleVerifyCodeChange,
                rules: [
                  {validator: Validate.validateEmpty, message: I18n.t("required")},
                  {len: 6, message: "must be exactly 6 characters"}
                ],
                getValueFromEvent: (event) => {
                  return  event.target.value.replace(/\s+/g,"")
                }
              })}/>
              <div className="validate-error-info">
                {(errors = getFieldError('verify_code')) ? errors.join(',') : null}
              </div>
            </div>
          </div>
          <div className="three wide computer three wide tablet sixteen wide mobile column no-padding-top ch-align-right">
            <button type="button" className={count_down.can_send_code === true ? "ui green button" : "ui gray button"} onClick={() => this.handleSendCode()} disabled={!count_down.can_send_code}>
              {count_down.showtext}
              {
                (count_down.count > 0 && count_down.count < 60) ? "("+count_down.count+")" : ""
              }
            </button>
          </div>
        </div>

        {
          this.state.sn_selected ?
            <div className="ui grid no-margin p-b-15">
              <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                <div className="ch-left-title">SN</div>
              </div>
              <div className="seven wide computer seven wide tablet sixteen wide mobile column no-padding-bt">
                <div className="field os-input">

                  <select id="sn" name="sn" onChange={(e) => this.onSnChange({sn: e.target.value})} value={this.state.sn} className="ui fluid search dropdown">
                    <option value="">Select SN</option>
                    {this.state.sn_list.map((sn) =>
                      <option key={sn} value={sn}>{sn}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          :
          null
        } 

        <div className="ui grid no-margin">
          <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
          </div>
          <div className="eight wide computer eight wide tablet sixteen wide mobile column no-padding-bt">
            <div className="vpp-association-hint no-margin">
              <p>
                Please check the verification code details in the email address.
              </p>
            </div>
          </div>
        </div>
        <div className="ui grid no-margin">
          <div className="four wide computer six wide tablet sixteen wide mobile column">
          </div>
          <div className="eight wide computer eight wide tablet sixteen wide mobile column">
            <button id="association_existed_btn" type="button" className={this.state.can_submit ? "ui green button" : "ui gray button"} disabled={!this.state.can_submit} onClick={()=>this.handleSubmit()}>Submit</button>
          </div>
        </div>
      </Form>
    );
  }
}

export default Form.create({ name: 'existed_vpp_account' })(ExistedVppAccount);