// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "src/shared/main.js"
import "src/shared/custom.js"
import "src/shared/common.js"
import "src/plan.js"
import "src/channel_promotions/commissions.js"
import "src/channel_commissions/index.js"
import "src/quote_histories/index.js"
import "src/channel_companies/index.js"
import "src/channel_companies/show.js"
import "src/channel_companies/settings.js"
import "src/potential_partners/index.js"
import "src/group_buy.js"
import "src/share_qrcode.js"
import "select2"

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
