import React from "react"
import PropTypes, { number } from "prop-types"

import '../select-box/index.scss'
// import { createForm, formShape } from 'rc-form'
import { Form } from 'antd'

import I18n from 'src/shared/i18n'

import AddressBox from '../address-finder/index'
import Modal from 'antd/lib/modal'
import 'antd/lib/modal/style/css'
import Button from 'antd/lib/button'
import 'antd/lib/button/style/css'

import Message from 'antd/lib/message'
import 'antd/lib/message/style/css'

import Validate from '../../lib/validate'
import emitter from '../../lib/events';

export class SearchType extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isValidAddress: true,
      isManualAddress: false,
      nmi_list: [],
      nmiListVisible: false,
      search_nmi: '',
      search_checksum: '',
      mirn_list: [],
      mirnListVisible: false
    }

    this.noPlans = {
      data: { planList: [], nmi_info: {} },
      result: false
    }
  }

  isSaHuaweiVPP = () => {
    let vpp_customer_info = this.props.vpp_customer_info
    if(vpp_customer_info.vpp_customer === 'yes' && vpp_customer_info.install_solar === 'yes' && vpp_customer_info.type === 'HuaWeiSa'){
      return true
    } else {
      return false
    }
  }


  changeBirthdayFunc = () => {
    if(this.props.plan_search_type === 'nmi'){
      this.searchPlanByNmi()
    }else{
      let checksum, is_vpp, nmi, birthday;
      nmi = this.props.search_type_info.nmi;
      checksum = this.props.search_type_info.checksum;
      is_vpp = this.props.is_vpp;
      birthday = this.props.birthday;
      if(nmi && checksum){
        this.getPlans(nmi, checksum, is_vpp, birthday );
      }
    }
  }

  resetSearchType = () => {
    let item = {}
    if(this.props.plan_search_type === 'nmi'){
      item = { full_nmi: '' }
    }else if(this.props.plan_search_type === 'mirn'){
      item = { full_mirn: ''}
    }else if(this.props.plan_search_type === 'address'){
      item = { connected_full_address: '' }
    }
    this.props.form.setFieldsValue(item)
  }

  componentDidMount() {
    this.eventEmitter = emitter.addListener('changeBirthday', this.changeBirthdayFunc);
    this.eventEmitter = emitter.addListener('resetSearchTypeForm', this.resetSearchType);
  }
  componentWillUnmount() {
    emitter.removeListener("changeBirthday", this.changeBirthdayFunc);
    emitter.removeListener("resetSearchTypeForm", this.resetSearchType);
  }

  changeType(type){
    if(this.props.plan_search_type !== type){
      this.props.handlePlanSearchTypeChange({
        plan_search_type: type,
        search_type_info: {full_nmi: '', connected_full_address: '', nmi: '', checksum: '', full_mirn: '', mirn: ''}
      });
    }
  }

  handleCustomerTypeChange = (customerType) => {
    this.props.updateState({
      customer_type: customerType
    })
    if(this.props.energy_type == 'gas' &&
    this.props.search_type_info.mirn &&
    this.props.search_type_info.checksum
    ) {
      this.getGasPlans(this.props.search_type_info.mirn, this.props.search_type_info.checksum, this.props.birthday)
    }
  }

  onNmiChange = (e) => {
    let full_nmi = e.target.value.replace(/\s+/g,"")
    let search_type_info = this.props.search_type_info;
    search_type_info.full_nmi = full_nmi
    search_type_info.nmi =  full_nmi.substr(0, full_nmi.length - 1);
    search_type_info.checksum = full_nmi.substr(-1);
    this.props.handleSearchTypeInfoChange({search_type_info: search_type_info})
  }

  onMirnChange = (e) => {
    let full_mirn = e.target.value.replace(/\s+/g, "")
    let search_type_info = this.props.search_type_info;
    search_type_info.full_mirn = full_mirn
    search_type_info.mirn = full_mirn.substr(0, full_mirn.length - 1);
    search_type_info.checksum = full_mirn.substr(-1);
    this.props.handleSearchTypeInfoChange({search_type_info: search_type_info})
  }

  getPlans = (nmi, checksum, is_vpp, birthday, search_history_id) => {
    var _this = this;
    // check nmi can sign up
    $.ajax({
      url: '/plans/nmi_can_signup',
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        nmi: nmi + checksum
      },
      type: 'GET',
      dataType: 'JSON',
      timeout: 10000,
      success: (data) => {
        if(data.result == true){
          // before get plan, set localStorage comparePlanOffer to null
          // localStorage.removeItem("comparePlanOffer")
          const channel_company = $("#channel_company").val()
          if (search_history_id == null) {
            search_history_id = '';
          }
          birthday = birthday ? birthday : _this.props.birthday
          return $.ajax({
            url: "/plans/search",
            method: "post",
            type: "json",
            data: {
              authenticity_token: $('[name="csrf-token"]')[0].content,
              nmi: nmi,
              checksum: checksum,
              is_vpp: is_vpp,
              search_history_id: search_history_id,
              channel_company: channel_company,
              birthday: birthday
            },
            success: (data) => {
              if(data.result){
                if(data.data.nmi_info.state != 'SA' && _this.isSaHuaweiVPP()){
                  Message.warning("Please enter the state address where the device is located.", 3.5)
                  return;
                }
                _this.props.handlePlanChange({plans: data})
              }else{
                Message.warning(data.msg, 3.5)
                _this.props.handlePlanChange({plans: this.noPlans})
              }
            }
          })
        }else{
          Message.warning(data.msg, 3.5)
          _this.props.handlePlanChange({plans: this.noPlans})
        }
      },
      error: (xhr, status, error) => {
        Message.warning(error === 'timeout' ? 'The request timed out, please try again later !' : 'Please try again later !', 3.5)
        _this.props.handlePlanChange({plans: this.noPlans})
      }
    })
  }

  getGasPlans = (mirn, checksum, birthday, search_history_id) => {
    var _this = this;
    // check mirn can sign up
    $.ajax({
      url: '/plans/mirn_can_signup',
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        mirn: mirn + checksum
      },
      type: 'GET',
      dataType: 'JSON',
      timeout: 10000,
      success: (data) => {
        if(data.result == true){
          // before get plan, set localStorage comparePlanOffer to null
          // localStorage.removeItem("comparePlanOffer")

          const channel_company = $("#channel_company").val()
          if (search_history_id == null) {
            search_history_id = '';
          }
          birthday = birthday ? birthday : _this.props.birthday
          return $.ajax({
            url: "/plans/gas_search",
            method: "post",
            type: "json",
            data: {
              authenticity_token: $('[name="csrf-token"]')[0].content,
              mirn: mirn,
              checksum: checksum,
              customer_type: _this.props.customer_type.toUpperCase(),
              // is_vpp: is_vpp,
              search_history_id: search_history_id,
              channel_company: channel_company,
              birthday: birthday
            },
            success: (data) => {
              if(data.result){
                _this.props.handlePlanChange({plans: data})
              }else{
                Message.warning(data.msg, 3.5)
                _this.props.handlePlanChange({plans: this.noPlans})
              }
            }
          })
        }else{
          Message.warning(data.msg, 3.5)
          _this.props.handlePlanChange({plans: this.noPlans})
        }
      },
      error: (xhr, status, error) => {
        Message.warning(error === 'timeout' ? 'The request timed out, please try again later !' : 'Please try again later !', 3.5)
        _this.props.handlePlanChange({plans: this.noPlans})
      }
    })
  }

  searchPlanByNmi = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
          let checksum, is_vpp, nmi, birthday;
          nmi = this.props.search_type_info.nmi;
          checksum = this.props.search_type_info.checksum;
          is_vpp = this.props.is_vpp;
          birthday = this.props.birthday
          this.setState({ search_nmi: nmi, search_checksum: checksum})

          let search_type_info = this.props.search_type_info
          Object.assign(search_type_info, { search_nmi: nmi, search_checksum: checksum});
          this.props.handleSearchTypeInfoChange({search_type_info: search_type_info})
          this.getPlans(nmi, checksum, is_vpp, birthday);
      }
    });
  }

  searchPlanByMirn = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let mirn = this.props.search_type_info.mirn
        let checksum = this.props.search_type_info.checksum
        let birthday = this.props.birthday
        let search_type_info = this.props.search_type_info
        Object.assign(search_type_info, { search_mirn: mirn});
        this.props.handleSearchTypeInfoChange({search_type_info: search_type_info})
        this.getGasPlans(mirn, checksum, birthday)
      }
    })
  }

  onNmiSubmit = (e) => {
    e.preventDefault();
    this.searchPlanByNmi()
  }

  onMirnSubmit = (e) => {
    e.preventDefault();
    this.searchPlanByMirn()
  }

  getAddress = (address) => {
    let search_type_info = this.props.search_type_info
    Object.assign(search_type_info, { connected_full_address: address});
    this.props.handleSearchTypeInfoChange({search_type_info: search_type_info})
  }

  selectAddress = (fullAddress, metaData) => {
    if(this.props.energy_type == 'gas') {
      this.getMirnByAddress(fullAddress, metaData)
    }
    if (this.props.energy_type == 'electricity') {
      this.getNmiByAddress(fullAddress, metaData)
    }
  }

  getNmiByAddress = (fullAddress, metaData) => {
    this.setState({
      'nmi': '',
      'checksum': '',
      'search_history_id': ''
    },()=>{
      this.setState({
        isShowLoading: true,
      })
      const channel_company = $("#channel_company").val()
      //request nmi
      $.ajax({
        url: '/plans/nmi',
        data: {
          authenticity_token: $('[name="csrf-token"]')[0].content,
          full_address: fullAddress,
          data: metaData,
          search_type: 'Channel',
          channel_company: channel_company
        },
        type: 'POST',
        dataType: 'JSON',
        timeout: 10000,
        success: (data) => {
          if (data.result) {
            if (data.data.length == 1) {
              this.setNmiChecksum(
                data.data[0].nmi,
                data.data[0].checksum,
                data.data[0].search_history_id,
                data.data[0].address
              )
            } else {
              this.setState({
                nmi_list: data.data,
                nmiListVisible: true
              })
            }
          } else {
            Message.warning(data.msg, 3.5)
          }
          this.setState({
            isShowLoading: false,
          })
        },
        error: (xhr, status, error) => {
          Message.warning(error === 'timeout' ? 'The request timed out, please try again later !' : 'Please try again later !', 3.5)
          this.setState({
            isShowLoading: false,
          })
        }
      })
    })

  }

  getMirnByAddress = (fullAddress, metaData) => {

    this.setState({
      'mirn': '',
      'checksum': '',
      'search_history_id': ''
    }, () => {
      this.setState({
        isShowLoading: true,
      })
      const channel_company = $("#channel_company").val()
      //request nmi
      $.ajax({
        url: '/plans/mirn', // to do get mirn by address
        data: {
          authenticity_token: $('[name="csrf-token"]')[0].content,
          full_address: fullAddress,
          data: metaData,
          customer_type: this.props.customer_type.toUpperCase(),
          search_type: 'Channel',
          channel_company: channel_company
        },
        type: 'POST',
        dataType: 'JSON',
        timeout: 10000,
        success: (data) => {
          if (data.result) {
            if (data.data.length == 1) {
              this.setMirnChecksum(
                data.data[0].mirn,
                data.data[0].checksum,
                data.data[0].search_history_id,
                data.data[0].address
              )
            } else {
              this.setState({
                mirn_list: data.data,
                mirnListVisible: true
              })
            }
          } else {
            Message.warning(data.msg, 3.5)
          }
          this.setState({
            isShowLoading: false,
          })
        },
        error: (xhr, status, error) => {
          Message.warning(error === 'timeout' ? 'The request timed out, please try again later !' : 'Please try again later !', 3.5)
          this.setState({
            isShowLoading: false,
          })
        }
      })
    })

  }

  setNmiChecksum = (nmi, checksum, search_history_id, address) => {
    this.setState({
      'nmi': nmi,
      'checksum': checksum,
      'search_history_id': search_history_id,
       connected_full_address: address
    },() => {
      this.props.form.setFieldsValue({connected_full_address: address})
      this.setState({nmiListVisible: false})
      let search_type_info = this.props.search_type_info
      Object.assign(search_type_info, { connected_full_address: address, search_connected_full_address: address, nmi: nmi, checksum: checksum});
      this.props.handleSearchTypeInfoChange({search_type_info: this.props.search_type_info})
      this.getPlans(nmi, checksum, this.props.is_vpp, this.props.birthday, search_history_id)
    })
  }

  setMirnChecksum = (mirn, checksum, search_history_id, address) => {
    this.setState({
      'mirn': mirn,
      'checksum': checksum,
      'search_history_id': search_history_id,
        connected_full_address: address
    },() => {
      this.props.form.setFieldsValue({connected_full_address: address})
      this.setState({mirnListVisible: false})
      let search_type_info = this.props.search_type_info
      Object.assign(search_type_info, { connected_full_address: address, search_connected_full_address: address, mirn: mirn,
      checksum: checksum});
      this.props.handleSearchTypeInfoChange({search_type_info: this.props.search_type_info})
      this.getGasPlans(mirn, checksum, this.props.birthday, search_history_id)
    })

  }

  selectActive = (condition) => {
    if(condition) {
      return "de-select-active"
    } else {
      return ""
    }
  }



  render(){
    let errors;
    const { getFieldProps, getFieldError, isFieldTouched } = this.props.form;
    const nmiError = getFieldError('full_nmi');
    const mirnError = getFieldError('full_mirn');
    let nmiActive = (this.props.plan_search_type === 'nmi' ? 'de-select de-select-active select-search-method' : 'de-select select-search-method');
    let addressActive = (this.props.plan_search_type === 'address' ? 'de-select de-select-active select-search-method' : 'de-select select-search-method');

    const _nmi_lsit =
      <React.Fragment>
        <table className="ui celled striped table">
          <thead>
            <tr>
              <th className="collapsing"></th>
              <th>NMI</th>
              <th>Checksum</th>
              <th>NMI Address</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.nmi_list.map((nmi, index)=>{
                return <tr key={index}>
                  <td>{index+1}</td>
                  <td style={{wordBreak: 'break-all'}}>{nmi.nmi}</td>
                  <td>{nmi.checksum}</td>
                  <td>{nmi.address}</td>
                  <td>
                    <a onClick={() => {this.setNmiChecksum(nmi.nmi, nmi.checksum, nmi.search_history_id, nmi.address)}}>Select</a>
                  </td>
                </tr>
              })
            }
            <tr></tr>
          </tbody>
        </table>
        {
          this.state.nmi_list.length === 0 ?
            <p>
              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
              Sorry, there is no search result for your address. Please use NMI to search again.
            </p>
          : ''
        }
      </React.Fragment>

    const _mirn_lsit =
      <React.Fragment>
        <table className="ui celled striped table">
          <thead>
            <tr>
              <th className="collapsing"></th>
              <th>MIRN</th>
              <th>Checksum</th>
              <th>MIRN Address</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.mirn_list.map((mirn, index)=>{
                return <tr key={index}>
                  <td>{index+1}</td>
                  <td style={{wordBreak: 'break-all'}}>{mirn.mirn + mirn.checksum }</td>
                  <td>{mirn.checksum}</td>
                  <td>{mirn.address}</td>
                  <td>
                    <a onClick={() => {this.setMirnChecksum(mirn.mirn, mirn.checksum, mirn.search_history_id, mirn.address)}}>Select</a>
                  </td>
                </tr>
              })
            }
            <tr></tr>
          </tbody>
        </table>
        {
          this.state.mirn_list.length === 0 ?
            <p>
              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
              Sorry, there is no search result for your address. Please use MIRN to search again.
            </p>
          : ''
        }
      </React.Fragment>


    return (
      <React.Fragment>
        <Modal
          title="NMI List"
          width={800}
          centered={true}
          maskClosable= {false}
          visible={this.state.nmiListVisible}
          onCancel={()=>this.setState({nmiListVisible: false})}
          footer={
            <div>
              <Button key="back" className="ui ch-orange button" onClick={()=>this.setState({nmiListVisible: false})}>Close</Button>
            </div>
          }
          >
          <div>{_nmi_lsit}</div>
        </Modal>

        <Modal
          title="MIRN List"
          width={800}
          centered={true}
          maskClosable= {false}
          visible={this.state.mirnListVisible}
          onCancel={()=>this.setState({mirnListVisible: false})}
          footer={
            <div>
              <Button key="back" className="ui ch-orange button" onClick={()=>this.setState({mirnListVisible: false})}>Close</Button>
            </div>
          }
          >
          <div>{_mirn_lsit}</div>
        </Modal>

        <div>
          <h3 className="ui header ch-left-bord">Supply Address</h3>

          {
            this.props.energy_type == 'gas' ?
            <div className="ui grid no-margin">
              <div className="four wide computer six wide tablet sixteen wide mobile column">
                <div className="ch-left-title">Customer Type</div>
              </div>
              <div className="twelve wide computer ten wide tablet sixteen wide mobile column">
                <div className={`de-select ${this.selectActive(this.props.customer_type == 'residential')}`} onClick={() => this.handleCustomerTypeChange('residential')}>
                  <span className="block">Residential</span>
                </div>
                <div className={`de-select ${this.selectActive(this.props.customer_type == 'business')}`} onClick={() => this.handleCustomerTypeChange('business')}>
                  <span className="block">Business</span>
                </div>
              </div>
            </div>
            :
            ""
          }

          <div className="ui grid no-margin">
            <div className="four wide computer six wide tablet sixteen wide mobile column">
              <div className="ch-left-title">Search Method</div>
            </div>
            <div className="twelve wide computer ten wide tablet sixteen wide mobile column">

              {
                this.props.energy_type == 'gas' ?
                <div className={`de-select ${this.selectActive(this.props.plan_search_type == 'mirn')}`} onClick={() => this.changeType('mirn')}>
                  <span className="block">MIRN</span>
                </div>
                :
                <div className={nmiActive} onClick={() => this.changeType('nmi')}>
                  <span className="block">NMI</span>
                </div>
              }

              <div className={addressActive} onClick={() => this.changeType('address')}>
                <span className="block">Address</span>
              </div>
            </div>
          </div>
          {
            this.props.plan_search_type === 'address' ?
              <div className="search-plan-address">
                <div className="ui grid no-margin">
                  <div className="four wide computer six wide tablet sixteen wide mobile column">
                    <div className="ch-left-title">Address</div>
                  </div>
                  <div className="eight wide computer six wide tablet sixteen wide mobile column">
                    <div className="field" id="addr-finder-autocomplete-root">
                      {/*<input className="form-control" placeholder="Address" type="text" name="address" id="address-finder" required={true} onChange={(e) => this.onAddressChange(e.target.value)} />*/}
                      <AddressBox
                        id="connected_full_address"
                        selectAddress={this.selectAddress}
                        getAddress= {this.getAddress}
                        getFieldError = {getFieldError}
                        getFieldProps = {getFieldProps}
                        fullAddressKey = 'connected_full_address'
                        fullAddressValue = {this.props.search_type_info.connected_full_address}
                        form = {this.props.form}
                        disabled = {this.state.isManualAddress}
                        validErrorInfo = {'this is invalid address'}
                        isValidAddress = {this.state.isValidAddress}
                        placeholder = "e.g unit(optional) , 123 Sample Street , Sydney NSW 2000"
                      />
                    </div>
                  </div>
                </div>
              </div>
            : ''
          }
          {
            this.props.plan_search_type === 'nmi' ?
            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.onNmiSubmit}>
              <div className="search-plan-nmi">
                <div className="ui grid no-margin">
                  <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="ch-left-title ch-input-title">NMI</div>
                  </div>
                  <div className="six wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="field os-input">
                      <input
                        id="full_nmi"
                        type="text"
                        placeholder="NMI"
                        className={['form-control' , nmiError ? 'validate-error-input' : ''].join(' ')}
                        {...getFieldProps('full_nmi', {
                        initialValue: this.props.search_type_info.full_nmi,
                        onChange: this.onNmiChange,
                        rules: [
                          {validator: Validate.validateEmpty, message: I18n.t("required")},
                          {len: 11, message: "Nmi must be exactly 11 characters"}
                        ],
                        getValueFromEvent: (event) => {
                          return  event.target.value.replace(/\s+/g,"")
                        }
                      })}/>
                      <div className="validate-error-info">
                        {(errors = nmiError) ? errors.join(',') : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-plan-nmi">
                <div className="ui grid no-margin">
                  <div className="four wide computer six wide tablet sixteen wide mobile column">
                  </div>
                  <div className="ten wide computer nine wide tablet sixteen wide mobile column">
                    <button type="button" className="ui green button" onClick={() => this.searchPlanByNmi()}>Search</button>
                  </div>
                </div>
              </div>
            </Form>
            : ''
          }

          {
            this.props.plan_search_type === 'mirn' ?
            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.onMirnSubmit}>
              <div className="search-plan-nmi">
                <div className="ui grid no-margin">
                  <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="ch-left-title ch-input-title">MIRN</div>
                  </div>
                  <div className="six wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="field os-input">
                      <input
                        id = "full_mirn"
                        type="text"
                        placeholder="MIRN"
                        className={['form-control' , mirnError ? 'validate-error-input' : ''].join(' ')}
                        {...getFieldProps('full_mirn', {
                        initialValue: this.props.search_type_info.full_mirn,
                        onChange: this.onMirnChange,
                        rules: [
                          {validator: Validate.validateEmpty, message: I18n.t("required")},
                          {len: 11, message: "MIRN must be exactly 11 characters"}
                        ],
                        getValueFromEvent: (event) => {
                          return  event.target.value.replace(/\s+/g,"")
                        }
                      })}/>
                      <div className="validate-error-info">
                        {(errors = mirnError) ? errors.join(',') : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-plan-nmi">
                <div className="ui grid no-margin">
                  <div className="four wide computer six wide tablet sixteen wide mobile column">
                  </div>
                  <div className="ten wide computer nine wide tablet sixteen wide mobile column">
                    <button type="button" className="ui green button" onClick={() => this.searchPlanByMirn()}>Search</button>
                  </div>
                </div>
              </div>
            </Form>
            : ''
          }

        </div>
      </React.Fragment>
    )
  }
}
export default Form.create()(SearchType)