export default {
  positiveInteger: /^[1-9][0-9]*$/, // eg: 12-> true  01 -> false
  email:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  empty: /\S/,
  licenceNumber: /^\d{4,9}$/,
  medicare: /^.{9}$/,
  mobile: /^04\d{8}$/,
  concessionCardNumber: /^.{3,9}$/,
  placeAddress: /^((\S+\s)|(\d+\s\S+\s))\S+\s\S+,\S+\s\S+\s[0-9]+\s(\S+)$/,
  // googleAddress: /^((Unit\s[\u4E00-\u9FA5A-Za-z0-9_]+\s\S+\s)|(\S+\s))([A-Za-z]+\s)*[A-Za-z]+,([A-Za-z]+\s){2,}\d+\s([A-Za-z]+\s)*(AU)$/,
  // googleAddress: /^(([\u4E00-\u9FA5A-Za-z0-9_-]+\/\S+\s)|(\S+\s))([A-Za-z0-9_-]+\s)*[A-Za-z0-9_-]*,([A-Za-z-]+\s){2,}\d+$/,
  googleAddress: /^\S$/,
  positiveInteger: /^\+?[1-9][0-9]*$/,
  billName: /^(\S+)\s(\S)+$/,
  password: /^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/
}