import Message from 'antd/lib/message'
import 'antd/lib/message/style/css'

document.addEventListener("turbolinks:load", function () {
    $(".share-qrcode").click(function(){
      $("#sharePromotionGroup").modal("toggle");
    });


    $(".create-a-group").click(function(e){
      var element = $(this)
      var method = element.data('method')
      var url = element.data('url')
      $('.buy-create-confirm-modal').modal({
        onApprove: function(){
          $.ajax({
            type: method,
            url: url,
            data: {
              authenticity_token: $('[name="csrf-token"]')[0].content
            },
            success: function(data){
              if(data.flag){
                Message.success(data.info, 2)
              }else{
                Message.warn(data.info, 2)
              }
            },
            error: function(xhr, textstatus, errorThrown){
              Message.error('request failed!', 2);
            }
          });
          setTimeout(function () {window.location.reload();}, 2000);
        }
      }).modal("toggle")
    });

    
    $(".end-a-promotion-group").click(function(e){
      var element = $(this)
      var method = element.data('method')
      var url = element.data('url')
      $('.end-now-modal').modal({
        onApprove: function(){
          $.ajax({
            type: method,
            url: url,
            data: {
              authenticity_token: $('[name="csrf-token"]')[0].content
            },
            success: function(data){
              if(data.flag){
                Message.success(data.info, 2)
              }else{
                Message.warn(data.info, 2)
              }
            },
            error: function(xhr, textstatus, errorThrown){
              Message.error('request failed!', 2);
            }
          });
          setTimeout(function () {window.location.reload();}, 2000);
        }
      }).modal("toggle")
    });
});