import $ from 'jquery'
import 'jquery-countdown';
import QRCode from 'qrcode';
import html2canvas from 'html2canvas';
import moment from 'moment';

  function clipboardCopy(selectClass) {
    if (selectClass == undefined) {
      selectClass = 'clipboard'
    }
    $(`.${selectClass}`).on('click', function (e) {
      let that = $(this);
      let text = that.data('clipboard-text');
      let textarea = document.createElement('textarea');

      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("Copy");
      textarea.style.display = 'none';

      that.append("<span class='tip' style='z-index: 1; width: 268px; position: absolute; right: 50%; top: 50%; background: gray; padding: 5px 10px; border-radius: 17px; color: white; transform: translate(50% ,-50%);'>Copy successfully, share link now!<span>");
      that.find(".tip").fadeOut(2000, function () {
        this.remove()
        textarea.remove();
      });

    })
  }

  function shareQrcodeImage() {
    if ($(".shareQrcodeImage").length > 0) {
      $(".shareQrcodeImage").each(function (index, element) {
        let that = $(element)
        let shareUrl = that.data("url")
        let qrcode = that.find("#share_qrcode")
        let opts = {
          margin: 1
        }

        QRCode.toDataURL(shareUrl, opts)
          .then(dataUrl => {
            qrcode.find('img').attr('src', dataUrl);
            //  disable scroll
            document.documentElement.style = 'position: fixed; top: 0; right: 0; bottom: 0; left: 0;';
            html2canvas(element, {
              useCORS: true,
              scale: window.devicePixelRatio,
              // width: element.offsetWidth,
              // height: element.offsetHeight
            }).then(function (canvas) {
              console.log(canvas)
              let img = new Image
              let dataUrl = canvas.toDataURL('image/png', 1.0)
              img.src = dataUrl
              img.style = "width: 300px; margin: 0 auto;"
              that.prev().find(".modal-body").html(img);
              // enabled scroll
              document.documentElement.style = '';
            });
          })
          .catch(err => console.error(err))
      })
    }
  }

  function bidTimer() {
    $('.bidTimer').each(function (index, element) {
      let timer = $(element)
      let endDate = moment.utc(timer.data('end-date'))
      console.log(endDate)
      timer.countdown(endDate.toDate(), function (event) {
        $(this).find(".timer").html(event.strftime('' +
          '<span class="timerItem">%H</span>' +
          '<span class="timerItem">%M</span>' +
          '<span class="timerItem">%S</span>'));
      }).on('finish.countdown', function () {
        console.log('time up!');
      });
    })
  }

export default {
  clipboardCopy,
  shareQrcodeImage,
  bidTimer
}