import ValidateRules from './regExp'
import axios from 'axios'
export default {
  validateEmpty: (rule, value, callback,source, options) => {
    value = value+'' || ''
    let message = rule.message || 'This is required.' 
    if(!ValidateRules.empty.test(value) || value === 'null'){
      callback(message)
    }
    callback()
  },
  validateEmail: (rule, value, callback,source, options) => {
    let message = rule.message || 'This is invalid' 
    if(ValidateRules.empty.test(value)){
      if(!ValidateRules.email.test(value)){
        callback(message)
      }
    }
    callback()
  },
  validateCustomerUniqEmail: (rule, value, callback,source, options) => {
    let message = rule.message 
    if(ValidateRules.empty.test(value)){
      if(ValidateRules.email.test(value)){
        axios.get('/data_validate/uniq_customer_email.json', {params:{email: value}})
        .then( (response) => {
          let exist_flag = response.data.flag 
          if(exist_flag){
            callback(message)
          } else {
            callback()
          }
        })
        .catch(function (error) { callback() } )
      }
    }
  },
  validateGoogleAddress: (rule, value, callback,source, options) => {
    let message = rule.message || 'This is invalid' 
    if(ValidateRules.empty.test($.trim(value))){
      if(!ValidateRules.googleAddress.test($.trim(value))){
        callback(message)
      }
    }
    callback()
  },
  validateNumberLen: (rule, value, callback,source, options) => {
    let message = rule.message || 'This is invalid'
    let re = /^\d+$/
    if(ValidateRules.empty.test(value)){
      if(!re.test(value) || value.length > rule.maxLen || value.length < rule.minLen){
        callback(message)
      }
    }
    callback()
  },
  _validateNumberLen: (rule, value, callback) => {
    let re = new RegExp(`^\\d{${rule.minLen},${rule.maxLen}}$`)
    if(ValidateRules.empty.test(value)){
      if(!re.test(value)){
        callback(false)
      } else {
        callback(true)
      }
    }
    
  },
  validateNumberMinMax: (rule, value, callback,source, options) => {
    let message = rule.message || `${rule.min}~${rule.max}`
    if(ValidateRules.empty.test(value)){
      if(rule.min === undefined && !rule.max !== undefined){
        if(!ValidateRules.positiveInteger.test(value) ||
          Number.parseInt(value) > Number.parseInt(rule.max)
          ) {
            callback(message)
        }
      }
      if(rule.min !== undefined && !rule.max === undefined){
        if(!ValidateRules.positiveInteger.test(value) ||
          Number.parseInt(value) < Number.parseInt(rule.min)
          ) {
            callback(message)
        }
      }
      if(rule.min !== undefined && !rule.max !== undefined){
        if(!ValidateRules.positiveInteger.test(value) ||
          Number.parseInt(value) < Number.parseInt(rule.min) ||
          Number.parseInt(value) > Number.parseInt(rule.max)
          ) {
            callback(message)
        }
      }
      
    }
    callback()
  },
  _validateNumberMinMax: (rule, value, callback,source, options) => {
   
    let message = rule.message || `${rule.min}~${rule.max}`
    if(ValidateRules.empty.test(value)){
      if(rule.min === undefined && !rule.max !== undefined){
        if(!ValidateRules.positiveInteger.test(value) ||
          Number.parseInt(value) > Number.parseInt(rule.max)
          ) {
            callback(message)
        }
      }
      if(rule.min !== undefined && !rule.max === undefined){
        if(!ValidateRules.positiveInteger.test(value) ||
          Number.parseInt(value) < Number.parseInt(rule.min)
          ) {
            callback(message)
        }
      }
      if(rule.min !== undefined && !rule.max !== undefined){
        if(!ValidateRules.positiveInteger.test(value) ||
          Number.parseInt(value) < Number.parseInt(rule.min) ||
          Number.parseInt(value) > Number.parseInt(rule.max)
          ) {
            callback(message)
        }
      }
      
    }
    callback()
  },
  validateStringLen: (rule, value, callback,source, options) => {
    let message = rule.message || 'This is invalid'
    let re = new RegExp(`^\\S{${rule.minLen},${rule.maxLen}}$`)
    if(ValidateRules.empty.test(value)){
      if(!re.test(value)){
        callback(message)
      }
    }
    callback()
  },
  validateMobileAu: (rule, value, callback,source, options) => {
    let message = rule.message || 'This is invalid'
    if(ValidateRules.empty.test(value)){
      if(!ValidateRules.mobile.test(value)){
        callback(message)
      }
    }
    callback()
  },
  validateBillName: (rule, value, callback,source, options) => {
    let message = rule.message || 'This is invalid'
    if(ValidateRules.empty.test(value)){
      if(!ValidateRules.billName.test(value)){
        callback(message)
      }
    }
    callback()
  },

  validatePassword: (rule, value, callback,source, options) => {
    let message = rule.message || 'This is invalid'
    if(ValidateRules.empty.test(value)){
      if(!ValidateRules.password.test(value)){
        callback(message)
      }
    }
    callback()
  },

  validateIsTrue:  (rule, value, callback,source, options) => {
    let message = rule.message || 'This is invalid'
    if(value !== true) {
      callback(message)
    } 
    callback()
  },
  validateIsfalse:  (rule, value, callback,source, options) => {
    let message = rule.message || 'This is invalid'
    if(value !== false) {
      callback(message)
    } 
    callback()
  }
 
}