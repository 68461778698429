// default
let de = {

}

let en = {
  'connection': 'Connection',
  'energy_plan': 'Energy Plan',
  'account_details': 'Account Details',
  'review': 'Review',
  'confirmation': 'Confirmation',
  'signup_need': 'To sign up you will need:',
  'email_address': 'an email address',
  'your_card_type': 'your driver licence, Medicare card or passport',
  'time_spend': 'around 5 minutes',
  'customer_type': 'Are you a new or existing Discover Energy customer?',
  'signup_new_customer': 'New Discover Energy Customer',
  'signup_old_customer': 'Existing Discover Energy customer',
  'customer_address_type': 'Is this a Residential or Business address ?',
  'residential': 'Residential',
  'business': 'Business',
  'customer_address': 'What is your address ?',
  'energy_type': 'Select Energy Type',
  'electricity': 'Electricity',
  'life_support_question': 'Is anyone living at the property on life support?',
  'yes': 'Yes',
  'no': 'No',
  'moving_home_question': 'Are you moving home?',
  'moving_yes': "Yes, I'm",
  'moving_home': 'moving home',
  'no_i_already': 'No, I already',
  'live_here': 'live here',
  'moving_description': 'If you are moving, your main switch must be off and there must be clear access to your meter. If there is no clear access to your meter, please contact us to',
  'live_chat_link': 'Live Chat link',
  'solar_question': 'Do you have solar installation at this address?',
  'email': 'Email',
  'email_enter_hint': 'Please enter your email',
  'consent_policy': "If you click continue but do not proceed with switching to Discover Energy, you consent to being contacted by email regarding DE’s products and services in accordance with our Privacy Policy.",
  'continue': 'Continue',
  'choose_energy_plan': 'Choose your energy plan',
  'exclusive_offer': 'EXCLUSIVE OFFER',
  'family_friends': 'Family & Friends',
  'pre_10_customer': 'FIRST 10 NON SOLAR CUSTOMERS',
  'discount_35': '35% Discount',
  'on_usage': 'on usage',
  'signip_bonus': '$100 Sign up bonus',
  'over_4_quarters': 'applied over 4 quarters',
  'send_us_email': 'Do you want to be a part of this exclusive offer? Send us an email at',
  'current_energy_bill': 'along with your most current energy bill.',
  'lowest': 'Lowest',
  'market_offer': 'market offer',
  'no_hidden': 'No hidden',
  'costs': 'costs',
  'no_lock': 'No lock',
  'in_contacts': 'in contacts',
  'select': 'select',
  'select_diff_plan': 'Select a different plan',
  'will_receive': 'You will receive',
  'supply_charge': 'Supply charge',
  'daily_supply_charge': 'Daily supply charge',
  'per_day': 'per day',
  'usage_rates': 'Usage rates',
  'peak_usage': 'Peak usage per day',
  'before_discount': 'Before discount',
  'after_35_discount': 'After 35% discount',
  'back': 'Back',
  'bill_contact_info': 'Billing and contact information',
  'mail_address': 'Mailing address',
  'diff_mail_address': 'Use a different mailing address',
  'add_contact_email': 'Add contact email',
  'cancel_contact_email': 'Cancel contact email',
  'add_email_address': 'Add an email address',
  'electricity_bill': 'Electricity Bill',
  'receive_electricity_bill_times': 'How often would you like to receive your electricity bills?',
  'monthly': 'Monthly',
  'day_of_receive_bill': 'What day of the month would you like your monthly electricity bills issued?',
  'please_select': 'Please select',
  'direct_debit': 'Direct debit',
  'MONTHLY': 'MONTHLY',
  'DAILY': 'DAILY',
  'review_direct_debit': 'Direct debit',
  'review_no_direct_debit': 'No Direct Debit',
  'review_connection_date': 'Connection date',
  'setup_direct_debit_question': 'Would you like to setup direct debit now?',
  'review_details': 'Review your details',
  'review_and_accept': "Please review the details of your connection before pressing the ‘I Accept’ button to finalise your application",
  'title': 'Title',
  'connection_address': 'Connection address',
  'change': 'change',
  'bills_send': 'Bills sent to',
  'bill_frequency': 'Bill frequency',
  'review_bill_frequency': 'Bill frequency',
  'your_energy_plans': 'Your energy plans',
  'terms_and_conditions': 'Terms and conditions',
  'read_terms_and_conditions': 'I have read and understood the terms and conditions.',
  'change_plan': 'Change Plan',
  'thank_you_for_signup': 'Thank you for signing up to Discover Energy',
  'summary': 'Summary',
  'happens_next_question': 'What happens next?',
  'will_receive_plan_email': "You'll receive an email about your new plan",
  'check_your_email': 'If you don’t receive it in the next 10 minutes make sure to check your junk folder.',
  'arrange_a_disconnection': 'Arrange a disconnection',
  'contact_retailer': "If you haven't already done so,contact your current retailer to arrange a disconnection at your current address.",
  'energy_switched': "Your energy will be switched on about 2 days after cooling",
  'learn_more': 'Learn more >',
  'life_support': 'Life Support',
  'communicate_customer': 'Please communicate with the customer service online about the life support !',
  'check_fill_out_info': 'Please check your fill out information !',
  'please_select_one': 'Please select one of them.',
  'unit_type_optional': 'Unit Type(optional)',
  'unit_type': 'Unit Type',
  'unit_optional': 'Unit(optional)',
  'unit': 'Unit',
  'street_number': 'Street number',
  'street_name': 'Street name',
  'street_type': 'Street Type',
  'suburb': 'Suburb',
  'state': 'State',
  'postcode': 'Postcode',
  'confirm_address': 'Confirm Address',
  'error_invalid_address': 'This is an invalid address.',
  'electricity_connection_need_ask': 'We need to ask as this may affect your electricity connection.',
  'need_connected_question': 'When do you need to be connected ?',
  'select_date': 'Select date',
  'electricity_will_connected': 'Your electricity will be connected between 7am and 11pm.',
  'business_electricity_connection_notice': "We require 3 business day's notice (before 1pm) for electricity connection.",
  'contact_us': 'contact us',
  'further_info': ' for further information.',
  'required': 'This is required.',
  'cardholder_name': 'Cardholder name',
  'credit_card_number': 'Credit card number',
  'account_name': 'Account name',
  'account': 'Account',
  'account_bsb': 'BSB',
  'account_number': 'Account number',
  'account_expiry_date': 'Expiry date',
  'bank_debit_info': 'I authorise and request Discover Energy Pty Ltd (User ID number 535-774) to debit my account details provided above. This debit will be made through the Bulk Electronic Clearing System (BECS) and will be subject to the terms and conditions of the',
  'direct_debit_request_service_agreement': '  Direct Debit Request Service Agreement ',
  'direct_debit_agree': ' that I have read and agree to.',
  'diretc_debit_hint_info':'Please note that Discover Energy does not hold your credit card details. These details are saved securely with our banking partner Commonwealth Bank of Australia.',
  'read_agree': 'I have read and agree to the ',
  'direct_debit_terms_and_condition': 'direct debit terms and condition',
  'bank_account': 'Bank account',
  'credit_card': 'Credit card',
  'please_account_number': 'Please enter your bank account number',
  'please_account_name': 'Please enter your bank account name',
  'name_on_card': 'Name on card',
  'month': 'month',
  'year': 'year',
  'consent_verify_identity': "• Given us consent to verify your identity with the document issuer or official record holder.",
  'consent_check_concession': "• Provided your consent for us to check your concession eligibility status with the respective Departments(show if concession is selected) Authority for eServices script hyperlink script to be provided.",
  'privacy_policy': 'Privacy policy',
  'consent_before_credit_check': "• Acknowledged that we will perform a credit check on your personal details prior to transferring your energy account to Discover Energy.",
  'credit_card_check': 'Credit check',
  'read_accepted_market': "• Read and accepted the Market ",
  'terms_and_agreement': 'Terms and Agreement',
  'sign_up': 'Sign Up',
  'welcome_to_discover_energy': '— Welcome to Discover Energy —',
  'terms_and_conditions2': 'Term and Conditions',
  'business_details': 'Your business details',
  'business_name': 'Business name',
  'enter_your_business_name': 'Enter your business name',
  'abn': 'ABN',
  'enter_your_name': 'Enter your abn',
  'contact_name': 'Contact name',
  'enter_your_contact_name': 'Enter your contact name',
  'mobile_number': 'Mobile number',
  'enter_your_mobile_number': 'Enter your mobile number',
  'work_number': 'Work number',
  'enter_your_work_number': 'Enter your work number',
  'email_sufix': 'Email-',
  'electricity_bills_issued': 'Electricity bills - Issued ',
  'no_direct_debit': 'No Direct Debit',
  'this_is_invalid': 'This is invalid.',
  'your_details': 'Your details',
  'your_call': 'Title',
  'your_first_name': 'First name',
  'enter_your_first_name': 'Enter your first name',
  'your_last_name': 'Last name',
  'enter_your_last_name': 'Enter your last name',
  'your_mobile': 'Mobile',
  'your_date_of_birth': 'Date of birth',
  'concessions': 'Concessions',
  'hold_concession_card_question': 'Do you hold a concession card?',
  'identification_type': 'Identification Type',
  'medicare_card_color': 'Please select your Medicare card color',
  'medicare': 'Medicare',
  'medicare_number': 'Please enter your medicare number',
  'individual_reference_number': 'Individual reference number',
  'please_enter_individual_reference_number': 'Please enter individual reference number',
  'valid_to': 'Valid to',
  'passport': 'Passport',
  'enter_your_passport': 'Enter your passport',
  'licence_state': 'State',
  'driver_licence': 'Driver Licence',
  'enter_driver_licence_number': 'Enter your driver licence number',
  'card_type': 'Card type',
  'card_number': 'Card number',
  'enter_your_concenssion_card_number': 'Enter your concenssion card number',
  'select_yes_and_agree': "by selecting yes and registering my concession details with Discover Energy, I authorise the exchange of my personal information with my concession provider to verify my eligibility for concession. For further information please review the concession terms and conditions.",
  'valid_mobile_number': "Please enter a valid mobile number ie must start with '04' ",
  'read_and_understand_terms_and_conditions': 'Please confirm that you have read and understand the terms and conditions !',
  'please_confirm_relevant_agreement': 'Please confirm the relevant agreement!',
  'signup_wechat_subscribe': 'signup_wechat_subscribe',
  'enum_call_mr': "Mr",
  'enum_call_mrs': "Mrs",
  'enum_call_ms': "Ms",
  'enum_call_miss': "Miss",
  'enum_call_dr': "Dr",
  'enum_iden_driver_licence':'Driver licence',
  'enum_iden_medicare':'Medicare',
  'enum_iden_passport':'Passport',
  'my_account_login': 'My account log in',
  'my_account_login_id': 'My account login ID',
  'my_account_login_uniq_email_error': 'Email has been used',
  'my_account_login_id_hold': 'You can use your email address for My account login',
  'my_account_login_id_hint': 'Manage your energy account in one secure place. View, paybills, monitor your usage and manage your account details.',
  'my_account_login_password': 'Create password for My account login',
  'my_account_login_password_hold': 'Please enter your password',
  'my_account_login_confirm_password': 'Confirm password',
  'my_account_login_password_error': 'The password entered twice is different.',
  'my_account_login_confirm_password_hold': 'Please confirm your password',
  'my_account_login_password_hint': 'Should be 8-20 characters, include at least one letter and one number.',
  'direct_debit_request': 'Direct Debit Request',
  'review_head_title': 'By accepting this offer you agree to the following:',
  'review_retail_head': 'Have read and accepted the',
  'review_retail_desc': 'Retail Terms and Conditions',
  'review_retail_rear': 'applicable to this offer.',
  'review_credit_check_head': 'Acknowledge that we will perform a ',
  'review_credit_check_desc': 'Credit Check ',
  'review_credit_check_rear': 'on your personal details prior to the transferring of your energy account to Discover Energy.',
  'review_privacy_policy_head': 'Have read and understood our ',
  'review_privacy_policy_desc': 'Privacy Policy',
  'review_privacy_policy_rear': '.',
  'review_debit_credit_head': 'Understand and agree to our',
  'review_debit_credit_desc': 'Debit and Credit Card Terms and Conditions',
  'review_debit_credit_rear': '.',
  'review_refund_policy_head': 'Have read and understood our ',
  'review_refund_policy_desc': 'Refund Policy',
  'review_refund_policy_rear': '.',
  'review_conset_iden_check_desc': 'You provide your consent for Discover Energy to verify your identity with the document issuer or official record holder of any identification you have provided.',
  'review_life_support_eligibility_desc': 'If you have provided concession or life support details, you also provide your consent for Discover Energy to verify your concession eligibility status with the relevant concession card provider or the relevant state government department.',
  'happen_cool_title': 'You have 10 business days to change your mind',
  'happen_cool_desc': 'Call us if you change your mind tell us what we can do.',
  'happen_cool_after_title': 'Your energy will be switched on about 2 days after cooling off period',
  'happen_cool_after_desc': 'Clear and safe access to the electricity meter is required and your current retailer will issue a final bill.',
  'property_moving_question': "Does the property you are moving out of need to be disconnected?",
  'property_moving_answer': "If yes, call you current retailer to arrange."
}


let zh = {
  'connection': '联系信息',
  'energy_plan': '电力套餐',
  'account_details': '账户信息',
  'review': '复审信息',
  'confirmation': '确认',
  'signup_need': '注册时，请您准备好',
  'email_address': '电子邮件',
  'your_card_type': '您的有效驾驶执照，医保卡或者护照',
  'time_spend': '整个流程大约需要五分钟',
  'customer_type': '您曾经注册过Discover Energy的账号吗？',
  'signup_new_customer': 'Discover Energy 新客户',
  'signup_old_customer': 'Discover Energy 老客户',
  'customer_address_type': '请选择该地址的用途？',
  'residential': '住宅',
  'business': '商业',
  'customer_address': '请输入您的地址',
  'energy_type': '请选择能源类型',
  'electricity': '电力',
  'life_support_question': '请问您家是否安装了医疗器械（life support）？',
  'yes': '是',
  'no': '否',
  'moving_home_question': '您是要搬家到这里吗？',
  'moving_yes': '是的',
  'moving_home': '搬到这里',
  'no_i_already': '没有',
  'live_here': '我住这里',
  'moving_description': '如果您正在搬家，您的主开关必须关闭，并且必须能够清楚地访问您的仪表。如果没有明确的仪表访问权限，请联系我们',
  'live_chat_link': '在线聊天链接',
  'solar_question': '您是否在这个地址安装了太阳能设备？',
  'email': '邮箱',
  'email_enter_hint': '请输入您的邮箱',
  'consent_policy': '如果您单击“继续”但未继续切换到“发现能源”，则表示您同意根据我们的隐私政策通过电子邮件与DE的产品和服务联系。',
  'continue': '继续',
  'choose_energy_plan': '请选择您的电力计划',
  'exclusive_offer': '独家提供',
  'family_friends': '家人和朋友',
  'pre_10_customer': '前十个非太阳能用户',
  'discount_35': '35％的折扣',
  'on_usage': '坚持使用',
  'signip_bonus': '$ 100注册奖金',
  'over_4_quarters': '申请超过4个季度',
  'send_us_email': '您想成为这个独家优惠的一部分吗？发送电子邮件至',
  'current_energy_bill': '以及最新的能源账单。',
  'lowest': '最低',
  'market_offer': '市场报价',
  'no_hidden': '没有隐藏',
  'costs': '成本',
  'no_lock': '没有锁定',
  'in_contacts': '联系人',
  'select': '选择',
  'select_diff_plan': '选择其他套餐',
  'will_receive': '您将获得',
  'supply_charge': '供应费',
  'daily_supply_charge': '每日供应费',
  'per_day': '每天',
  'usage_rates': '使用率',
  'peak_usage': '每日高峰使用量',
  'before_discount': '折扣之前',
  'after_35_discount': '享受35％的折扣之后',
  'back': '上一步',
  'bill_contact_info': '结算及联系方式',
  'mail_address': '邮寄地址',
  'diff_mail_address': '使用其他邮寄地址',
  'add_contact_email': '添加联系邮箱',
  'cancel_contact_email': '取消添加',
  'add_email_address': '添加电子邮件地址',
  'electricity_bill': '电费账单',
  'receive_electricity_bill_times': '您希望多久收到一次电费账单？',
  'monthly': '每月一次',
  'day_of_receive_bill': '您希望每个月的哪一天收到月度帐单?',
  'please_select': '请选择',
  'direct_debit': '直接付款',
  'MONTHLY': '每月一次',
  'DAILY': '每天一次',
  'review_direct_debit': '自动付款',
  'review_no_direct_debit': '未设置自动付款',
  'review_connection_date': '连接日期',
  'setup_direct_debit_question': '您是否希望即刻设置扣款资料吗?',
  'review_details': '查看您的详细信息',
  'review_and_accept': '在按“我接受”按钮完成申请之前，请查看连接的详细信息',
  'title': '标题',
  'connection_address': '连接地址',
  'change': '修改',
  'bills_send': '账单发送到',
  'bill_frequency': '账单发送频率',
  'review_bill_frequency': '账单发送周期',
  'your_energy_plans': '您的能源套餐',
  'terms_and_conditions': '条款和条件',
  'read_terms_and_conditions': '我已阅读并理解条款内容和条件',
  'change_plan': '修改套餐',
  'thank_you_for_signup': '感谢您注册 Discover Energy',
  'summary': '概要',
  'happens_next_question': '接下来发生什么？',
  'will_receive_plan_email': '您将收到有关新计划的电子邮件',
  'check_your_email': '如果您在接下来的10分钟内没有收到它，请务必检查您的垃圾文件夹。',
  'arrange_a_disconnection': '安排断开连接',
  'contact_retailer': '请确保您所提供地址的电表清晰且处于安全位置，读表完成后，您当前的电力零售公司将会给你发送一份他们的最终账单。',
  'energy_switched': '您所提供地址的电力将会在冷静期结束后的两天自动连接到Discover Energy公司',
  'learn_more': '更多 >',
  'life_support': '技术支持',
  'communicate_customer': '请在线与客服沟通生活支持！',
  'check_fill_out_info': '请检查您的填写信息！',
  'please_select_one': '请选择其中一个。',
  'unit_type_optional': '门牌号类型（可选）',
  'unit_type': '门牌号类型',
  'unit_optional': '门牌号（可选）',
  'unit': '门牌号',
  'street_number': '街道号码',
  'street_name': '街道名称',
  'street_type': '街道类型',
  'suburb': '区',
  'state': '州',
  'postcode': '邮编',
  'confirm_address': '确认地址',
  'error_invalid_address': '这是一个无效的地址',
  'electricity_connection_need_ask': '请向我们发送电子邮件，我们专业的客服人员将会与您联络。',
  'need_connected_question': '您将需要在什么时候接通用电？',
  'select_date': '请选择日期',
  'electricity_will_connected': '您的电力将在早上7点至晚上11点之间连接。',
  'business_electricity_connection_notice': '我们需要提前3个工作日通知电力供应商为您进行连接。您的电力将在工作时间内自动连接，请',
  'contact_us': '联系我们',
  'further_info': '以获取更多的咨询。',
  'required': '这里是必填',
  'cardholder_name': '持卡人姓名',
  'credit_card_number': '信用卡卡号',
  'account_name': '账户名',
  'account': '账户',
  'account_bsb': '银行清算号码（BSB）',
  'account_number': '银行账户号码',
  'account_expiry_date': '到期时间',
  'bank_debit_info': '知情同意书，我授权并请求Discover Energy Pty Ltd（用户ID号535-774）从我以上提供的帐户详细信息中扣款。此借方将通过批量电子清算系统（BECS）进行结算，我已阅读并同意', 
  'direct_debit_request_service_agreement': '直接付款的请求服务协议条款和约束条件',
  'direct_debit_agree': '。',
  'diretc_debit_hint_info': '请注意，Discover Energy并不会保存您的信用卡信息。这些细节将被我们的银行合作伙伴，澳大利亚联邦银行安全保存。',
  'read_agree': '我已经阅读并同意',
  'direct_debit_terms_and_condition': '直接扣款条款和条件',
  'bank_account': '银行账户',
  'credit_card': '信用卡账户',
  'please_account_number': '请输入您的银行帐号',
  'please_account_name': '请输入您的银行帐户名称',
  'name_on_card': '卡片上的名字',
  'month': '月',
  'year': '年',
  'consent_verify_identity': '• 同意我们与文件发行人或官方记录持有人核实您的身份。',
  'consent_check_concession': '• 如果您同意我们检查您与各部门的特许资格状态（显示是否选择了特许权）将提供电子服务脚本超链接脚本的权限。',
  'privacy_policy': '隐私协议',
  'consent_before_credit_check': '• 确认在将您的能源账户转移到 Discover Energy 之前，我们将对您的个人信息进行信用检查。',
  'credit_card_check': '信用检查',
  'read_accepted_market': '• 阅读并接受市场',
  'terms_and_agreement': '条款和协议',
  'sign_up': '注册',
  'welcome_to_discover_energy': '— 欢迎来到 Discover Energy —',
  'terms_and_conditions2': '条款和条件',
  'business_details': '您的企业信息',
  'business_name': '公司名称',
  'enter_your_business_name': '输入您的公司名称',
  'abn': 'ABN',
  'enter_your_name': '请输入您的公司ABN',
  'contact_name': '联系人姓名',
  'enter_your_contact_name': '请输入联系人姓名',
  'mobile_number': '手机号码',
  'enter_your_mobile_number': '请输入联系人手机号码',
  'work_number': '工号',
  'enter_your_work_number': '请输入工号',
  'email_sufix': '邮箱-',
  'electricity_bills_issued': ' ',
  'no_direct_debit': '没有直接付款',
  'this_is_invalid': '这是无效的。',
  'your_details': '您的信息',
  'your_call': '称谓',
  'your_first_name': '名字',
  'enter_your_first_name': '请输入您的名字',
  'your_last_name': '姓',
  'enter_your_last_name': '请输入您的姓',
  'your_mobile': '手机号',
  'your_date_of_birth': '出生日期',
  'concessions': '优惠信息',
  'hold_concession_card_question': '您是否持有优惠卡?',
  'identification_type': '证件类型',
  'medicare_card_color': '请选择您的医保卡颜色？',
  'medicare': '医保',
  'medicare_number': '请输入您的医疗保险号码',
  'individual_reference_number': '证件号',
  'please_enter_individual_reference_number': '请输入证件号',
  'valid_to': '有效期',
  'passport': '护照',
  'enter_your_passport': '请输入护照号码',
  'licence_state': '州',
  'driver_licence': '驾驶执照',
  'enter_driver_licence_number': '输入您的驾驶执照号码',
  'card_type': '卡类型',
  'card_number': '卡号',
  'enter_your_concenssion_card_number': '输入您的优惠卡号码',
  'select_yes_and_agree': '通过选择是并使用 Discover Energy 注册我的特许经营细节，我授权与我的特许经营商交换我的个人信息，以验证我是否有资格获得特许权。有关详细信息，请查看特许条款和条件。',
  'valid_mobile_number': "请输入有效的手机号码，即必须以'04'开头",
  'read_and_understand_terms_and_conditions': '请确认您已阅读并理解条款和条件！',
  'please_confirm_relevant_agreement': '请确认相关协议！',
  'signup_wechat_subscribe': '关注微信公众号',
  'enum_call_mr': "先生",
  'enum_call_mrs': "太太",
  'enum_call_ms': "女士",
  'enum_call_miss': "小姐",
  'enum_call_dr': "博士",
  'enum_iden_driver_licence':'驾驶证',
  'enum_iden_medicare':'医保卡',
  'enum_iden_passport':'护照',
  'my_account_login': '我的账户（My Account）登陆信息',
  'my_account_login_id': '我的账户登陆ID',
  'my_account_login_uniq_email_error': '邮箱已被使用',
  'my_account_login_id_hold': '您可以使用您的电子邮件登陆我的账户（My Account）',
  'my_account_login_id_hint': '在一个安全的环境管理你的账户，查看、支付账单、监视您的使用情况并管理您的帐户详细信息。',
  'my_account_login_password': '请为我的账户（My Account）设置密码',
  'my_account_login_password_hold': '请输入您的密码',
  'my_account_login_confirm_password': '确认密码',
  'my_account_login_password_error': '两次输入的密码不一致',
  'my_account_login_confirm_password_hold': '请再次输入确认密码',
  'my_account_login_password_hint':'密码组合必须是在8-20个字符的范围，请至少包括一个字符和数字',
  'direct_debit_request': '扣款信息详情',
  'review_head_title': '通过接受这个提议，你同意以下几点:',
  'review_retail_head': '接受此优惠即表示您同意以下内容已阅读并接受适用于此优惠的',
  'review_retail_desc': '零售条款和条件',
  'review_retail_rear': '。',
  'review_credit_check_head': '授权我们将在您的能源账户转移到Discover Energy之前对您的个人信息进行',
  'review_credit_check_desc': '信用检查',
  'review_credit_check_rear': '。',
  'review_privacy_policy_head': '我已阅读并理解我们的',
  'review_privacy_policy_desc': '隐私政策',
  'review_privacy_policy_rear': '。',
  'review_debit_credit_head': '我已阅读并理解我们的',
  'review_debit_credit_desc': '借记卡和信用卡政策',
  'review_debit_credit_rear': '。',
  'review_refund_policy_head': '我已阅读并理解我们的',
  'review_refund_policy_desc': '退款政策',
  'review_refund_policy_rear': '。',
  'review_conset_iden_check_desc': '您将同意Discover Energy与您提供的任何身份证明的文件发行人或官方记录持有人核实您的身份信息。',
  'review_life_support_eligibility_desc': '如果您提供了优惠卡（concession card）或者生命支持仪器（life support）的信息，您还需要征得Discover Energy的知情同意权，以便与相关特许卡提供商或相关州政府部门核实您的特许资格状况。',
  'happen_cool_title': '10 天的冷静期',
  'happen_cool_desc': '你将拥有10个工作日的冷静期， 如果在此期间你想改变主意，请务必打电话给我们并告诉我们，我们能做些什么?',
  'happen_cool_after_title': '您所提供地址的电力将会在冷静期结束后的两天自动连接到Discover energy公司',
  'happen_cool_after_desc': '请确保您所提供地址的电表是清晰且处于安全位置，读表完成后，您当前的电力零售公司将会给您发送一份他们的最终账单。',
  'property_moving_question': "请问您需要给你搬离的地址断电吗?",
  'property_moving_answer': "如果需要，请打电话给您现在的电力公司申请断电。"
}

let locale = {
  zh,
  de,
  en
}

export default (() => {
  // let defaultLanguage = localStorage.getItem('language') || navigator.systemLanguage || navigator.language
  let defaultLanguage = localStorage.getItem('language') || 'en'
  let standard = (language) => (language || defaultLanguage).toLocaleLowerCase().replace('-', '_').split('_')[0]
  var language = standard()
  return {
    language(value) {
      if (value != null) {
        language = standard(value)
        localStorage.setItem('language', language)
      }
      return language
    },
    t(str) {
      return (locale[language] || locale['de'])[str] || str
    },
    isEnglish() {
      return language == 'en'
    }
  }
})()