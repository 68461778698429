document.addEventListener("turbolinks:load", function() {
  $("#quote-history-plan-submit").click( function (e) {
    var data;
    e.preventDefault();
    $('#quote-history-form').form("validate form");
    if ($('#quote-history-form').form("is valid")) {
      $(this).attr('disabled', true);
      data = {};
      $.map($('form').serializeArray(), function (n, i) {
        return data[n['name']] = n['value'];
      });
      data['authenticity_token'] = $('[name="csrf-token"]')[0].content;
      return $.ajax({
        url: '/quote_histories/'+ data.id +'/create_promotion',
        method: 'post',
        type: 'json',
        data: data,
        success: function (data) {
          if (data.result) {
            if ('direct_signup' === $('#channel_promotion_signup_source').val()) {
              window.location.href = data.signup_url;
            } else {
              alert("Sent successfully");
              window.location.reload();
            }
          } else {
            alert(data.msg);
            window.location.reload();
          }
          return $(this).attr('disabled', false);
        }
      });
    }
  });

  $("#quote-history-get-site-plan").click(function(e){
    let url = $(this).data("action-url")
    return $.ajax({
      url: url,
      method: 'get',
      type: 'script'
    });
  })
});