import React from "react"
import PropTypes from "prop-types"

export class FlashMessage extends React.Component {
  render () {
    const errors = this.props.errors;
    let msg = [];
    Object.entries(errors).forEach(([key, value]) => {
        if(value !== ''){
          msg.push({key: key, value: value})
        }
      }
    );
    function renderLi(content, index) {
      return <li key={index}>{content.value}</li>
    }
    if (msg.length == 0){
      return "";
    }
    return (
      <ul className="alert alert-danger">
        {msg.map((content, index) =>
          renderLi(content, index)
        )}
      </ul>
    );
  }
}
