document.addEventListener("turbolinks:load", function() {

  $(document).ready(function(){
    $.fn.form.settings.rules.greaterThan = function (inputValue, validationValue) {
      return inputValue > validationValue;
    }
    ignore_customize_store_category_validate()
    ignore_vpp_commission_amount_validate()
  })

  function ignore_customize_store_category_validate(){
    var cur_category = $("#channel_company_store_category").val()
    if(cur_category === "" || cur_category === null){
      $(".ui.form").form('remove fields',[
        'channel_company[customize_store_category]'
      ])
      $("#channel_company_customize_store_category_field").hide();
    }else{
      $("#channel_company_store_category").trigger("change")
    }
  }

  $('#channel_company_channel_type').change(function(){
    ignore_customize_store_category_validate()
  })

  $('#channel_company_store_category').change(function(){
    $(".ui.form").form('remove fields',[
      'channel_company[customize_store_category]'
    ])
    var channel_type = $("#channel_company_channel_type").val()
    if(channel_type == 'business'){
      $("#channel_company_customize_store_category_field").hide();
      if($('#channel_company_store_category').val() == 'customize'){
        $(".ui.form").form('add rule','channel_company[customize_store_category]', 'empty')
        $("#channel_company_customize_store_category_field").show();
      }else{
        $('#channel_company_customize_store_category').val("")
      }

    }
  })

  function ignore_vpp_commission_amount_validate(){
    var value = $("#channel_company_company_type").val()
    if(value === undefined){
    }else if(value != 'VPP_CHANNEL'){
      $(".ui.form").form('remove fields',[
        'channel_company[vpp_commission_amount]'
      ])
      $("#vpp-commission-amount-container").hide();
    }else{
      $("#vpp-commission-amount-container").show();
      $(".ui.form").form('add rule','channel_company[vpp_commission_amount]', {
        rules: [
          {
            type: 'greaterThan[0.001]',
            prompt: "commission amount must be greater than 0"
          }
        ]
      })
      $("#channel_company_vpp_commission_amount").trigger("change")
    }
  }

  $('#channel_company_company_type').change(function(){
    ignore_vpp_commission_amount_validate()
  })

  $(document).ready(function(){
    $("#q_store_category_eq").trigger("change");
  })
  
  $('#q_store_category_eq').change(function(){
    if($('#q_store_category_eq').val() == 'customize'){
      $("#q_customize_store_category_field").show();
    }else{
      $("#q_customize_store_category_field").hide();
      $('#q_customize_store_category_cont').val("")
    }
  })
});



