import React from "react"
import './index.scss'

import Tooltip from 'antd/lib/tooltip'
import 'antd/lib/tooltip/style/css'

import Icon from 'antd/lib/icon'
import 'antd/lib/icon/style/css'

import I18n from "src/shared/i18n"
import Validate from '../../lib/validate'

class AddressBox extends React.Component {
  constructor(props) {
    super(props)
    this.widget = null
    this.address_line_1 = React.createRef()
    this.address_line_2 = React.createRef()
  }

  componentDidMount() {
    var script = document.createElement('script');
    script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
    script.async = true;
    script.onload = this.loadWidget
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    if (this.widget) {
      // this.widget.destroy()
      // this.widget = null
    }
  }

  loadWidget = () => {
    this.widget = new AddressFinder.Widget(
      document.getElementById(this.props.id),
      'QKNHF67EYD9XLCAJ4TPU',
      'AU', {
        container: document.getElementById('autocomplete-root'),
        byline: false,
        footer_class: 'address-box-footer',
        empty_content: 'No addresses were found.',
        empty_class: 'address-empty'
      }
    )
    this.widget.on('results:empty', () => {
      this.props.getAddress({})
    })
    this.widget.on('results:update', () => {
      let footElementArr = document.getElementsByClassName('address-box-footer')
      if(document.getElementsByClassName('address-box-footer').length > 0) {
        footElementArr[0].getAttributeNode('style').value = "display: none"
      }
    })
    this.widget.on('result:select', (fullAddress, metaData) => {
      // get nmi
      if (this.props.selectAddress !== undefined) {
        this.props.selectAddress(fullAddress, metaData)
      }

      for(let propName in metaData) {
        if(metaData[propName] === null) {
          metaData[propName] = ''
        }
      }
      let addressInfo = {
        unit_type: metaData.unit_type,
        unit: metaData.unit_identifier,
        street_number: metaData.street_number_2 !== '' ? metaData.street_number_1 + '-' + metaData.street_number_2 : metaData.street_number_1,
        street_name: metaData.street_name,
        street_type: metaData.street_type,
        suburb: metaData.locality_name,
        state: metaData.state_territory,
        postcode: metaData.postcode,
        [this.props.id]: fullAddress
      }
      //fullAddress = `${addressInfo.unit_type !== '' ? addressInfo.unit_type + ' ' + addressInfo.unit +', ' : '' }${addressInfo.street_number} ${addressInfo.street_name} ${addressInfo.street_type}, ${addressInfo.suburb} ${addressInfo.state} ${addressInfo.postcode}`
      addressInfo[this.props.fullAddressKey] = fullAddress
      this.props.form.setFieldsValue({[this.props.id]: fullAddress})
      this.props.getAddress(addressInfo)
    })

  }

  handleChange = (e) => {
    // let addressInfo = {
    //   unit_type: '',
    //   unit: '',
    //   street_number: '',
    //   street_name: '',
    //   street_type: '',
    //   suburb: '',
    //   state: '',
    //   postcode: ''
    // }
    // this.props.getAddress(addressInfo)
    this.props.getAddress(e.target.value)
  }

  render() {
    let errors;
    const { getFieldProps, getFieldError } = this.props.form;
    return (
      <React.Fragment>
        <div id="autocomplete-root" className="autocomplete-root address-box os-input">
          <input
            type="search"
            id={this.props.id}
            className={['form-control' , getFieldError(this.props.id) ? 'validate-error-input' : ''].join(' ')}
            placeholder={this.props.placeholder}
            ref={this.address_line_1}
            disabled={this.props.disabled}
            {...getFieldProps(this.props.id, {
              initialValue: this.props.fullAddressValue,
              onChange: this.handleChange,
              rules: [
                {validator: Validate.validateEmpty, message: 'This is required.'}
              ],
            })}
            >
          </input>

          <div className="validate-error-info">
              {(errors = getFieldError(this.props.id)) ? errors.join(',') :
              !this.props.isValidAddress && this.props.fullAddressValue !== '' ? this.props.validErrorInfo : null
              }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AddressBox;