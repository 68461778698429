document.addEventListener("turbolinks:load", function() {
  if($('body').hasClass('channel-companies-settings')){
    $(document).ready(function(){
      $.fn.form.settings.rules.validateOnlyOneCompanyChannel = function (checkedValue) {
        var is_see_group_checked = $("#channel_company_is_see_group").is(':checked')
        var is_similar_online_checked = $("#channel_company_is_similar_online").is(':checked')
        if(is_see_group_checked && is_similar_online_checked){
          return false
        }else{
          return true
        }
      }
      seFormValidate({
        'channel_company[trailing_start_at]':{
          rules: [
            {
              type: 'empty'
            }
          ]
        },
        'channel_company[is_see_group]': {
          rules: [
            {
              type: 'validateOnlyOneCompanyChannel',
              prompt: "only one can select"
            }
          ]
        },
        'channel_company[is_similar_online]': {
          rules: [
            {
              type: 'validateOnlyOneCompanyChannel',
              prompt: "only one can select"
            }
          ]
        }
      })
      $('#channel_company_trailing_on_paid_bill').trigger("change");
    })

    $('#channel_company_is_see_group, #channel_company_is_similar_online').change(function(){
      $('#update_settings_form').form('validate form')
    })

    $('#channel_company_trailing_on_paid_bill').change(function(){
      var is_checked = $("#channel_company_trailing_on_paid_bill").is(':checked')
      if(is_checked == true){
        $(".ui.form").form('add rule', 'channel_company[trailing_start_at]', 'empty')
      }else{
        $(".ui.form").form('remove fields',['channel_company[trailing_start_at]'])
        $('#update_settings_form').form('validate form')
      }
    })
  }
})