import $ from 'jquery';
import QRCode from 'qrcode';


document.addEventListener("turbolinks:load", function () {
  if ($(".ShareQRCodeImg").length > 0) {
    $(".ShareQRCodeImg").each(function (index, element) {
      let that = $(element)
      let shareUrl = that.data("url")
      let qrcode = that.find(".share_qrcode")
      let opts = { margin: 1 }

      QRCode.toDataURL(shareUrl, opts)
        .then(dataUrl => {
          qrcode.find('img').attr('src', dataUrl);
        })
        .catch(err => console.error(err))
    })
  }
})