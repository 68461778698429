import React from "react"
import PropTypes from 'prop-types'
import ExistedVppAccount from './existedVppAccount'
import UnExistedVppAccount from './unExistedVppAccount'

export class VppQuestion extends React.Component {
  // props: { handleVppCustomerInfoChange(vpp_customer_info), vpp_customer_info: {} } 
  constructor(props) {
    super(props);
    // this.props.vpp_customer_info = {
    //   vpp_customer: '',
    //   install_solar: '',
    //   deinsight_user: '',
    //   disabled_change: '',
    //   email: 
    // }
  }

  onChangeType(type) {
    if(this.props.vpp_customer_info.vpp_customer !== type){
      this.props.handleVppCustomerInfoChange({vpp_customer_info: {
        vpp_customer: type,
        install_solar: '',
        deinsight_user: '',
        disabled_change: false
      }})
    }
  }

  onChangeSolar(type) {
    if(this.props.vpp_customer_info.install_solar !== type){
      this.props.handleVppCustomerInfoChange({vpp_customer_info: {
        vpp_customer: this.props.vpp_customer_info.vpp_customer,
        install_solar: type,
        deinsight_user: '',
        disabled_change: false
      }})
    }
  }

  onChangeDeinsightUser(value) {
    if(this.props.vpp_customer_info.deinsight_user !== value)
    this.props.handleVppCustomerInfoChange({vpp_customer_info: {
      vpp_customer: this.props.vpp_customer_info.vpp_customer,
      install_solar: this.props.vpp_customer_info.install_solar,
      deinsight_user: value
    }})
  }

  handleExistedVppAccountChange = (item)=>{
    let vpp_customer_info = this.props.vpp_customer_info
    this.props.handleVppCustomerInfoChange({vpp_customer_info: Object.assign(vpp_customer_info, item)})
  }

  handleUnExistedVppAccountChange = (item)=>{
    let vpp_customer_info = this.props.vpp_customer_info
    this.props.handleVppCustomerInfoChange({vpp_customer_info: Object.assign(vpp_customer_info, item)})
  }
  

  render () {
    let activeYes = (this.props.vpp_customer_info.vpp_customer === 'yes' ? 'de-select de-select-active select_vpp_customer' : 'de-select select_vpp_customer');
    let activeNo = (this.props.vpp_customer_info.vpp_customer === 'no' ? 'de-select de-select-active select_vpp_customer' : 'de-select select_vpp_customer');
    let solarActiveYes = (this.props.vpp_customer_info.install_solar === 'yes' ? 'de-select de-select-active select_vpp_customer' : 'de-select select_vpp_customer');
    let solarActiveNo = (this.props.vpp_customer_info.install_solar === 'no' ? 'de-select de-select-active' : 'de-select')
    return (
      <React.Fragment>
        <React.Fragment>
          <h3 className="ui header ch-left-bord">Associate VPP</h3>
          <div className="ui grid no-margin">
            <div className="four wide computer six wide tablet sixteen wide mobile column">
              <div className="ch-left-title">Are you a VPP customer?</div>
            </div>
            <div className="twelve wide computer ten wide tablet sixteen wide mobile column">
              <div className={activeYes} onClick={() => this.onChangeType('yes')}>
                <span className="block">Yes</span>
              </div>
              <div className={activeNo} onClick={() => this.onChangeType('no')}>
                <span className="block">No</span>
              </div>
            </div>
          </div>
        </React.Fragment>
        {
          this.props.vpp_customer_info.vpp_customer ===  'yes' ?
          <React.Fragment>
            <div className="ui grid no-margin">
              <div className="four wide computer six wide tablet sixteen wide mobile column">
                <div className="ch-left-title">Do you want to link device to DE VPP?</div>
              </div>
              <div className="twelve wide computer ten wide tablet sixteen wide mobile column">
                <div className={solarActiveYes} onClick={() => this.onChangeSolar('yes')}>
                  <span className="block">Yes</span>
                </div>
                <div className={solarActiveNo} onClick={() => this.onChangeSolar('no')}>
                  <span className="block">No</span>
                </div>
              </div>
            </div>
          </React.Fragment>
          : ''
        }
        {
          this.props.vpp_customer_info.disabled_change === true ?
            <div className="ui grid no-margin">
              <div className="four wide computer six wide tablet sixteen wide mobile column">
                <div className="ch-left-title">Your associated DE VPP account</div>
              </div>
              <div className="six wide computer six wide tablet sixteen wide mobile column">
                <div className="field">
                  <input type="text" required={true} disabled value={this.props.vpp_customer_info.email} />
                </div>
              </div>
            </div>
          :
            this.props.vpp_customer_info.vpp_customer ===  'yes' && this.props.vpp_customer_info.install_solar === 'yes' ?
              <UnExistedVppAccount 
                handleUnExistedVppAccountChange={(item)=>{this.handleUnExistedVppAccountChange(item)}}
                vpp_policy={this.props.vpp_policy}
              />
            : ''
        }
      </React.Fragment>
    );
  }
}

VppQuestion.propTypes = {
  vpp_customer: PropTypes.string,
  install_solar:  PropTypes.string,
  deinsight_user: PropTypes.string
};

export default VppQuestion;