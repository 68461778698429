import React from "react"
import PropTypes from 'prop-types'
import { Form, Select, Input, Button } from 'antd'
import Validate from '../../lib/validate'
import I18n from 'src/shared/i18n'
import Tooltip from 'antd/lib/tooltip'
import 'antd/lib/tooltip/style/css'
import Icon from 'antd/lib/icon'
import 'antd/lib/icon/style/css'
import Message from 'antd/lib/message'
import 'antd/lib/message/style/css'


function isEmptyObject(obj){
  for(var n in obj){return false}
  return true;
}

function hasErrors(fieldsError) {
  if(isEmptyObject(fieldsError)){
    return true
  }else{
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }
}

export class UnExistedVppAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      sn: '',
      type: '',
      check_code: '',
      user_email: '',
      agree: false,
      disabled_submit: false,
      sn_error: '',
      is_multiple: '',
      huawei_info_type: "email",
      huawei_user_info: "",
      au_state: ""
    }
  }

  componentDidMount() {
    $('.tooltip.icon').popup();
    $('.ui.dropdown:not([data-placeholder])').dropdown();
    $('.ui.dropdown[data-placeholder="false"]').dropdown({
      placeholder: false
    });
    $('.ui.checkbox').checkbox();
    this.props.form.validateFields();
  }

  vppAccountValid = (request_params) => {
    var vppAccount = JSON.parse(localStorage.getItem("vppAccount"))
    if(vppAccount){
      for(let i in request_params){
        if(request_params[i] !== vppAccount[i]){ return false }
      }
      this.props.handleUnExistedVppAccountChange(vppAccount["vpp_account"])
      return true
    }else{
      return false
    }
  }

  handleSubmit = () => {
    let _this = this
    this.props.form.validateFields((err, values) => {
      if (!err) {
        _this.setState({disabled_submit: true}, ()=>{
          let request_params = {
            email: _this.state.email,
            sn: _this.state.sn,
            type: _this.state.type,
            check_code: _this.state.check_code,
            user_email: _this.state.type === "HuaWei" ? _this.state.huawei_user_info : _this.state.user_email,
            is_multiple: _this.state.is_multiple,
            state: _this.state.au_state
          }
          if(_this.vppAccountValid(request_params)){ return }
          $.ajax({
            url: '/plans/association_vpp_account',
            method: 'POST',
            data: Object.assign({authenticity_token: $('[name="csrf-token"]')[0].content}, request_params),
            success: function(result){
              if(result.code === 200){
                let vpp_account = {
                  email: result.data.email,
                  sn: result.data.sn,
                  type: result.data.type,
                  check_code: result.data.check_code,
                  deinsight_id: result.data.deinsight_id,
                  user_email: result.data.user_email,
                  is_multiple: result.data.is_multiple,
                  disabled_change: true
                }
                _this.props.handleUnExistedVppAccountChange(vpp_account)
                localStorage.setItem("vppAccount", JSON.stringify(Object.assign(request_params, {vpp_account: vpp_account})))
              }else{
                let msg = result.msg
                if(_this.state.type === "HuaWei" && _this.state.au_state != "SA"){
                  msg += " please select the correct state."
                }
                Message.warning(msg)
                _this.setState({disabled_submit: false})
              }
            }
          });
        })
      }
    });
  }

  handChange = (e) => {
    let _this = this;
    let cur_value
    if (e.target.id === 'is_multiple') {
      if (e.target.value === '1'){
        cur_value = true
      } else if (e.target.value === '0'){
        cur_value = false
      } else {
        cur_value = ''
      }
    } else if (e.target.id === 'type'){
      cur_value = e.target.value
    }else{
      cur_value = e.target.value.replace(/\s+/g,"")
    }
    if(e.target.id === 'type' && e.target.value === 'Sungrow'){
      _this.setState({check_code: ''})
      this.props.form.setFieldsValue({check_code: ''})
    }
    if(e.target.id === 'type' && ['SolarEdge', "QHome", "Growatt"].includes(e.target.value)){
      _this.setState({check_code: '', user_email: ''})
      this.props.form.setFieldsValue({check_code: '', user_email: ''})
    }
    _this.setState({[e.target.id]: cur_value}, ()=>{
      _this.updateSubmitStatus()
    });
  }

  handUserEmailChange = (e)=>{
    let _this = this;
    let user_email = e.target.value.replace(/\s+/g,"")
    this.setState({user_email: user_email, email: user_email}, ()=>{
      this.props.form.setFieldsValue({email: user_email})
      _this.updateSubmitStatus()
    })
  }

  updateSubmitStatus(){
    let _this = this
    let sn_error = false
    const sn = this.state.sn
    const type = this.state.type
    if(type === 'SolarEdge' && sn !== ''){
      if(!/^\w{8}-\w{2}$/.test(sn)){
        sn_error = true
        this.setState({sn_error: 'Please enter SN in (8 digits)-(2 digits) format'})
      }else{
        this.setState({sn_error: ''})
      }
    }
    this.props.form.validateFields((err, values) => {
      if(err || sn_error){
        _this.setState({disabled_submit: true})
      }else{
        _this.setState({disabled_submit: false})
      }
    });
  }

  huaweiInfoRule = () => {
    if(this.state.huawei_info_type === "email"){
      return [
              {validator: Validate.validateEmpty, message: I18n.t("required")},
              {validator: Validate.validateEmail, message: 'this email is invalid'}
            ]
    }else if (this.state.huawei_info_type === "user_id"){
      return [
              {validator: Validate.validateEmpty, message: I18n.t("required")}
            ]
    }else{
      return [
              {validator: Validate.validateEmpty, message: I18n.t("required")},
              {validator: Validate.validateNumberLen, minLen: 10, maxLen: 10}
            ]
    }
  }

  huaweiInfoType = () => {
    if(this.state.huawei_info_type === "user_id"){
      return "username"
    }else if(this.state.huawei_info_type === "email"){
      return "inverter portal email"
    }else{
      return "phone number"
    }
  }

  headUpperCase = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  render () {
    let errors;
    const { getFieldProps, getFieldError, getFieldsError, isFieldTouched } = this.props.form;
    const typeError = isFieldTouched('type') && getFieldError('type');
    const snError = isFieldTouched('sn') && getFieldError('sn');
    const checkCodeError = isFieldTouched('check_code') && getFieldError('check_code');
    const userEmailError = isFieldTouched('user_email') && getFieldError('user_email');
    const emailError = isFieldTouched('email') && getFieldError('email');
    const isMultipleError = isFieldTouched('is_multiple') && getFieldError('is_multiple');
    const huaweiUserInfoError = isFieldTouched("huawei_user_info") && getFieldError('huawei_user_info')
    const auStateError = isFieldTouched("au_state") && getFieldError("au_state")
    const disabled_click_submit = hasErrors(getFieldsError()) || this.state.disabled_submit || !this.state.agree
    return (
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
          <div className="ui grid no-margin">
            <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
              <div className="ch-left-title">Do you have multiple inverter devices?</div>
            </div>
            <div className="six wide computer six wide tablet sixteen wide mobile column no-padding-bt">
              <div className="field os-input">
                <select
                    id="is_multiple"
                    className={['ui fuild dropdown' , isMultipleError ? 'validate-error-input' : ''].join(' ')}
                    {...getFieldProps('is_multiple', {
                      initialValue: this.state.is_multiple,
                      onChange: this.handChange,
                      rules: [

                      ]
                    })}>
                  <option value="-">-None-</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                <div className="validate-error-info">
                  {(errors = typeError) ? errors.join(',') : null}
                </div>
              </div>
            </div>
          </div>

          <div className="ui grid no-margin">
            <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
              <div className="ch-left-title">Inverter brand</div>
            </div>
            <div className="six wide computer six wide tablet sixteen wide mobile column no-padding-bt">
              <div className="field os-input">
                <select
                    id="type"
                    className={['ui fuild dropdown' , typeError ? 'validate-error-input' : ''].join(' ')}
                    {...getFieldProps('type', {
                      initialValue: this.state.type,
                      onChange: this.handChange,
                      rules: [
                        {validator: Validate.validateEmpty, message: I18n.t("required")}
                      ],
                    })}>
                  <option value="">Please select inverter Brand</option>
                  <option value="AlphaEss">AlphaEss</option>
                  <option value="AlphaEstore">AlphaEstore</option>
                  <option value="GoodWe">GoodWe</option>
                  <option value="Sungrow">Sungrow</option>
                  <option value="SolarEdge">SolarEdge</option>
                  <option value="QHome">QHome</option>
                  <option value="HuaWei">HUAWEI</option>
                  <option value="GeGoodWe">GE</option>
                  <option value="Growatt">GROWATT</option>
                </select>
                <div className="validate-error-info">
                  {(errors = typeError) ? errors.join(',') : null}
                </div>
              </div>
            </div>
          </div>

          <div className="ui grid no-margin">
            <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
              <div className="ch-left-title">SN</div>
            </div>
            <div className="six wide computer six wide tablet sixteen wide mobile column no-padding-bt">
              <div className="field os-input">
                <input
                    id="sn"
                    type="text"
                    placeholder={this.state.type === 'SolarEdge' ? 'xxxxxxxx-xx' : 'Please enter your sn'}
                    className={['form-control' , snError ? 'validate-error-input' : ''].join(' ')}
                    {...getFieldProps('sn', {
                      initialValue: this.state.sn,
                      onChange: this.handChange,
                      rules: [
                        {validator: Validate.validateEmpty, message: I18n.t("required")}
                      ],
                      getValueFromEvent: (event) => {
                        return  event.target.value.replace(/\s+/g,"")
                      }
                    })}/>
                <div className="validate-error-info">
                  {(errors = snError) ? errors.join(',') : this.state.sn_error}
                </div>
              </div>
            </div>
          </div>
          {
            this.state.type === "HuaWei" ?
                <div className="ui grid no-margin">
                  <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="ch-left-title">State</div>
                  </div>
                  <div className="six wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="field os-input">
                      <select
                          id="au_state"
                          className={['ui fuild dropdown' , auStateError ? 'validate-error-input' : ''].join(' ')}
                          {...getFieldProps('au_state', {
                            initialValue: this.state.au_state,
                            onChange: this.handChange,
                            rules: [
                              {validator: Validate.validateEmpty, message: I18n.t("required")}
                            ],
                          })}>
                        <option value="">Please select State</option>
                        <option value="SA">SA</option>
                        <option value="NSW">NSW</option>
                        <option value="QLD">QLD</option>
                        <option value="ACT">ACT</option>
                        <option value="VIC">VIC</option>
                      </select>
                      <div className="validate-error-info">
                        {(errors = auStateError) ? errors.join(',') : null}
                      </div>
                    </div>
                  </div>
                </div>
                : ""
          }
          {
            !['', 'Sungrow', 'SolarEdge', "QHome", "HuaWei", "Growatt"].includes(this.state.type) ?
                <div className="ui grid no-margin">
                  <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="ch-left-title">Check Code</div>
                  </div>
                  <div className="six wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="field os-input">
                      <input
                          id="check_code"
                          type="text"
                          placeholder="Please enter your check code"
                          className={['form-control' , checkCodeError ? 'validate-error-input' : ''].join(' ')}
                          {...getFieldProps('check_code', {
                            initialValue: this.state.check_code,
                            onChange: this.handChange,
                            rules: [
                              {validator: Validate.validateEmpty, message: I18n.t("required")}
                            ],
                            getValueFromEvent: (event) => {
                              return  event.target.value.replace(/\s+/g,"")
                            }
                          })}/>
                      <div className="validate-error-info">
                        {(errors = checkCodeError) ? errors.join(',') : null}
                      </div>
                    </div>
                  </div>
                </div>
                : ''
          }

          {
            !['', 'SolarEdge', 'QHome', "HuaWei", "Growatt"].includes(this.state.type) ?
                <div className="ui grid no-margin">
                  <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="ch-left-title">Inverter Portal Email</div>
                  </div>
                  <div className="six wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="field os-input">
                      <input
                          id="user_email"
                          type="text"
                          placeholder="Please enter your inverter portal email"
                          className={['form-control' , userEmailError ? 'validate-error-input' : ''].join(' ')}
                          {...getFieldProps('user_email', {
                            initialValue: this.state.user_email,
                            onChange: this.handUserEmailChange,
                            rules: [
                              {validator: Validate.validateEmpty, message: I18n.t("required")},
                              {validator: Validate.validateEmail, message: 'this email is invalid'}
                            ],
                            getValueFromEvent: (event) => {
                              return  event.target.value.replace(/\s+/g,"")
                            }
                          })}/>
                      <Tooltip placement="topRight" className="plan-tip link icon tooltip tooltip-top-10" title='Please enter the original email address you used to register your inverter.'>
                        <Icon type="question-circle" theme="filled" />
                      </Tooltip>
                      <div className="validate-error-info">
                        {(errors = userEmailError) ? errors.join(',') : null}
                      </div>
                    </div>
                  </div>
                </div>
                : ''
          }
          {
            this.state.type === "HuaWei" ?
              <React.Fragment>
                <div className="ui grid no-margin">
                  <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="ch-left-title">Identification type</div>
                  </div>
                  <div className="six wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="field os-input">
                      <select
                          id="huawei_info_type"
                          className="ui fluid dropdown"
                          {...getFieldProps("huawei_info_type", {
                            initialValue: "email",
                            onChange: this.handChange,
                          })}
                      >
                        <option value="email">Inverter portal email</option>
                        <option value="user_id">Username</option>
                        <option value="phone_number">Phone number</option>
                      </select>
                      <div className="validate-error-info">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ui grid no-margin">
                  <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="ch-left-title">{this.headUpperCase(this.huaweiInfoType())}</div>
                  </div>
                  <div className="six wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="field os-input">
                      <input
                          type="text"
                          id="huawei_user_info"
                          className={['form-control' , huaweiUserInfoError ? 'validate-error-input' : ''].join(' ')}
                          placeholder={"Please enter your " + this.huaweiInfoType()}
                          {...getFieldProps("huawei_user_info", {
                            initialValue: this.state.huawei_user_info,
                            onChange: this.handChange,
                            rules: this.huaweiInfoRule(),
                            getValueFromEvent: (event) => {
                              return event.target.value.replace(/\s+/g,"")
                            }
                          })}
                      />
                      <div className="validate-error-info">
                        {(errors = huaweiUserInfoError) ? errors.join(',') : null}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            : ""
          }
          {
            this.state.type !== "" ?
                <div className="ui grid no-margin">
                  <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="ch-left-title">DE VPP login email</div>
                  </div>
                  <div className="six wide computer six wide tablet sixteen wide mobile column no-padding-bt">
                    <div className="field os-input">
                      <input
                          id="email"
                          type="text"
                          placeholder="Please enter your login email"
                          className={['form-control' , emailError ? 'validate-error-input' : ''].join(' ')}
                          {...getFieldProps('email', {
                            initialValue: this.state.email,
                            onChange: this.handChange,
                            rules: [
                              {validator: Validate.validateEmpty, message: I18n.t("required")},
                              {validator: Validate.validateEmail, message: 'this email is invalid'}
                            ],
                            getValueFromEvent: (event) => {
                              return  event.target.value.replace(/\s+/g,"")
                            }
                          })}/>
                      <div className="validate-error-info">
                        {(errors = emailError) ? errors.join(',') : null}
                      </div>
                    </div>
                  </div>
                </div>
                : ''
          }

          <div className="ui grid no-margin">
            <div className="four wide computer six wide tablet sixteen wide mobile column no-padding-bt">
            </div>
            <div className="six wide computer six wide tablet sixteen wide mobile column no-padding-bt">
              <div className="field os-input">
                <div className="ui checked checkbox" onClick={() => this.setState({agree: !this.state.agree})}>
                  <input id="agree_terms" type="checkbox" checked={this.state.agree} onChange={() => this.setState({agree: !this.state.agree})} />
                  <label htmlFor="agree_terms">
                    I have read and agree to <a href={this.props.vpp_policy} target="_blank" className="italic_font">DE VPP Terms of Use.</a>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="ui grid no-margin">
            <div className="four wide computer six wide tablet sixteen wide mobile column">
            </div>
            <div className="six wide computer six wide tablet sixteen wide mobile column">
              <button id="association_un_existed_btn" type="button" className={disabled_click_submit ? "ui gray button" : "ui green button"} disabled={disabled_click_submit} onClick={this.handleSubmit}>Submit</button>
            </div>
          </div>
        </Form>
    );
  }


}

export default Form.create()(UnExistedVppAccount);
