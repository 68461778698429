import './custom.scss'

document.addEventListener("turbolinks:load", function () {
});

$(function () {
  var initAF, valid_associate_info, valid_email, valid_un_associate_info, widget;
  $(document).ajaxStart(function () {
    $.fancybox.showLoading();
    $.fancybox.helpers.overlay.open({
      parent: $('body'),
      closeClick: false
    });
  }).ajaxStop(function () {
    $.fancybox.hideLoading();
    $.fancybox.helpers.overlay.close();
  }).ajaxError(function (a, b, e) {
    throw e;
  });

  $(document).on('click', '#plan-summary-submit', function (e) {
    var data;
    e.preventDefault();
    $('#plan-summary-form').form("validate form");
    if ($('#plan-summary-form').form("is valid")) {
      $(this).attr('disabled', true);
      data = {};
      $.map($('form').serializeArray(), function (n, i) {
        return data[n['name']] = n['value'];
      });
      data['authenticity_token'] = $('[name="csrf-token"]')[0].content;
      return $.ajax({
        url: '/plans/register',
        method: 'post',
        type: 'json',
        data: data,
        success: function (data) {
          if (data.result) {
            if ('direct_signup' === $('#channel_promotion_signup_source').val()) {
              window.location.href = data.signup_url;
            } else {
              alert("Sent successfully");
              window.location.href = '/plans';
            }
          } else {
            if(data.refresh){
              alert(data.msg);
              window.location.reload();
            }else{
              alert(data.msg);
            }
          }
          return $(this).attr('disabled', false);
        }
      });
    }
  });

});
